const enum MenuSingleStoreActionType {
  SET_MENU = 'SET_MENU',
  SET_STORE = 'SET_STORE',
  SET_MENU_ITEM = 'SET_MENU_ITEM',
  SET_WEB_MENU_ITEMVOS = 'SET_WEB_MENU_ITEMVOS',
  SET_MENU_SECOND_ATTRIBUTE_ITEM = 'SET_MENU_SECOND_ATTRIBUTE_ITEM',
  SET_MENU_SCROLLING = 'SET_MENU_SCROLLING',
  SET_MENU_SELECTED_ATTRIBUTE_MODAL = 'SET_MENU_SELECTED_ATTRIBUTE_MODAL',
  SET_MENU_SELECTED_SECOND_ATTRIBUTE_MODAL = 'SET_MENU_SELECTED_SECOND_ATTRIBUTE_MODAL',
  SET_MENU_APPLY_RULE_RESPONSE = 'SET_MENU_APPLY_RULE_RESPONSE',
  SET_MENU_SELECTED_ITEM_QUANTITY = 'SET_MENU_SELECTED_ITEM_QUANTITY',
}

export { MenuSingleStoreActionType };
