import { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Notice } from '@/assets/img/notice.svg';
import { Style } from '@/components/style';
import StoreDetailModal from '@/components/common/storeDetail/storeDetailModal';
import { FormattedMessage } from 'react-intl';

const { secondaryColor } = new Style().getUiConfig();
function StoreDetail({ webStoreVo }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="d-flex w-100 pt-4 pb-4 ps-4 pe-4 align-items-center justify-content-between">
      <div className="h6 mb-0">
        {webStoreVo?.displayName ? webStoreVo.displayName : ''}
      </div>
      <div
        className="d-flex align-items-center gap-1"
        style={{ fontSize: '0.85rem', color: secondaryColor }}
        onClick={handleShow}
        role="presentation"
      >
        <Notice />
        <FormattedMessage id="storeDetail.store" />
      </div>
      <StoreDetailModal
        webStoreVo={webStoreVo}
        show={show}
        handleClose={handleClose}
      />
    </div>
  );
}

StoreDetail.propTypes = {
  webStoreVo: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
  }).isRequired,
};

export default StoreDetail;
