import { Ii18n } from './type';

const error: Ii18n = {
  'error.title': 'Warning',
  'error.confirm': 'OK',
  'error.noParty': 'Please Select Party',
  'error.invalidMobile': 'Please Enter Correct Mobile Number',
  'error.invalidName': 'Please Enter Correct Name',
  'error.invalidDateTime': 'Please Select Date and Time',
  'error.invalidNameAddressFields': 'Please Enter Name and Address',
  'error.noRedeemItems': 'No Redeem Items Available',
  'error.network': 'Network Error',
  'error.1': '系統異常',
  'error.10001': 'Order Abnormal',
  'error.10002': 'Order already paid',
  'error.10003': "Order is on paying process, item can't be added.Thank you",
  'error.10009': 'The order has been split and items cannot be added.',
  'error.10004': 'Item soldout ',
  'error.100041':
    'The meal information is abnormal, please notify the clerk for assistance, thank you! [requires menu update]',
  'error.10005': 'Item attribute error, please contact staff',
  'error.10006': 'Order is fail, please contact staff',
  'error.10017': 'Tables have been merged, please scan the table QR Code again',
  'error.20001': 'Invoice run out, please contact staff',
  'error.20002': 'Order is paid, please contact staff',
  'error.20003': 'Order not found',
  'error.20004': 'Payment failed, please try again',
  'error.20005':
    'Create Payment Fail. If your card is charged, please ask staff for refund',
  'error.30001': 'Seat info error, please contact staff. Thank you',
  'error.30002': 'Please contact staff to open a table',
  'error.30003': 'Table repeated, please select other table. Thank you',
  'error.40001': 'Order error, please contact staff. Thank you',
  'error.40002': 'Store information error, please contact staff. Thank you',
  'error.40003':
    'Store not open. Please contact staff. \nThe opening time is from {startTime} to {endTime}.',
  'error.40004': 'Staff not found',
  'error.40005': 'Member not found',
  'error.40006': 'PARSE_SALES_REPORT_ERROR',
  'error.40007': 'The opening time is from {startTime} to {endTime}.',
  'error.40008': "Can't not find is member, please re-enter phone number",
  'error.40009':
    'The locker is full at present,\n Please wait to place an order, Thank you for your cooperation!',
  'error.40011': 'Online Service Unavailable',
  'error.40012': 'Insufficient available points,\n Please remove redeem items',
  'error.40013': 'Online Service Unavailable',
  'error.50001': 'System busy, please try again. Thank you',
  'error.50003': 'Online Service Unavailable',
  'error.60001': 'At Most 3 OTP Per Day',
  'error.60002': 'Please Enter the Correct OTP',
  'error.noPromotion': 'Please Select One Promotion',
  'error.noRedeem': 'Please Select One Discount',
  'error.noOtp': 'Please Enter OTP',
  'error.camera.permission': 'Please Allow Camera Permission And Try Again',
  'error.coupon.not.exist': 'Coupon Does Not Exist',
  'error.coupon.used': 'Coupon Is Used',
  'error.coupon.time': 'Coupon do not match the available time',
  'error.coupon.store': 'Coupon Cannot Be Used In This Store',
  'error.coupon.server': 'Error, Please Try Again',
  'error.qr.not.detected': 'Unable To Detect QR Code',
  'error.qr.empty': 'Please Enter Coupon Serial Number',
  'error.select.payment': 'Please Select Payment',
  'error.select.onlinepayment': 'Please Select Online Payment',
  'error.select.invoice': 'Please Select Invoice Type',
  'error.select.npo': 'Please Select NPO',
  'error.fill.npo': 'Please Fill NPO Code',
  'error.identifier.406': 'Wrong Identifier, Please try again',
  'error.identifier.403': 'Wrong Identifier, Please try again',
  'error.identifier.400': 'Wrong Identifier',
  'error.identifier': 'Wrong Identifier',
  'error.invoice.gov': 'Server Error, Please Choose Other Option',
  'error.mobilecarrier': 'Please Fill Mobile Carrier',
  'error.mobilecarrier.notvalid': 'Wrong Mobile Carrier',
  'error.npo.notvalid': 'Wrong NPO',
  'error.payment.retry': 'Go Back To Payment',
  'error.cardnumber': 'Invalid Card Number',
  'error.cardexpdate': 'Card Expired',
  'error.cardcsc': 'Wront CSC Code',
  'error.pay': '{pay} Pay Fail, Code [{status}], Reason [{msg}]',
  'error.preorderTime':
    'The specified time is unavailable, please choose again',
  'error.payment': 'Pay Fail',
  'error.pickup.unavailable': 'Pickup Is Currently Unavailable',
  'error.preorderTime.unavailable': 'Preorder Is Currently Unavailable',
  'error.order.full': 'Order Full',
  'error.time.order.full':
    'Your Selected Time Is Unavailable, Please Select A New One',
  'error.order.before.mergeOrder':
    'Tables have been merged, please scan the table QR Code again',
  'error.order.after.mergeOrder':
    'The order has been changed to an invalid order, please scan the table QR Code again',
  'error.10019':
    'The order is duplicated, please scan the QR code to order again.',
  'error.10020':
    'The order cannot be continued, please scan the QR code again to order',
  'error.10003.title': 'Unable to add items',
  'error.10003.msg': 'Order is being checked out',
  'error.10009.title': 'Unable to add items',
  'error.10009.msg': 'The order has been split',
  'error.100041.title': 'Meal information abnormal',
  'error.100041.msg': 'Please ask the service staff to update the menu',
  'error.10005.title': 'Item attribute exception',
  'error.10005.msg': 'Please contact service personnel',
  'error.10006.title': 'Order establishment failed',
  'error.10006.msg': 'Please contact service personnel',
  'error.10017.title': 'Tables have been merged',
  'error.10017.msg': 'Please rescan the table QR Code',
  'error.10019.title': 'Duplicate order',
  'error.10019.msg': 'Please scan the code again to order',
  'error.10020.title': 'Unable to continue ordering',
  'error.10020.msg': 'Please scan the code again to order',
  'error.20001.title': 'No invoice number',
  'error.20001.msg': 'Please contact service personnel',
  'error.20002.title': 'Order has been checked out',
  'error.20002.msg': 'Please contact service personnel',
  'error.20004.title': 'Payment failed',
  'error.20004.msg': 'Please try again',
  'error.20005.title': 'Order establishment failed',
  'error.20005.msg':
    'If your card is debited, please go to the store for a refund',
  'error.30001.title': 'Seat information error',
  'error.30001.msg': 'Please contact service personnel',
  'error.30003.title': 'Duplicate table number',
  'error.30003.msg': 'Please select another seat',
  'error.40001.title': 'Wrong order number',
  'error.40001.msg': 'Please ask the service staff to open a table',
  'error.40002.title': 'Store message error',
  'error.40002.msg': 'Please contact service personnel',
  'error.40003.title': 'Current non-business hours',
  'error.40003.msg':
    'Please contact service staff for assistance \nOnline business hours are {startTime}-{endTime}',
  'error.40007.title': 'Current non-business hours',
  'error.40007.msg':
    'Online business hours are\n {startTime}-{endTime} \nPlease place orders during business hours',
  'error.40008.title': 'No mobile phone found for this member',
  'error.40008.msg': 'Please re-enter',
  'error.40009.title': 'Smart meal pickup cabinet is full',
  'error.40009.msg': 'Please wait a moment before placing an order',
  'error.40012.title': 'Insufficient points',
  'error.40012.msg': 'Please remove the redemption items',
  'error.50001.title': 'The system is busy',
  'error.50001.msg': 'Please try again later',
  'error.60001.title': "Today's SMS sending limit has been reached",
  'error.60001.msg': 'The upper limit is 3 times per day',
};

const welcome: Ii18n = {
  'welcome.availableServeTypes.DINE_IN': 'For Here',
  'welcome.availableServeTypes.TAKE_OUT': 'To Go',
  'welcome.availableServeTypes.DELIVERY': 'Delivery',
  'welcome.availableTakeOutServeTypes.DEFAULT':
    'Please Select Takeout Method (required)',
  'welcome.availableTakeOutServeTypes.DEFAULT.warn':
    'Please Select Takeout Method (required)',
  'welcome.availableTakeOutServeTypes.TAKE_OUT': 'To Go',
  'welcome.availableTakeOutServeTypes.SCHEDULE_TAKE_OUT': 'Scheduled To Go',
  'welcome.tel': 'Please Enter Mobile',
  'welcome.selectedDate': 'Please Select a Date',
  'welcome.date': 'Date: {date}',
  'welcome.selectedTime': 'Please Select a time',
  'welcome.time': 'Time: {time}',
  'welcome.setParty': 'Select Party',
  'welcome.setPartyTitle': 'Select Party',
  'welcome.skipParty': 'Skip Party Selection and Order',
  'welcome.newOrder': 'New Order',
  'welcome.newPickUpOrder': 'New Order',
  'welcome.existingOrder': 'Existing Order',
  'welcome.name': 'Name',
  'welcome.address': 'Address (required)',
  'welcome.phone': 'Phone',
  'welcome.name.required': 'Name(required)',
  'welcome.tel.required': 'Phone(required)',
  'welcome.comment': 'Comments(Optional)',
  'welcome.email': 'Email',
  'welcome.supplier': 'Supplier',
  'welcome.privacy': 'Privacy',
  'welcome.confirm': 'Confirm',
  'welcome.add': 'Add',
  'welcome.postpay':
    'Please Pay At Kiosk After Finishing Your Meal, Thank You!',
  'welcome.royality': 'Please Fill In Mobile For Member Point!',
  'welcome.machine.offline': 'Out of Service',
  'welcome.machine.offline.text':
    'The system is temporarily closed. We apologize for any inconvenience caused. For any questions, please call the store.',
  'welcome.search.order': 'Search Order',
  'welcome.search.order.and.redeem': 'Search Order / Points',
  'welcome.timeremind':
    'The estimated time for picking up the meal will be adjusted according to the situation of the store. Please confirm again after the order is submitted, and keep the phone open to avoid communication delays, thank you.',
  'welcome.timeremind.onlineTime.title': "It's not opening time",
  'welcome.pickup.timeremind.onlineTime':
    'The opening time is from {startTime} to {endTime}.',
  'welcome.availableTakeOutServeTimes':
    'It is currently non-business hours, the opening time for online reservations is {startTime} ~ {endTime}, and the pick-up time is {timePeriods}; if there is a large demand for ordering food, please contact the store to confirm the availability.',
  'welcome.pickup.timeremind.onlineTime.title': "It's not opening time",
  'welcome.pickup.timeremind.pickupTime':
    'The latest pickup time is {pickupEndTime}',
  'welcome.timeremind.delivery.notice':
    'Delivery Time Might be different Due To Traffic ',
  'welcome.viewMenu': 'View Menu',
  'welcome.store.not.open':
    'The order service of {serveType} is currently not available during non-store business hours.',
  'welcome.business.time': 'Business hours {startTime} ～ {endTime}',
  'welcome.button.bussiness.time': 'Business hours : {startTime} ～ {endTime}',
  'welcome.phone.royaltyEnable.reminder':
    'Please enter your mobile phone number to collect points. This order can accumulate points',
  'welcome.machine.offline.msg':
    'If you have any questions, please contact the store',
};

const searchOrder: Ii18n = {
  'searchOrder.title': 'Order Record',
  'searchOrder.tel': 'Please Fill in Mobile',
  'searchOrder.availableServeTypes.DINE_IN': 'For Here',
  'searchOrder.availableServeTypes.TAKE_OUT': 'To Go',
  'searchOrder.availableServeTypes.DELIVERY': 'Delivery',
  'searchOrder.availableServeTypes.PICK_UP': 'Cubbites',
  'searchOrder.search': 'Search',
  'searchOrder.myOrder': 'My Orders',
  'searchOrder.refresh': 'Refresh',
  'searchOrder.emptyOrder': 'There‘s no any order in two days',
  'searchOrder.watingPay': 'Wait For Pay',
  'searchOrder.watingPay.comment.PRINT_AFTER_ORDER':
    'Take Your Order After Pay',
  'searchOrder.watingPay.comment.PRINT_AFTER_PAY':
    'The Order Will Start Making Meal After Pay',
  'searchOrder.orderCode': 'Order No.',
  'searchOrder.preOrderTime': 'Preorder Time',
  'searchOrder.createOrderTime': 'Create Order Time',
  'searchOrder.takeOrderMethod': 'Serve Type',
  'searchOrder.payTotal': 'Total',
  'searchOrder.itemTotal': '{Items} Items',
  'searchOrder.gotoPay': 'To Pay',
  'searchOrder.totalMeal': '{meal}meals',
  'searchOrder.order.cancel': 'Cancel',
  'searchOrder.order.cancel.comment': 'This order has been canceled',
  'searchOrder.order.complete': 'Complete',
  'searchOrder.back.to.previous.page': 'Go back to previous page',
};

const redeemPoint: Ii18n = {
  'redeemPoint.title': 'My Points',
  'redeemPoint.point': 'points',
  'redeemPoint.info.cutstomer.points': 'Currently available points',
  'redeemPoint.info.current.redeem.points': 'This exchange uses',
  'redeemPoint.redeem.confirm': 'Exchange',
  'redeemPoint.empty.redeem.item': 'There are currently no redeemable items',
  'redeemPoint.insufficient.points': 'Insufficient available points',
  'redeemPoint.Inventory.limit.reached': 'Inventory limit reached',
  'redeemPoint.cost.money.not.enough': 'cost money not enough',
  'redeemPoint.out.of.stack': 'Exchange quantity exceeds stock',
  'redeemPoint.memberPoints': 'Member Points',
  'redeemPoint.searchPoints': 'Query points',
  'redeemPoint.earnPoints': 'Get record',
  'redeemPoint.usedPoints': 'Usage record',
  'redeemPoint.thirdRedeemPlatform.msg':
    'Please use {platform} APP to check point records',
  'redeemPoint.empty.point.record': 'No point record yet',
  'redeemPoint.recent.three.month.record':
    'RedeemPoint.recent.three.month.record',
  'redeemPoint.type.default.portal.change': 'Redeem Points',
  'redeemPoint.type.earn': 'Gathering Points',
  'redeemPoint.type.rollback': 'Return Redemption Points',
  'redeemPoint.type.redeem': 'Redeem Points',
  'redeemPoint.type.refund': 'Return Collection Points',
};

const confirmModal: Ii18n = {
  'confirmModal.title.insert.otp': 'Enter confirmation code',
  'confirmModal.otp.send': 'A verification code has been sent to your phone:',
  'confirmModal.insert.otp': 'Please enter a 4-digit verification code',
  'confirmModal.NOT.received.otp': 'Did not receive verification code?',
  'confirmModal.resend.otp': 'Resend',
  'confirmModal.title.confirm.redeem': 'Confirm Exchange',
  'confirmModal.current.redeem.points': 'Points used for this exchange:',
  'confirmModal.confirm.sure.msg': 'Do you confirm the exchange: ',
  'confirmModal.title.redeem.success': 'Exchange Successfully',
  'confirmModal.success.msg':
    'The item has been redeemed successfully, and the item has been added to your shopping cart.',
  'confirmModal.point': 'Points',
  'confirmModal.cancel': 'Cancel',
  'confirmModal.next': 'Next',
  'confirmModal.confirm': 'Confirm',
  'confirmModal.view.cart': 'View Cart',
  'confirmModal.continued.order': 'Continud',
  'confirmModal.line.otp.send':
    'LINE notification has been sent, please check your phone and enter the verification code:',
  'confirmModal.line.otp.bind.agree': 'Agree',
  'confirmModal.line.otp.bind.disagree': 'Disagree',
  'confirmModal.title.redeem.line.bind': 'Confirm binding mobile phone',
  'confirmModal.success.line.bind.msg':
    'Do you agree to bind this mobile phone number to the store LINE@? In the future, order redemption from LINE@ will not require entering a verification code or sending LINE notifications.',
};

const stores: Ii18n = {
  'stores.select': 'Please Select a Store',
  'store.phone': 'Support Phone {phone}',
  'store.email': 'Support Email {email}',
};

const tableinfo: Ii18n = {
  'tableinfo.serveType.DINE_IN': 'Dine In',
  'tableinfo.serveType.TAKE_OUT': 'To Go',
  'tableinfo.serveType.SCHEDULE_TAKE_OUT': 'Order Takeout',
  'tableinfo.serveType.DELIVERY': 'Delivery',
  'tableinfo.serveType.PICK_UP': 'Pick up',
  'tableinfo.serveType.time.DINE_IN': 'Dine In {time}',
  'tableinfo.serveType.time.TAKE_OUT': 'To Go {time} Pickup',
  'tableinfo.serveType.time.DELIVERY': 'Delivery {time} Deliver',
  'tableinfo.serveType.time.PICK_UP': 'To Go {time} Pickup',
  'tableinfo.party': 'Party {party}',
  'tableinfo.seats': 'Seats {seat}',
  'tableinfo.seats.title': 'Table number',
  'tableinfo.serveType.time': 'Taking meals',
  'tableinfo.address': 'Address',
  'tableinfo.takeSeat.time': 'Seat Time',
  'tableinfo.seatOverTimeMins.title': 'Meal time limit',
  'tableinfo.seatOverTimeMins.mins': 'Mins',
  'tableinfo.table.members': 'Number of people dining',
  'tableinfo.table.members.shareMealDetail': '{count} {title}',
};

const attribute: Ii18n = {
  'attribute.require': 'Require {number}',
  'attribute.atLeast': 'At Least {number}',
  'attribute.atMost': 'At Most {number}',
  'attribute.optionItem': 'Optional {number} items',
  'attribute.requireItem': 'Required {number} items',
  'attribute.back': 'Back',
  'attribute.addtoCart': 'Add to Cart',
  'attribute.add': 'Add Items',
  'attribute.edit': 'Edit',
  'attribute.unsatisfy': 'Please Select Items',
  'attribute.comment': 'Comment',
  'attribute.comment.placeholder': 'Comment (Optional)',
};

const common: Ii18n = {
  'common.remain.raw.material':
    'Insufficient raw materials, please call the store if you want to place an order',
  'common.remain': 'remaining : {remain}',
  'common.soldout': 'Sold Out',
  'common.confirm': 'Confirm',
  'common.back': 'Back',
  'common.continueOrder': 'Continue Order',
  'common.editItems': 'Edit Items',
  'common.placeOrder': 'Place Order {price}',
  'common.shareMeal': 'Add Sharemeal Fee {price}',
  'common.place.order': 'Place Order',
  'common.order.placed': 'Order Placed',
  'common.order.paid': 'Order Paid',
  'common.order.cancel': 'Order Refunded or Cancelled',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.cancel': 'Cancel',
  'common.copylink': 'Copy URL',
  'common.homePage': 'Home',
  'common.searchOrder': 'Search Order',
  'common.myPoints': 'My Points',
  'common.store.name': 'Business person name:',
  'common.store.identifier': 'Uniform number:',
  'common.shareMeal.modal.header.title':
    'Please select the number of people to eat',
  'common.shareMeal.modal.confirm': 'Confirm',
  'common.shareMeal.title': '※ Shared Meal Instructions',
  'common.shareMeal.msg':
    'Each {personCount} person can share one pot, if the minimum consumption is not met, a fee will be charged',
};

const cart: Ii18n = {
  'cart.delivery.address': 'Delivery address',
  'cart.view': 'View Cart',
  'cart.address': 'address：',
  'cart.title': 'Cart',
  'cart.count': 'You Have {count} Items',
  'cart.order': 'Place Order',
  'cart.place.order': 'Place Order',
  'cart.confirm.order': 'Place Order',
  'cart.discount': 'Use Discount',
  'cart.editTime.DINE_IN': 'Dine In',
  'cart.editTime.TAKE_OUT': 'Pickup Time',
  'cart.editTime.DELIVERY': 'Delivery Time',
  'cart.editTime.PICK_UP': 'Pickup Time',
  'cart.customerInfo': 'Customer Info',
  'cart.comment': 'Comment: {comment}',
  'cart.memberpoint': 'Get Point',
  'cart.ask.memberpoint': 'Would You Like To Accumulate Point',
  'cart.memberpoint.instruction':
    'Please Order Again After Entering Your Phone Number.',
  'cart.memberpoint.event': 'Rewards Program.',
  'cart.memberpoint.event.PRICE_POINT':
    'Now enter your mobile phone number, and you can accumulate {point} points when you spend over {price}.',
  'cart.memberpoint.earn.point': 'Earn Points',
  'cart.memberpoint.point.redemption': 'Point Redemption',
  'cart.memberpoint.earn.point.by.phone': 'Accumulate point with phone',
  'cart.memberpoint.phone.correct':
    'Successfully entered, points will be deposited into this mobile number after checkout is completed.',
  'cart.memberpoint.consumption':
    'The total consumption of this time is {total},',
  'cart.memberpoint.can.earn.point': 'Can accumulate {rulePoint} points.',
  'cart.not.in.businees.hour': 'This store is not opened now',
  'cart.customerInfo.name': 'Order name (optional)',
  'cart.customerInfo.name.required': 'Order name (required)',
  'cart.customerInfo.name.error':
    'The name field cannot be empty, please enter the name of the orderer',
  'cart.customerInfo.phone': 'Please enter the mobile phone number (optional)',
  'cart.customerInfo.phone.required':
    'Please enter a mobile phone number (required)',
  'cart.customerInfo.phone.empty.error':
    'The phone field cannot be empty, please enter a phone number',
  'cart.customerInfo.phone.error': 'Please enter a valid mobile phone number',
  'cart.customerInfo.phone.royaltyEnable.reminder':
    'Please enter your phone number to collect points, this order can accumulate {points} points',
  'cart.customerInfo.phone.royaltyEnable.valid':
    'Checkout completion points will be automatically deposited into this phone number',
  'cart.customerInfo.order.comment': 'Order comment (optional)',
  'cart.card.cartOrder.title': 'Order content',
  'cart.card.cartOrder.items.total.forward': 'total',
  'cart.card.cartOrder.items.total.backward': 'items',
  'cart.card.cartOrder.check.order.item.detail': 'Check the details',
  'cart.card.cartOrder.order.remove': 'Remove',
  'cart.card.cartOrder.footer.remind':
    'Click on the meal item to edit the meal content. ',
  'cart.card.cartOrder.footer.add.items': 'Add',
  'cart.card.cartOrderItemModal.comment': 'Comment',
  'cart.card.cartOrderItemModal.updated.item': 'Updated Item',
  'cart.card.takeOutDeliveryInfo.title': 'Meal Information',
  'cart.card.takeOutDeliveryInfo.delivery.address': 'delivery address',
  'cart.card.discount.title': 'Use discount',
  'cart.card.discount.coupon.title': 'New Coupon',
  'cart.card.discount.redeem.title': 'Enquiry/Points Redemption',
  'cart.card.discount.item.discount.title': 'Discount used:',
  'cart.card.discount.item.coupon.title': 'Coupon used:',
  'cart.card.discount.item.redeem.title': 'Used points:',
  'cart.card.discount.item.remove': 'Remove',
  'cart.card.detail.title': 'Checkout Details',
  'cart.card.detail.itemTotal.title': 'Product amount',
  'cart.card.detail.coupon.title': 'Coupon discount',
  'cart.card.detail.redeem.title': 'Point discount',
  'cart.card.detail.discount.title': 'Discount amount',
  'cart.card.detail.shareMealFee.title': 'Share MealFee',
  'cart.card.detail.serviceFee.title': 'Service Fee',
  'cart.card.detail.serviceFeeMinConsumption.title': 'Delivery low consumption',
  'cart.card.detail.total.title': 'Total amount',
  'cart.card.detail.error.serviceFeeMinConsumption':
    '＊You need to spend {key} yuan for free delivery, and you need to pay an additional delivery fee {value} yuan if you dont spend the minimum amount',
  'cart.card.detail.accumulated.points': 'Accumulated Points',
  'cart.card.cartOrderItemModal.error.lacking.short':
    'Insufficient number of items {itemTitle}: ',
  'cart.card.cartOrderItemModal.error.lacking':
    'The item quantity is less than {itemTitle} remaining: {findWebItemLimitQuantity} units',
  'cart.card.detail.error.minPrice':
    '＊The minimum order amount is $ {condition}, please click Add Meal to continue ordering',
  'cart.card.detail.error.shareMeal':
    '＊Additional purchases of other items cannot be used for the co-cup fee, and the minimum number of {minMainMealTarget} pots is not met at present',
  'cart.card.detail.error.maxQuantity.byOrder':
    '＊Orders are limited to $ {maxQuantity} copies, please re-edit the order item quantity',
  'cart.card.detail.error.maxQuantity.byItem':
    '＊The order limit is exceeded, please adjust the quantity of the following items: \n $ {displayNames}/limit $ {maxQuantity} pieces',
  'cart.askShareMealModal.title': 'Do you want to share the pot? ',
  'cart.askShareMealModal.msg':
    'A shared meal fee of $ {shareMealFee} is charged',
  'cart.askShareMealModal.reminder.title': '※ Consumption Description',
  'cart.askShareMealModal.reminder.detail':
    'Each {mealShortage} people can share one pot, {shareMealCondictionMsg} additional purchases of other items can be used to offset the shared pot fee. Currently, the minimum amount of shared pot is not met, and $ {shareMealFee} will be charged if no more meals are purchased',
  'cart.askShareMealModal.reminder.detail.getShareMealCondictionMsg':
    '$ {price} will be charged for {title}',
  'cart.askShareMealModal.palceOrder': 'Continue to add order',
  'cart.askShareMealModal.shareMeal': 'I want to share the pot',
  'cart.payment.store.reminder':
    '*The store will start making meals after the checkout and payment in the store are completed',
  'cart.payment.alert.msg':
    ' *After selecting the payment method, you need to click the "Confirm order" or "Proceed to checkout" button below, the order will be established and sent',
  'cart.payment.stroe.only.alert.msg':
    ' *After selecting the payment method, you need to click the "Confirm Order" button below to complete the order and send it out.',
  'cart.payment.online.only.alert.msg':
    ' *After selecting the payment method, you need to click the "Go to Checkout" button below to complete the order and send it out.',
  'cart.store.name': 'Beneficiary : {storeName}',
};

const promotion: Ii18n = {
  'promotion.myDiscount': 'My Discount',
  'promotion.useDiscount': 'Use Discount',
  'promotion.redeem': 'Redeem Point',
  'promotion.available.point': '{point} Point(s) Available',
  'promotion.coupon': 'Coupon',
  'promotion.search': 'Search',
  'promotion.menu.myDiscount': 'My Discount/Points',
  'promotion.menu.useDiscount': 'Use Discount/Points',
};
const redeem: Ii18n = {
  'redeem.title': 'Please Select A Discount',
  'redeem.point': '{point} Points Deduction',
};
const otp: Ii18n = {
  'otp.title': 'OTP',
  'otp.placeholder': 'Please Enter OTP Code',
};
const coupon: Ii18n = {
  'coupon.title': 'Coupon',
  'coupon.camera': 'Scan Coupon',
  'coupon.picture': 'Select Coupon From File',
  'coupon.serial': 'Enter Coupon Code',
  'coupon.v2.modal.header.title': 'New Coupon',
  'coupon.v2.modal.footer.cta': 'Confirm',
  'coupon.v2.scan.title': 'Scan Coupon',
  'coupon.v2.couponbarcode.title': 'Discount Code',
  'coupon.v2.camera': 'Camera Scan Coupon',
  'coupon.v2.sucess.msg':
    '*Cool bumper coupons were successfully redeemed and new discounts have been added',
  'coupon.v2.modal.body.input.placeholder': 'Enter discount code',
  'coupon.v2.error.input.empty': 'Please enter the discount code',
};
const scancoupon: Ii18n = {
  'scancoupon.title': 'Scan Coupon',
  'scancoupon.notice': 'Please Scan QRCode',
};
const recommendation: Ii18n = {
  'recommendation.title': 'Recommendation',
  'recommendation.nextTime': 'Next Time, Thanks',
  'recommendation.confirm': 'Confirm',
};
const rulealert: Ii18n = {
  'rulealert.title': 'Notice',
  'rulealert.title.quantity.limit': 'order limit exceeded',
  'rulealert.body.edit.item':
    'Please adjust the quantity of the following items：',
  'rulealert.party': 'Party of {party}',
  'rulealert.sharemeal.message1': '{party} persons need to order {pot} pots,',
  'rulealert.sharemeal.message2': 'or order atleast {pot} pot + {item} item',
  'rulealert.sharemeal.message3': 'to remove sharefee',
  'rulealert.min.consumption.message1':
    'Additional fee {fee} will be added if total is less than {condition}',
  'rulealert.min.consumption.message2': 'Minimum charge is {condition}',
};
const postpay: Ii18n = {
  'postpay.title': 'Pay',
  'postpay.qrcode': 'QRCode',
  'postpay.orderlist': 'Order List',
  'postpay.customId': 'Order No {customId}',
  'postpay.customId.v2': 'Order No',
  'postpay.qrcode.message1':
    'Please proceed to the Kiosk and "enter your order number" or',
  'postpay.qrcode.message2':
    '"scan with the qrcode" to proceed payment after finishing your meal',
  'postpay.qrcode.message3':
    '(turn up brightness on your phone before scanning the qr code)',
  'postpay.view.orderList': 'View Order Records',
  'postpay.goto.qrcode': 'Pay',
  'postpay.qrcode.message':
    'After finishing your meal, please go to the counter and show this screen to check out, or scan this QR Code at the checkout machine to pay. ',
  'postpay..existingOrder.total': 'Total amount',
  'postpay.orderlist.title': 'Order record',
  'postpay.existingOrder.batch.title': '{times}th order',
};
const payment: Ii18n = {
  'payment.amount': 'Amount Due',
  'payment.view': 'Detail',
  'payment.confirm': 'Pay',
  'payment.confirm.order': 'Confirm',
  'payment.free': 'Create Order',
  'payment.options': 'Payment Options',
  'payment.options.header': 'Payment Options',
  'payment.instore': 'Pay In Store',
  'payment.cash': 'Pay On Cash',
  'payment.online': 'Pay Online',
  'payment.online.option': 'Please Select Payment Type',
  'payment.LINEPAY': 'LINE Pay',
  'payment.JKOPAY': 'JKOPAY',
  'payment.APPLEPAY': 'Apple Pay',
  'payment.GOOGLEPAY': 'Google Pay',
  'payment.TAIWANPAY': 'Taiwan Pay',
  'payment.TAPPAY_DIRECT': 'Credit Card',
  'payment.CTBC': 'Credit Card',
  'payment.BBPOS': 'BBPOS',
  'payment.BBPOS.title': 'BBPOS Policy',
  'payment.PAYPAL': 'Paypal',
  'payment.invoice': 'Please Select Invoice Type',
  'payment.detail.title': 'Detail',
  'payment.invoice.identifier': 'Please Fill Identifier (Optional)',
  'payment.invoice.mobilecarrier': 'Please Fill Carrier',
  'payment.invoice.mobilecarrier.save': 'Save Carrier',
  'payment.invoice.npo': 'Please Select NPO',
  'payment.invoice.fill.npo': 'Please Fill NPO',
  'payment.invoice.PAPER': 'Paper',
  'payment.invoice.BUYER_IDENTIFIER': 'Identifier',
  'payment.invoice.MOBILE_CARRIER': 'Carrier',
  'payment.invoice.NPO_CODE': 'Donate',
  'payment.invoice.EMAIL': 'Email',
  'payment.invoice.EMAIL_IDENTIFIER': 'Identifier + Email',
  'payment.npo.8585': '財團法人台灣兒童暨家庭扶助基金會',
  'payment.npo.7000': '財團法人台灣世界展望會',
  'payment.npo.9527': '財團法人罕見疾病基金會',
  'payment.npo.928': '財團法人宜蘭縣私立佛教幸夫社會福利慈善事業基金會',
  'payment.npo.8341': '社團法人中華民國銀髮慈善協會',
  'payment.npo.5252': '社團法人中華民國身心障礙聯盟',
  'payment.npo.4312': '社團法人中華親善動物保護協會',
  'payment.npo.OTHER': '其他',
  'payment.submit': 'Create Payment{br}Please Wait...',
  'payment.submit.success': 'Payment Created',
  'payment.cardnumber': 'Card Number',
  'payment.expiration.date': 'Exp Date',
  'payment.card.csc': 'CSC',
  'payment.card.change': 'change a credit card',
  'payment.card.remember':
    'Remember this card number for the next express checkout',
  'payment.card.remember.tip':
    'Record the current credit card payment information, and the next payment only needs to be authorized for quick checkout. And reserves the right to modify and delete.',
  'payment.expiration.date.placeholder': 'MM/YY',
  'payment.card.csc.placeholder': 'CSC',
  'payment.confirmation.window.title': 'You are about to send an order',
  'payment.confirmation.window.info':
    'After placing the order, the store will start to prepare meals for you immediately, please confirm the following order information:',
  'payment.confirmation.window.info.DINE_IN':
    'The store will start making meals after the payment is completed at the checkout in the store. Please confirm the following order information:',
  'payment.confirmation.window.notice.Takeout':
    '※The meal preparation time is at least 15-20 minutes, please be patient. For exact pick-up time, please contact the store by phone to confirm.',
  'payment.confirmation.window.notice.DINE_IN':
    '※ After placing your order, please pay at the in-store counter or self-checkout.',
  'payment.confirmation.window.notice.DELIVERY':
    '※Please understand that delivery time will be extended due to peak hours.',
  'payment.confirmation.window.notice':
    '※ After confirming the delivery of the order, please go to the store to pay and pick up the food. If you want to modify or cancel your order, please contact the store. Please do not abandon the order without reason. ',
  'payment.confirmation.window.payTotal': 'Payment amount:',
  'payment.confirmation.window.payMethod': 'payment method:',
  'payment.confirmation.window.takeOrderMethod': 'pick up:',
  'payment.confirmation.window.orderTime': 'Order Time：',
  'payment.confirmation.window.DELIVERY.address': 'Delivery Address：',
  'payment.confirmation.window.DINE_IN': 'For Here',
  'payment.confirmation.window.TAKE_OUT': 'To Go',
  'payment.confirmation.window.DELIVERY': 'Delivery',
  'payment.confirmation.window.cancel': 'Cancel',
  'payment.confirmation.window.confirmOrder': 'Confirm',
  'payment.confirmation.window.serveTypes.TAKE_OUT': 'To Go',
  'payment.confirmation.window.serveTypes.SCHEDULE_TAKE_OUT':
    'Pick Up Reserve At {time}',
  'payment.confirmation.window.serveTypes.DELIVERY':
    'Delivery Reserve At {time}',
  'payment.confirmation.window.serveTypes.PRINT_AFTER_PAY.TAKEOUT':
    '※ Only after the checkout and payment are completed in the store, the store starts to prepare the meal. If it is inconvenient to wait on-site, it is recommended to select "Online Payment" and pick up the meal at the store at the estimated time of completion of the meal.',
  'payment.confirmation.window.serveTypes.PRINT_AFTER_PAY.DINE_IN':
    '※ Only after the checkout and payment are completed in the store, the store starts to prepare the meal.',
  'payment.coupon.remind':
    'Please place an order again, or ask the service staff for assistance',
  'payment.submit.remind':
    'After selecting the payment method, you need to click the「{ctaString}」button below to complete the order and send it out.',
  'payment.select.other.method': 'Please choose another payment method',
};

const detail: Ii18n = {
  'detail.order.status': 'Order Status',
  'detail.customid': 'Order Number',
  'detail.preorderTime': 'Pickup Time',
  'detail.printafterorder.preorder.desc':
    'Your Order Will Be Ready At Your Selected Time, Please Pay At Store',
  'detail.printafterpay.preorder.desc':
    'The store will start making meals only after the in-store checkout payment is completed.',
  'detail.pay.pending': 'Pay Pending',
  'detail.pay.store': 'Pay in store',
  'detail.pay.autocancel1': 'Please Pay At Store Or Pay Online before',
  'detail.pay.autocancel2': '',
  'detail.qrcode': 'Select To Show And Scan QRCODE',
  'detail.qrcode.pickup':
    'Press this button to show QRCODE, scan and take your meal.',
  'detail.qrcode.title': 'Scan',
  'detail.qrcode.desc': 'Please Scan QRCODE At The Machine To Pay',
  'detail.cancel.order':
    '* Contact Store If You Want To Change Or Cancel Order',
  'detail.pay.other': 'Please Select Other Payment',
  'detail.pay.online': 'Online Payment',
  'detail.order': 'Order Detail',
  'detail.order.payment': 'Payment',
  'detail.order.store.name': 'Store Name',
  'detail.order.dateTime': 'Order Create Time',
  'detail.order.servetype': 'Serve Type',
  'detail.order.seats': 'Seats',
  'detail.order.customerName': 'Name',
  'detail.order.mobile': 'Mobile',
  'detail.order.address': 'Address',
  'detail.order.comment': 'Comment',
  'detail.order.pickup.time': 'Pickup Time',
  'detail.order.pickup.cabinet.number': 'Pickup Cabinet Number',
  'detail.order.pickup.pincode': 'Cabinet Password: : ',
  'detail.order.pickup.address': 'Pickup Address',
  'detail.order.pickup.payment': 'Pickup Type',
  'detail.order.cancel': 'Cancel',
  'detail.order.cancel.ask': 'Agree to cancel order ?',
  'detail.order.cancel.already': 'Order is cancelled.',
  'detail.detail': 'Detail',
  'detail.back.to.search.order': 'back to search order',
  'detail.welcome': 'Order Again',
  'detail.payment.CASH': 'Cash',
  'detail.payment.LINEPAY': 'LINE Pay',
  'detail.payment.ApplePay': 'Apple Pay',
  'detail.payment.GooglePay': 'Google Pay',
  'detail.payment.TAPPAY': 'Tappay',
  'detail.payment.TAPPAY_DIRECT': 'Credit Card',
  'detail.payment.BBPOS_ONLINE': 'BBPOS',
  'detail.payment.JKOPAY': 'Jkopay',
  'detail.payment.PAYPAL': 'Paypal',
  'detail.payment.CTBC_ONLINE': 'CTBC Online',
  'detail.payment.TAIWANPAY': 'Taiwan Pay',
  'detail.takeout.preordertime': 'Pickup Time',
  'detail.takeout.only.message':
    'You have finished your payment. Your order is preparing.',
  'detail.takeout.message':
    'You have finished your payment. Your order will be ready at pickup time.',
  'detail.delivery.preordertime': 'Delivery Time',
  'detail.delivery.message':
    'You have finished your payment. Your order will be delivered at delivery time.',
  'detail.delivery.message.location':
    'You have finished your payment., Your order will be delivered to specified location at delivery time',
  'detail.delivery.notice': '* Delivery Time Might Vary Due To Traffic',
  'detail.cubbie.title': 'Cubbie Info',
  'detail.cubbie.qrcode': 'Select Here To Show QRCODE To Pick Up Your Order',
  'detail.order.tag.cash': 'CASH',
  'detail.order.tag.refund': 'Order refund',
  'detail.order.tag.cancel': 'Order cancellation',
  'detail.want.change.order':
    '* If you want to modify the order content or cancel the order, please contact the store by phone. ',
  'detail.cancelled.order':
    '* The store canceled the order. If you have any questions, please contact the store by phone. ',
  'detail.order.paid.message':
    'Your order has been paid successfully and the meal has been prepared for you',
  'detail.order.cancelled.reminder.title': 'Order canceled',
  'detail.order.cancelled.reminder.msg': 'Restart a new order',
  'detail.order.refund.reminder.title': 'Order has been refunded',
  'detail.order.reminder.TAKEOUT.preorderTime.msg':
    'Your order has been paid successfully and the meal will be picked up at the reserved time',
  'detail.order.reminder.TAKEOUT.preorderTime.msg.end': 'Preparation completed',
  'detail.order.reminder.DELIVERY.preorderTime.msg':
    'Your order has been paid successfully and the meal will be delivered at the scheduled delivery time',
  'detail.order.reminder.DELIVERY.cash.preorderTime.msg':
    'Your order has been placed successfully and the meal will be delivered at the scheduled delivery time',
  'detail.order.reminder.DELIVERY.preorderTime.msg.end':
    'Deliver to the specified address as soon as possible before',
  'detail.order.reminder.DELIVERY.msg':
    '* Delivery time will be extended due to peak hours, please forgive me',
  'detail.sharelMeal.addon.limit.time': 'Add link is valid until',
};

const orderdetail: Ii18n = {
  'orderdetail.itemcount': '{count} Item(s)',
  'orderdetail.count': 'Item(s)',
  'orderdetail.subtotal': 'Subtotal',
  'orderdetail.discount': 'Discount',
  'orderdetail.coupon': 'Coupon Discount',
  'orderdetail.fee': 'Fee',
  'orderdetail.shareMeal': 'Share Fee',
  'orderdetail.total': 'Total',
  'orderdetail.count.total': 'Total:',
};

const bulletin: Ii18n = {
  'bulletin.title': 'Announcement',
  'bulletin.confirm': 'OK',
};

const tag: Ii18n = {
  'tag.SPECIAL_PRICE': 'On Sale',
};

const notice: Ii18n = {
  'notice.title': 'Notice',
  'notice.content':
    'You Will Be Redirecting To Third Party Payment Service. After Finishing Payment Process, You Will Be Redirecting Back To Our Page.',
  'notice.googlepay.1': 'Please Select ',
  'notice.googlepay.2': 'Copy URL',
  'notice.googlepay.3': 'And Paste URL On Chrome Browser To Continue Payment.',
};

const cds: Ii18n = {
  'cds.store': 'store',
  'cds.orderNo': 'orderNo',
  'cds.lineIn': 'lineIn',
  'cds.status': 'status',
  'cds.cabinetNo': 'cabinetNo',
  'cds.QUEUEING': 'queueing',
  'cds.CABINET': 'available',
  'cds.CLOSED': 'done',
  'cds.CANCELLED': 'canceled',
  'cds.EXPIRED': 'expired',
  'cds.WARNING': 'error',
  'cds.check.order.status': 'meal preparation status',
};

const changeStoreModal: Ii18n = {
  'changeStoreModal.cleanCart':
    'The cart will be clear if you leave this page.',
  'changeStoreModal.leave': 'Do you want to leave this page?',
};
const phison: Ii18n = {
  'phison.employee': 'Employee',
  'phison.guest': 'Guest',
  'phison.employee.input': 'Employee Id',
  'phison.employee.extNumber': 'ext.num (optional)',
  'phison.employee.id.empty': "Employee id can't not be emptied.",
  'phison.employee.id.error': 'Wrong employee id.',
};

const storeDetail: Ii18n = {
  'storeDetail.store': 'Store Info',
  'storeDetail.address': 'address',
  'storeDetail.map': 'navigation',
  'storeDetail.phone': 'phone',
  'storeDetail.call': 'call',
  'storeDetail.business.time': 'business hours',
  'storeDetail.business.in': 'open',
  'storeDetail.business.out': 'closed',
};

const pickUp: Ii18n = {
  'pick.up.prepare.message': 'Your order is preparing. Please wait patiently.',
  'pick.up.warning.message':
    'Your order is abnormal. Please ask the restaurant for help.',
  'pick.up.can.pickup.message':
    'Your order is prepared well. Please take your order by cabinet on your list below or scan the QR Code to see the details.',
  'pick.up.pickedup.message': 'Picked up completed. Thanks for your order！',
  'pick.up.expired.message':
    'Your order is expired. Please check detail of your order and contact our staff if you have any question.',
  'pick.up.status.no.pay': 'Outstanding',
  'pick.up.status.prepare': 'Is being held for pick up',
  'pick.up.status.on.shelf': ' Is ready for pick up',
  'pick.up.status.at.counter': 'At counter',
  'pick.up.status.pending.pull.off.shelf': 'Wait for take off the shelves',
  'pick.up.status.picked.up': 'Picked up',
  'pick.up.status.cancelled': 'Cancelled',
  'pick.up.status.refund': 'Refunded',
  'pick.up.status.warning': 'Warning',
  'pick.up.status.expired': 'Expired',
};

const locationSelector = {
  'location.selector.select.address': 'Please select loacation (required)',
  'location.selector.location': 'Location',
};

const delivery = {
  'uberDirect.error.invalid_params':
    'The parameters of your request were invalid.',
  'uberDirect.error.unknown_location':
    'The specified location was not understood.',
  'uberDirect.error.address_undeliverable':
    'The specified location is not in a deliverable area.',
  'uberDirect.error.address_undeliverable_limited_couriers':
    'The specified location is not in a deliverable area at this time because all couriers are currently busy.',
  'uberDirect.error.pickup_window_too_small':
    'The pickup window needs to be at least 10 minutes long.',
  'uberDirect.error.dropoff_deadline_too_early':
    'The dropoff deadline needs to be at least 20 minutes after the dropoff ready time.',
  'uberDirect.error.dropoff_deadline_before_pickup_deadline':
    'The dropoff deadline needs to be after the pickup deadline.',
  'uberDirect.error.dropoff_ready_after_pickup_deadline':
    'The dropoff ready time needs to be at or before the pickup deadline',
  'uberDirect.error.pickup_ready_too_early':
    'The payment failed due to overdue payment, and the meal cannot be delivered within the specified appointment time. Please place an order again or ask the store',
  'uberDirect.error.pickup_deadline_too_early':
    'The pickup deadline time needs to be at least 20 minutes from now.',
  'uberDirect.error.pickup_ready_too_late':
    'The pickup ready time needs to be within the next 30 days.',
  'uberDirect.error.customer_not_found': 'Customer does not exist.',
  'uberDirect.error.request_timeout': 'The request timed out…',
  'uberDirect.error.customer_suspended':
    'Your account is passed due. Payment is required.',
  'uberDirect.error.customer_blocked':
    'Your account is not allowed to create deliveries.',
  'uberDirect.error.customer_limited':
    'Your account’s limits have been exceeded.',
  'uberDirect.error.unknown_error': 'An unknown error happened.',
  'uberDirect.order.status.pending': 'Order Processing',
  'uberDirect.order.status.delivering': 'Order Delivery',
  'uberDirect.order.status.delivered': 'Delivery Complete',
  'uberDirect.order.delivered.time': 'Appointment delivery time',
  'delivery.please.input.delivery.address': 'Please enter delivery address',
  'uberDirect.deliveryMethod': 'Delivery Method',
  'uberDirect.deliverableAction.deliverable_action_meet_at_door':
    'meet at the door',
  'uberDirect.deliverableAction.deliverable_action_leave_at_door':
    'put the door',
  'delivery.please.input.address': 'please enter address',
  'delivery.please.input.address.warn': 'please enter address',
  'delivery.placeholder.address.msg': 'please enter address',
  'uberDirect.error.goto.welcome': 'Go To Welcome',
};

const member = {
  'member.userTerms.privacy': 'Have read and agreed',
  'member.common.and': 'and',
  'member.userTerms.title': 'User Terms',
  'member.profile.gender.DEFAULT': 'Please select',
  'member.profile.gender.MALE': 'Male',
  'member.profile.gender.FEMALE': 'Female',
  'member.profile.name.warn': 'The input name format is incorrect',
  'member.profile.phone.empty': 'Mobile phone number required',
  'member.profile.phone.format.warn': 'Input mobile phone format is wrong',
  'member.profile.phone.registered.warn':
    'Mobile phone number has been registered',
  'member.profile.title.name': 'name',
  'member.profile.title.name.placeholder': 'Please enter your name',
  'member.profile.title.phone': 'Mobile phone',
  'member.profile.title.birth': 'Birthday',
  'member.profile.title.gender': 'Gender',
  'member.header.remove.account': 'Delete member account',
  'member.header.title.MemberRegister': 'Register',
  'member.header.title.MemberLogin': 'Please enter your mobile phone number',
  'member.header.title.MemberOtpValidator':
    'Please enter the SMS verification code',
  'member.header.title.MemberProfile': 'Member profile',
  'member.MemberRegister.submit': 'Register and verify SMS',
  'member.MemberOtpValidator.time.prefix': 'Please Yu',
  'member.MemberOtpValidator.time.concat': 'Input for verification',
  'member.MemberOtpValidator.otp.valid.error': 'Input verification code error',
  'member.MemberOtpValidator.is.lock.error':
    "Today's text message sending limit has been reached",
  'member.common.btn.confirm': 'Confirm',
  'member.common.btn.send.otp': 'Send verification code',
  'member.common.btn.save': 'Save',
  'member.common.btn.login': 'Login',
  'member.modal.title.save.success': 'Save successfully',
  'member.modal.body.save.success':
    'Your member information has been changed and saved successfully. ',
  'member.modal.title.remove.account': 'Delete member account',
  'member.modal.body1.remove.account':
    'Deleting a member account will prevent you from logging in again. ',
  'member.modal.body2.remove.account':
    'Are you sure you want to delete the account? ',
  'member.modal.title.remove.account.success':
    'Delete member account successfully',
  'member.need-setting.reminder.msg':
    'Update member information immediately to easily obtain preferential information. ',
  'member.need-setting.reminder.btn': 'Go to member settings',
  'member.reminder.msg':
    'Become a member and receive discount information immediately',
  'member.reminder.btn': 'Join a member',
  'member.reminder.short.btn': 'Join a member',
  'member.menu.promotion': 'Member Discount/Points',
};

const ko: Ii18n = {
  ...locationSelector,
  ...storeDetail,
  ...cds,
  ...phison,
  ...changeStoreModal,
  ...confirmModal,
  ...redeemPoint,
  ...searchOrder,
  ...error,
  ...welcome,
  ...stores,
  ...tableinfo,
  ...attribute,
  ...common,
  ...cart,
  ...promotion,
  ...redeem,
  ...otp,
  ...coupon,
  ...scancoupon,
  ...recommendation,
  ...rulealert,
  ...postpay,
  ...payment,
  ...detail,
  ...orderdetail,
  ...bulletin,
  ...tag,
  ...notice,
  ...pickUp,
  ...delivery,
  ...member,
};
export default ko;
