import { Style } from '@/components/style';
import messageAction, { initMessage } from '@actions/message/messageAction';
import disableScroll from 'disable-scroll';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ReactComponent as Warn } from '@/assets/img/warn_round.svg';

function LoadingNError(props) {
  if (props) {
    initMessage(props);
  }

  const loading = useSelector<RootStateOrAny, any>(
    (state) => state.commonReducer.loading
  );

  const alertMessage = useSelector<RootStateOrAny, any>(
    (state) => state.messageReducer.alertMessage
  );

  const errorConfirm = useSelector<RootStateOrAny, any>(
    (state) => state.messageReducer.errorConfirm
  );

  const { errorMessage, errorMessageConfig } =
    useSelector<RootStateOrAny, any>(
      (state) => state.messageReducer.errorMessage
    ) || {};

  const clickConfirm = () => {
    errorConfirm();
    messageAction.setErrorMessage(undefined);
    messageAction.setErrorConfirm(() => {});
  };

  useEffect(() => {
    if (loading) {
      document.body.style.touchAction = 'none';
      disableScroll.on();
    } else {
      document.body.style.touchAction = '';
      disableScroll.off();
    }
  }, [loading]);
  const { secondaryColor, whiteColor, gray3Color, blackColor } =
    new Style().getUiConfig();
  return (
    <div>
      <div
        className="modal"
        id="loadingModal"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        style={{
          visibility: loading ? 'visible' : 'hidden',
          display: 'block',
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1060,
        }}
      >
        <div
          className="d-flex flex-column justify-content-center"
          style={{ height: '100%' }}
        >
          <div className="d-flex justify-content-center">
            <div
              className="spinner-border"
              style={{
                width: '3rem',
                height: '3rem',
                color: 'rgba(91, 174, 163, 0.9)',
              }}
              role="status"
            >
              <span className="sr-only" />
            </div>
          </div>
        </div>
      </div>

      {!isEmpty(errorMessage) ? (
        <div
          className="modal"
          id="errorMessageModal"
          tabIndex={-1}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          style={
            errorMessage !== ''
              ? {
                  display: 'block',
                  background: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 1070,
                }
              : { display: 'none' }
          }
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body" style={{ padding: '20px' }}>
                {/* error icon */}
                <div
                  className="mt-3 d-flex justify-content-center"
                  style={{ marginBottom: '20px' }}
                >
                  <Warn />
                </div>
                {/* title */}
                <div
                  className={`${errorMessageConfig.titleClass} d-flex justify-content-center`}
                  style={{ marginBottom: '12px' }}
                >
                  <h5
                    className="mt-1 fw-bolder modal-title"
                    style={{ color: blackColor, fontSize: '20px' }}
                  >
                    {errorMessageConfig.title ? (
                      errorMessageConfig.title
                    ) : errorMessage ? (
                      errorMessage
                    ) : (
                      <FormattedMessage id="error.title" />
                    )}
                  </h5>
                </div>

                {/* body msg */}
                <div
                  className={`mt-1 ${errorMessageConfig.bodyClass} d-flex justify-content-center`}
                  style={{
                    color: gray3Color,
                    fontSize: '14px',
                    marginBottom: '20px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {errorMessageConfig.title ? errorMessage : ''}
                </div>

                {/* botton */}
                <div>
                  <button
                    type="button"
                    className="btn fw-bolder"
                    style={{
                      backgroundColor: secondaryColor,
                      width: '100%',
                      color: whiteColor,
                    }}
                    onClick={clickConfirm}
                  >
                    {errorMessageConfig.button ? (
                      errorMessageConfig.button
                    ) : (
                      <FormattedMessage id="error.confirm" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {!isEmpty(alertMessage) ? (
        <div
          className="alert alert-danger"
          role="alert"
          style={{
            zIndex: 999999,
            opacity: 0.9,
            position: 'fixed',
            width: '90%',
            marginLeft: '5%',
            bottom: '65px',
          }}
        >
          <div className="d-flex justify-content-center">{alertMessage}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

LoadingNError.propTypes = {
  alertMessage: PropTypes.string,
  setAlertMessage: PropTypes.func,
  errorMessage: PropTypes.any,
  setErrorMessage: PropTypes.func,
};

export default LoadingNError;
