import { FormattedMessage } from 'react-intl';
import CdsStatusTable from './cdsStatusTable';
import './cdsStatus.scss';
const backgroundColor = '#F5F8F9';
const buttonBackgroundColor = '#FFFFFF';
function CdsStatus() {
  return (
    <div className="d-flex accordion accordion-flush justify-content-center mt-3">
      <div
        className="d-flex flex-column accordion-item "
        style={{
          width: '90%',
          background: backgroundColor,
        }}
      >
        <div
          className="accordion-header align-self-center"
          style={{ width: '90%' }}
          id="flush-headingChildStatus"
        >
          <div
            className="d-flex button  justify-content-center"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseChildStatus"
            style={{
              height: '50px',
              background: buttonBackgroundColor,
              borderRadius: '10px',
              boxShadow: '1px 1px 4px 0px rgb(0 0 0 / 20%)',
              fontSize: '18px',
            }}
          >
            <div className=" align-self-center ms-4">
              <FormattedMessage id="cds.check.order.status" />
            </div>
            <button
              style={{
                height: '20px',
                width: '20px',
                background: buttonBackgroundColor,
              }}
              className="accordion-button collapsed align-self-center shadow-none ms-1 "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseChildStatus"
            />
          </div>
        </div>
        <div
          id="flush-collapseChildStatus"
          className="accordion-collapse collapse"
          aria-labelledby="flush-headingChildStatus"
        >
          <div
            style={{
              background: backgroundColor,
              marginTop: '5px',
              height: '15px',
            }}
          />
          <CdsStatusTable />
        </div>
      </div>
    </div>
  );
}

export default CdsStatus;
