import PropTypes from 'prop-types';
import { Style } from '@/components/style';

const { primaryColor, whiteColor } = new Style().getUiConfig();
function SmallButton({ children, onClick, fontSize, uniqeClass }) {
  return (
    <button
      className={`btn d-flex align-items-center gap-2 ${uniqeClass}`}
      type="submit"
      style={{
        border: '1px solid',
        borderColor: primaryColor,
        borderRadius: '20px',
        backgroundColor: whiteColor,
        color: primaryColor,
        fontSize: fontSize ? fontSize : '14px',
        minHeight: '30px',
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

SmallButton.defaultProps = {
  onClick: () => {},
};

SmallButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fontSize: PropTypes.string,
  uniqeClass: PropTypes.string,
};

export default SmallButton;
