// import { MemberActionType } from '../../actions/member/memberTypes';
import { MemberActionType } from '@actions/member/memberTypes';

const initialState: any = {
  phone: null,
  points: 0,
  isOtpVerified: false,
  isLineIdBounded: false,
  isDisagreeLineToBind: false,
  memberInfoRes: null,
  storeInfoRes: null,
  memberPageState: '',
  memberLangs: [],
  showMemberDropDown: false,
  isMemberEditEntrance: false,
  isMemberRegisterEntrance: false,
  isUpdateMemberPhone: false,
};

const memberReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case MemberActionType.SET_MEMBER_PHONE:
      return {
        ...state,
        phone: payload,
      };

    case MemberActionType.SET_MEMBER_POINTS:
      return {
        ...state,
        points: payload,
      };

    case MemberActionType.SET_MEMBER_OTP_VERIFIED:
      return {
        ...state,
        isOtpVerified: payload,
      };
    case MemberActionType.SET_IS_LINEID_BOUNDED:
      return {
        ...state,
        isLineIdBounded: payload,
      };
    case MemberActionType.SET_IS_DISAGREE_LINE_TO_BIND:
      return {
        ...state,
        isDisagreeLineToBind: payload,
      };
    case MemberActionType.SET_MEMBER_INFO_RES:
      return {
        ...state,
        memberInfoRes: payload,
      };
    case MemberActionType.SET_STORE_INFO_RES:
      return {
        ...state,
        storeInfoRes: payload,
      };
    case MemberActionType.SET_MEMBER_PAGE_STATE:
      return {
        ...state,
        memberPageState: payload,
      };
    case MemberActionType.SET_MEMBER_LANGS:
      return {
        ...state,
        memberLangs: payload,
      };
    case MemberActionType.SET_SHOW_MEMBER_DROP_DOWN:
      return {
        ...state,
        showMemberDropDown: payload,
      };
    case MemberActionType.SET_MEMBER_EDIT_ENTRANCE:
      return {
        ...state,
        isMemberEditEntrance: payload,
      };
    case MemberActionType.SET_MEMBER_REGISTER_ENTRANCE:
      return {
        ...state,
        isMemberRegisterEntrance: payload,
      };
    case MemberActionType.SET_UPDATE_MEMBER_PHONE:
      return {
        ...state,
        isUpdateMemberPhone: payload,
      };
    case MemberActionType.SET_CURRENT_SELECTED_PHONE:
      return {
        ...state,
        currentSelectedPhone: payload,
      };

    default:
      return state;
  }
};

export default memberReducer;
