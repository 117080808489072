import { useState } from 'react';
import CartV2 from '../cartV2';

function Page3() {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="d-flex flex-column">
      {/* <CartV2 isCartOpen={isOpen} setCartOpen={setIsOpen} /> */}
      {!isOpen && (
        <button className="fixed-bottom" type="button" onClick={handleOpen}>
          Open
        </button>
      )}
    </div>
  );
}

export default Page3;
