import { Qrcode } from '@/components/common//qrcode';
import QRCode from 'qrcode.react';
import { Modal } from 'bootstrap';
import { CdsStatus } from '@/components/common/cds';
import {
  CancelTimeNotice,
  OrderStatusBar,
  QrModalButton,
  ReminderLayout,
  UberDirectStatus,
} from '@/components/common/kioskRDetail/components';
import '@/components/common/kioskRDetail/kioskRDetail.scss';
import { OrderDetailContent } from '@/components/common/orderDetailContent';
import TopStoreInfoDetail from '@/components/common/storeDetail/storeDetail';
import { Style } from '@/components/style';
import BottomButtonLayout from '@/ui/BottomButtonLayout';
import DetailItem from '@/ui/DetailItem';
import OutlineButton from '@/ui/OutlineButton';
import PrimaryButton from '@/ui/PrimaryButton';
import moment from 'moment';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import DeliveryType from '@/constants/DeliveryType';
import { isEmpty } from 'lodash';
import { ReactComponent as MemberLoggedIn } from '@/assets/img/membership_primary.svg';
import { RootStateOrAny, useSelector } from 'react-redux';

type Props = {
  mode: string;
  webStoreVo: any;
  orderGroupVo?: any;
  orderVo?: any;
  total: any;
  customId: any;
  preorderTime: any;
  serveType: any;
  orderUuid: any;
  dateTime: any;
  customerName: any;
  mobile: any;
  address: any;
  comment: any;
  payment: any;
  locale: string;
  closeQrModal?: any;
  paymentList: any;
  itemCount: any;
};

const {
  backgroundColor,
  primaryColor,
  alertColor,
  secondaryColor,
  gray2Color,
  whiteColor,
} = new Style().getUiConfig();

function useSearch() {
  const { search } = useLocation();
  const isSearch = new URLSearchParams(search).get('autoSearch');
  return useMemo(() => isSearch, [isSearch]);
}

function KioskRDetail({
  address,
  closeQrModal,
  comment,
  customId,
  customerName,
  dateTime,
  itemCount,
  locale,
  mobile,
  mode,
  orderGroupVo,
  orderUuid,
  orderVo,
  payment,
  paymentList,
  preorderTime,
  serveType,
  total,
  webStoreVo,
}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const isSearch = useSearch();
  const childOrderUuid = orderGroupVo?.orderVos[0].uuid;
  const getStatus = (orderGroupVo, orderVo) => {
    if (orderGroupVo) {
      return orderGroupVo.status;
    }
    if (orderVo) {
      return orderVo.status;
    }
    return '';
  };

  const getCancelTime = (webStoreVo, orderGroupVo, orderVo) => {
    let dateTime;
    if (orderGroupVo) {
      if (orderGroupVo.orderVos[0].preorderTime) {
        dateTime = new Date(orderGroupVo.orderVos[0].preorderTime);
      } else {
        dateTime = new Date(orderGroupVo.orderVos[0].dateTime);
      }
    }
    if (orderVo) {
      if (orderVo.preorderTime) {
        dateTime = new Date(orderVo.preorderTime);
      } else {
        dateTime = new Date(orderVo.dateTime);
      }
    }
    let cancelDateTime;

    if (webStoreVo.autoCancelMins === 0) {
      const end_time_hours = webStoreVo.endTime.split(':');
      const today = new Date();
      const today_year = today.getFullYear();
      const today_month = today.getMonth();
      const today_date = today.getDate();
      const final_cancel_time = new Date(
        today_year,
        today_month,
        today_date,
        end_time_hours[0],
        end_time_hours[1]
      );
      // eslint-disable-next-line @typescript-eslint/no-shadow
      let cancelDateTime = moment(final_cancel_time)
        .add(1, 'd')
        .format('YYYY/MM/DD HH:mm');
      if (end_time_hours[0] < 12) {
        cancelDateTime = moment(final_cancel_time)
          .add(1, 'd')
          .format('YYYY/MM/DD HH:mm');
      } else {
        cancelDateTime = moment(final_cancel_time).format('YYYY/MM/DD HH:mm');
      }
    } else {
      cancelDateTime = moment(dateTime)
        .add(webStoreVo.autoCancelMins, 'm')
        .format('YYYY/MM/DD HH:mm');
    }
    return cancelDateTime;
  };

  const pushToPayment = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('isCartSelectPayment', 'false');
    history.push({
      pathname: `${process.env.REACT_APP_VERSION}/online/order/${orderUuid}/payment`,
      search: `${queryParams}`,
    });
  };
  const pushToSearchOrder = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('isSearch');
    queryParams.delete('locale');
    history.push({
      pathname: `${process.env.REACT_APP_VERSION}/online/store/${webStoreVo.uuid}/searchOrder`,
      search: `${queryParams}`,
      state: location.state,
    });
  };

  const isUberDirectPlatform = () => {
    if (
      orderVo?.serveType === 'DELIVERY' &&
      webStoreVo.deliveryPlatform === DeliveryType.UBER_DIRECT
    ) {
      return true;
    }
    return false;
  };

  const getAddress = () => {
    if (isUberDirectPlatform()) {
      if (orderVo?.structuredAddress) {
        const structAddress = JSON.parse(orderVo.structuredAddress);
        const tempAddress = String(structAddress.state)?.concat(
          String(structAddress.city)?.concat(
            String(structAddress.street_address)
          )
        );
        return tempAddress;
      }
    }
    return address;
  };

  const qrcodeModalShow = () => {
    const modal = Modal.getOrCreateInstance(
      document.getElementById('qrcodeModal')
    );
    modal.show();
  };

  const isMember = useSelector<RootStateOrAny, any>(
    (state) => state.memberInfoReducer.isMember
  );
  return (
    <div className="pt-1 pb-1" style={{ backgroundColor }}>
      {webStoreVo && (orderGroupVo || orderVo) && total !== undefined ? (
        <div>
          <Qrcode
            childOrderUuid={childOrderUuid}
            itemCount={itemCount}
            total={total}
            locale={locale}
            qrcodeValue={
              mode === 'kiosk'
                ? window.location.href.split('/payment/kiosk')[0]
                : window.location.href.split('/detail')[0]
            }
            title={intl.formatMessage({ id: 'detail.qrcode.title' })}
            customId={customId}
            desc={
              mode === 'kiosk'
                ? intl.formatMessage({ id: 'detail.qrcode.desc' })
                : intl.formatMessage({ id: 'detail.qrcode.pickup.desc' })
            }
            closeQrModal={closeQrModal}
          />
          <div
            className="card border-0 ms-2 me-2 mt-3 mb-3"
            style={{
              borderRadius: '10px',
              boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
            }}
          >
            <TopStoreInfoDetail webStoreVo={webStoreVo} />
            <OrderStatusBar
              status={getStatus(orderGroupVo, orderVo)}
              customId={customId}
              mode={mode}
              paid={orderVo?.paid || orderGroupVo?.paid}
            />

            {mode === 'kiosk' ? (
              <div>
                {webStoreVo.takeoutKitchenReceipt === 'PRINT_AFTER_PAY' &&
                  serveType !== 'TAKE_OUT' && (
                    <div className="d-flex mt-3 justify-content-center">
                      <div
                        className="text-center fw-bold"
                        style={{
                          width: '90%',
                          fontSize: '12px',
                          color: alertColor,
                        }}
                      >
                        <FormattedMessage id="detail.printafterpay.preorder.desc" />
                      </div>
                    </div>
                  )}
                {serveType === 'TAKE_OUT' && isEmpty(preorderTime) && (
                  <div className="d-flex mt-3 justify-content-center">
                    <div
                      className="text-center fw-bold"
                      style={{
                        width: '90%',
                        fontSize: '12px',
                        color: alertColor,
                      }}
                    >
                      <FormattedMessage id="detail.printafterpay.preorder.desc" />
                    </div>
                  </div>
                )}
                {serveType === 'TAKE_OUT' && !isEmpty(preorderTime) && (
                  <div className="d-flex mt-3 justify-content-center">
                    <div
                      className="text-center fw-bold"
                      style={{
                        width: '90%',
                        fontSize: '12px',
                        color: alertColor,
                      }}
                    >
                      <FormattedMessage id="detail.printafterorder.preorder.desc" />
                    </div>
                  </div>
                )}
                {serveType === 'DINE_IN' &&
                  webStoreVo.takeoutKitchenReceipt === 'PRINT_AFTER_ORDER' && (
                    <div className="d-flex mt-3 justify-content-center">
                      <div
                        className="text-center fw-bold"
                        style={{
                          width: '90%',
                          fontSize: '12px',
                          color: alertColor,
                        }}
                      >
                        <FormattedMessage id="detail.printafterpay.preorder.desc" />
                      </div>
                    </div>
                  )}
                {(orderGroupVo || orderVo.serveType !== 'DELIVERY') && (
                  <ReminderLayout setBackGroundColor={alertColor}>
                    <div
                      className="p-1 pb-3 fw-bold d-flex justify-content-center"
                      style={{ fontSize: '20px' }}
                    >
                      <span>
                        <FormattedMessage id="detail.pay.store" />
                      </span>
                      &nbsp;
                      <span style={{ color: alertColor }}>
                        {new Intl.NumberFormat(locale, {
                          style: 'currency',
                          currency: webStoreVo.currency,
                          minimumFractionDigits: parseInt(
                            webStoreVo.roundingScale,
                            10
                          ),
                          maximumFractionDigits: parseInt(
                            webStoreVo.roundingScale,
                            10
                          ),
                        }).format(total)}
                      </span>
                    </div>

                    <CancelTimeNotice
                      time={getCancelTime(webStoreVo, orderGroupVo, orderVo)}
                    />
                    {/* <QrModalButton>
                      <FormattedMessage id="detail.qrcode" />
                    </QrModalButton> */}
                    <div
                      className="mt-3 text-center"
                      role="presentation"
                      onClick={() => qrcodeModalShow()}
                    >
                      <QRCode
                        style={{
                          borderRadius: '10px',
                          width: '60%',
                          height: '60%',
                        }}
                        value={
                          mode === 'kiosk'
                            ? window.location.href.split('/payment/kiosk')[0]
                            : window.location.href.split('/detail')[0]
                        }
                        size={200}
                        renderAs="canvas"
                        bgColor="#FFFFFF"
                        includeMargin
                      />
                    </div>
                  </ReminderLayout>
                )}

                <div
                  className="mt-3 mb-3 d-flex justify-content-center"
                  style={{ fontSize: '12px', color: '#808080' }}
                >
                  <FormattedMessage id="detail.cancel.order" />
                </div>

                {paymentList &&
                paymentList.filter(
                  (p) => !p.includes('STORE') && !p.includes('CASH')
                ).length > 0 ? (
                  <div
                    className="d-flex"
                    style={{ height: '50px', background: backgroundColor }}
                  >
                    <div
                      className="ms-3 me-3 align-self-center fw-bold"
                      style={{ fontSize: '20px' }}
                    >
                      <FormattedMessage id="detail.pay.other" />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {paymentList &&
                  paymentList.filter(
                    (p) => !p.includes('STORE') && !p.includes('CASH')
                  ).length > 0 && (
                    <div className="ms-2 me-2 mt-3 mb-3">
                      <OutlineButton onClick={pushToPayment}>
                        <FormattedMessage id="detail.pay.online" />
                      </OutlineButton>
                    </div>
                  )}
              </div>
            ) : (
              <>
                <ReminderLayout>
                  <div className="d-flex flex-column justify-content-center">
                    {getStatus(orderGroupVo, orderVo) !== 'CANCELLED' &&
                      preorderTime === undefined &&
                      serveType === 'TAKE_OUT' && (
                        <div
                          className="d-flex justify-content-center"
                          style={{ fontSize: '12px' }}
                        >
                          <span className="fw-bold align-self-center ms-5 me-5">
                            <FormattedMessage id="detail.takeout.only.message" />
                          </span>
                        </div>
                      )}
                    {getStatus(orderGroupVo, orderVo) !== 'CANCELLED' &&
                      preorderTime === undefined &&
                      serveType === 'DINE_IN' && (
                        <div
                          className="d-flex justify-content-center"
                          style={{ fontSize: '12px' }}
                        >
                          <span className="fw-bold align-self-center ms-5 me-5">
                            <FormattedMessage id="detail.takeout.only.message" />
                          </span>
                        </div>
                      )}
                    {getStatus(orderGroupVo, orderVo) !== 'CANCELLED' &&
                      (preorderTime !== undefined &&
                      serveType === 'TAKE_OUT' ? (
                        <div
                          className="d-flex justify-content-center text-center"
                          style={{ fontSize: '12px' }}
                        >
                          <span className="fw-bold align-self-center ms-5 me-5">
                            <FormattedMessage id="detail.takeout.message" />
                          </span>
                        </div>
                      ) : (
                        serveType === 'DELIVERY' && (
                          <div
                            className="d-flex justify-content-center"
                            style={{ fontSize: '12px' }}
                          >
                            <span className="mx-5 fw-bold align-self-center text-center">
                              {webStoreVo.tradingLocations &&
                              !isUberDirectPlatform() ? (
                                <div className="pb-2">
                                  <FormattedMessage id="detail.delivery.message.location" />
                                </div>
                              ) : (
                                <div>
                                  {isUberDirectPlatform() ? (
                                    <div>
                                      <UberDirectStatus
                                        preorderTime={orderVo.preorderTime}
                                        uberStatus={orderVo.deliveryStatus}
                                      />
                                      <FormattedMessage id="detail.delivery.message" />
                                    </div>
                                  ) : (
                                    <div>
                                      <FormattedMessage id="detail.delivery.message" />
                                    </div>
                                  )}
                                </div>
                              )}
                            </span>
                          </div>
                        )
                      ))}
                    {getStatus(orderGroupVo, orderVo) !== 'CANCELLED' &&
                      (preorderTime !== undefined &&
                      serveType === 'TAKE_OUT' ? (
                        <div
                          className="d-flex justify-content-center text-center"
                          style={{ fontSize: '12px' }}
                        >
                          <span className="fw-bold align-self-center">
                            <FormattedMessage id="detail.takeout.preordertime" />
                          </span>
                          <span
                            className="align-self-center fw-bold"
                            style={{ color: primaryColor }}
                          >
                            {preorderTime}
                          </span>
                        </div>
                      ) : (
                        preorderTime !== undefined &&
                        serveType === 'DELIVERY' &&
                        !isUberDirectPlatform() && (
                          <div
                            className="d-flex justify-content-center pb-2"
                            style={{ fontSize: '12px' }}
                          >
                            <span className="fw-bold align-self-center">
                              <FormattedMessage id="detail.delivery.preordertime" />
                            </span>
                            <span
                              className="mx-1 align-self-center fw-bold"
                              data-testid="statusDeliveryTime"
                              style={{ color: primaryColor }}
                            >
                              {preorderTime}
                            </span>
                          </div>
                        )
                      ))}
                    {getStatus(orderGroupVo, orderVo) !== 'CANCELLED' &&
                      serveType === 'DELIVERY' && (
                        <div
                          className="d-flex justify-content-center"
                          style={{ fontSize: '12px', color: secondaryColor }}
                        >
                          <span className="fw-bold align-self-center">
                            <FormattedMessage id="detail.delivery.notice" />
                          </span>
                        </div>
                      )}
                    {getStatus(orderGroupVo, orderVo) === 'CANCELLED' && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ fontSize: '12px', color: secondaryColor }}
                      >
                        <span className="fw-bold align-self-center">
                          <FormattedMessage id="common.order.cancel" />
                        </span>
                      </div>
                    )}
                  </div>
                  {orderGroupVo ? <CdsStatus /> : ''}
                </ReminderLayout>
                <div
                  className="mt-3 mb-3 d-flex justify-content-center"
                  style={{ fontSize: '12px', color: '#808080' }}
                >
                  <FormattedMessage id="detail.cancel.order" />
                </div>
                {webStoreVo.webUiConfig.showCubbyQrcode ? (
                  <>
                    {' '}
                    <div
                      className="d-flex"
                      style={{ height: '50px', background: backgroundColor }}
                    >
                      <div
                        className="ms-3 me-3 align-self-center fw-bold"
                        style={{ fontSize: '20px' }}
                      >
                        <FormattedMessage id="detail.cubbie.title" />
                      </div>
                    </div>
                    <div className="ms-2 me-2 mt-3 mb-3">
                      <QrModalButton>
                        <FormattedMessage id="detail.qrcode.pickup" />
                      </QrModalButton>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
            {/* 訂購資訊 */}
            <div
              className="d-flex"
              style={{ height: '50px', background: backgroundColor }}
            >
              <div
                className="ms-3 me-3 align-self-center fw-bold mr-1"
                style={{ fontSize: '20px' }}
              >
                <FormattedMessage id="detail.order" />
              </div>
              <div className="align-self-center d-flex">
                {isMember && <MemberLoggedIn width="24px" height="24px" />}
              </div>{' '}
            </div>
            {mode === 'detail' && payment !== undefined ? (
              <DetailItem title="detail.order.payment">{payment}</DetailItem>
            ) : (
              ''
            )}

            <DetailItem title="detail.order.dateTime">{dateTime}</DetailItem>

            <DetailItem title="detail.order.servetype">
              {isUberDirectPlatform() ? (
                <FormattedMessage
                  id={`uberDirect.deliverableAction.${orderVo.deliverableAction}`}
                />
              ) : (
                <FormattedMessage id={`tableinfo.serveType.${serveType}`} />
              )}
              {/* <FormattedMessage id={`tableinfo.serveType.${serveType}`} /> */}
            </DetailItem>

            {orderVo?.table?.seats?.length > 0 ? (
              <div>
                <DetailItem title="detail.order.seats">
                  {orderVo.table.seats.join(',')}
                </DetailItem>
              </div>
            ) : (
              ''
            )}
            {preorderTime !== undefined && serveType === 'TAKE_OUT' ? (
              <DetailItem title="detail.takeout.preordertime">
                {preorderTime}
              </DetailItem>
            ) : (
              preorderTime !== undefined &&
              serveType === 'DELIVERY' && (
                <DetailItem title="detail.delivery.preordertime">
                  {preorderTime}
                </DetailItem>
              )
            )}
            {customerName !== undefined ? (
              <DetailItem title="detail.order.customerName">
                {customerName}
              </DetailItem>
            ) : (
              ''
            )}
            {mobile !== undefined ? (
              <DetailItem title="detail.order.mobile">{mobile}</DetailItem>
            ) : (
              ''
            )}
            {address !== undefined && (
              <DetailItem title="detail.order.address">
                {getAddress()}
              </DetailItem>
            )}
            {comment !== undefined ? (
              <DetailItem title="detail.order.comment">{comment}</DetailItem>
            ) : (
              ''
            )}
            <div
              className="d-flex"
              style={{ height: '50px', background: backgroundColor }}
            >
              <div
                className="ms-3 me-3 align-self-center fw-bold"
                style={{ fontSize: '20px' }}
              >
                <FormattedMessage id="detail.detail" />
              </div>
            </div>
            <OrderDetailContent
              locale={locale}
              webStoreVo={webStoreVo}
              orderVo={orderVo}
              orderGroupVo={orderGroupVo}
            />
            {isSearch && (
              <BottomButtonLayout>
                <PrimaryButton onClick={pushToSearchOrder}>
                  <FormattedMessage id="detail.back.to.search.order" />
                </PrimaryButton>
              </BottomButtonLayout>
            )}
          </div>
          {isSearch && <div style={{ height: '60px' }} />}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default KioskRDetail;
