import { CommonActionType } from '../../actions/common/commonTypes';

const initialState: any = { loading: false };
const commonReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case CommonActionType.LOADING:
      return {
        ...state,
        loading: true,
      };
    case CommonActionType.COMPLETE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default commonReducer;
