import { FormattedMessage } from 'react-intl';
import StoreUtils from '@/utils/storeInfoUtils';
import { img } from '../../../assets';

type props = {
  style: any;
  webStoreVo: any;
  isPayment?: any;
};
function BottomStoreInfo(props: props) {
  return (
    <div>
      {props.webStoreVo.name ? (
        props.isPayment ? (
          props.webStoreVo.webUiConfig?.showStoreInfo ? (
            <div
              className="text-center d-flex justify-content-center"
              style={{
                height: '32px',
                fontSize: '9px',
                background: props.style.getUiConfig().backgroundColor,
              }}
            >
              <span className="align-self-center">
                <a
                  style={{ color: props.style.getUiConfig().secondaryColor }}
                  href=""
                  data-bs-toggle="modal"
                  data-bs-target="#privacyModal"
                >
                  <FormattedMessage id="welcome.privacy" />
                </a>
              </span>
              <span
                className="align-self-center"
                style={{
                  marginLeft: '10px',
                  color: props.style.getUiConfig().gray3Color,
                }}
              >
                Powered By:{' '}
                <a
                  style={{ color: props.style.getUiConfig().secondaryColor }}
                  href={props.webStoreVo.supplierWebsite}
                >
                  {props.webStoreVo.supplierName}
                </a>
              </span>
            </div>
          ) : (
            ''
          )
        ) : (
          <div>
            <div
              style={{
                marginLeft: '30px',
                marginRight: '30px',
              }}
            >
              <div
                className="text-center"
                style={{
                  color: props.style.getUiConfig().gray3Color,
                  borderBottom: `1px solid ${
                    props.style.getUiConfig().gray3Color
                  }`,
                  fontSize: '12px',
                  fontWeight: 700,
                  paddingTop: '10px',
                  paddingBottom: '5px',
                }}
              >
                {props.webStoreVo.name}
              </div>
            </div>
            {props?.webStoreVo?.name && (
              <div
                style={{
                  color: props.style.getUiConfig().gray3Color,
                  fontSize: '10px',
                  marginLeft: '82px',
                  marginTop: '10px',
                  fontWeight: 300,
                }}
              >
                <FormattedMessage id="common.store.name" />
                {props.webStoreVo.name}
              </div>
            )}
            {StoreUtils.isStoreIdentifierValid(props.webStoreVo) && (
              <div
                style={{
                  color: props.style.getUiConfig().gray3Color,
                  fontSize: '10px',
                  marginLeft: '82px',
                  fontWeight: 300,
                }}
              >
                <FormattedMessage id="common.store.identifier" />
                {props.webStoreVo.identifier}
              </div>
            )}
            {props.webStoreVo.startTime && props.webStoreVo.endTime ? (
              <div
                style={{
                  color: props.style.getUiConfig().gray3Color,
                  fontSize: '10px',
                  marginLeft: '82px',
                  fontWeight: 300,
                }}
              >
                <div>
                  <FormattedMessage
                    id="welcome.button.bussiness.time"
                    values={{
                      startTime: props.webStoreVo?.startTime,
                      endTime: props.webStoreVo?.endTime,
                    }}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            
            {props.webStoreVo.phone || props.webStoreVo.email ? (
              <div
                style={{
                  color: props.style.getUiConfig().gray3Color,
                  fontSize: '10px',
                  marginLeft: '82px',
                  marginTop: '0px',
                  fontWeight: 300,
                }}
              >
                {props.webStoreVo.email ? (
                  <div>
                    <FormattedMessage id="welcome.email" />:{' '}
                    {props.webStoreVo.email}
                  </div>
                ) : (
                  <div>
                    <FormattedMessage id="welcome.address" />:{' '}
                    {props.webStoreVo.address}
                  </div>
                )}
                {props.webStoreVo.phone ? (
                  <div>
                    <a
                      href={`tel:${props.webStoreVo.phone}`}
                      style={{ color: props.style.getUiConfig().gray3Color }}
                    >
                      <FormattedMessage id="welcome.phone" />:{' '}
                      {props.webStoreVo.phone}
                    </a>
                  </div>
                ) : (
                  ''
                )}
                <div>
                  {props.webStoreVo.fb ? (
                    <a href={props.webStoreVo.fb}>
                      <img
                        style={{ width: '15px', marginRight: '15px' }}
                        src={img.icon.fb}
                        alt=""
                      />
                    </a>
                  ) : (
                    ''
                  )}
                  {props.webStoreVo.line ? (
                    <a href={props.webStoreVo.line}>
                      <img
                        style={{ width: '15px' }}
                        src={img.icon.line}
                        alt=""
                      />
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}

            {props.webStoreVo.webUiConfig?.showStoreInfo ? (
              <div
                className="text-center d-flex justify-content-center"
                style={{
                  marginTop: '20px',
                  height: '32px',
                  fontSize: '9px',
                  background: props.style.getUiConfig().backgroundColor,
                }}
              >
                <span className="align-self-center">
                  <a
                    style={{ color: props.style.getUiConfig().secondaryColor }}
                    href=""
                    data-bs-toggle="modal"
                    data-bs-target="#privacyModal"
                  >
                    <FormattedMessage id="welcome.privacy" />
                  </a>
                </span>
                <span
                  className="align-self-center"
                  style={{
                    marginLeft: '10px',
                    color: props.style.getUiConfig().gray3Color,
                  }}
                >
                  Powered By:{' '}
                  <a
                    style={{ color: props.style.getUiConfig().secondaryColor }}
                    href={props.webStoreVo.supplierWebsite}
                  >
                    {props.webStoreVo.supplierName}
                  </a>
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        )
      ) : props.webStoreVo.webUiConfig?.showStoreInfo ? (
        <div
          className="text-center d-flex justify-content-center"
          style={{
            marginTop: '20px',
            height: '32px',
            fontSize: '9px',
            background: props.style.getUiConfig().backgroundColor,
          }}
        >
          <span className="align-self-center">
            <a
              style={{ color: props.style.getUiConfig().secondaryColor }}
              href=""
              data-bs-toggle="modal"
              data-bs-target="#privacyModal"
            >
              <FormattedMessage id="welcome.privacy" />
            </a>
          </span>
          <span
            className="align-self-center"
            style={{
              marginLeft: '10px',
              color: props.style.getUiConfig().gray3Color,
            }}
          >
            Powered By:{' '}
            <a
              style={{ color: props.style.getUiConfig().secondaryColor }}
              href={props.webStoreVo.supplierWebsite}
            >
              {props.webStoreVo.supplierName}
            </a>
          </span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default BottomStoreInfo;
