const enum CustomizeWelcomeActionType {
  SET_WELCOME_LOCALE = 'SET_WELCOME_LOCALE',
  SET_WELCOME_NAME = 'SET_WELCOME_NAME',
  SET_WELCOME_AREA_CODE = 'SET_WELCOME_AREA_CODE',
  SET_WELCOME_PHONE = 'SET_WELCOME_PHONE',
  SET_WELCOME_DATE_PREORDER_TIME = 'SET_WELCOME_DATE_PREORDER_TIME',
  SET_WELCOME_TIME_PREORDER_TIME = 'SET_WELCOME_TIME_PREORDER_TIME',
  SET_WELCOME_ADDRESS = 'SET_WELCOME_ADDRESS',
  SET_WELCOME_SHARE_MEAL_CONDICTIONS = 'SET_WELCOME_SHARE_MEAL_CONDICTIONS',
  SET_WELCOME_COMMENT = 'SET_WELCOME_COMMENT',
  SET_SELECTED_SERVETYPE = 'SET_SELECTED_SERVETYPE',
  SET_SELECTED_TAKEOUT_SERVETYPE = 'SET_SELECTED_TAKEOUT_SERVETYPE',
  SET_IS_SELECTED_SERVETYPE_VALID = 'SET_IS_SELECTED_SERVETYPE_VALID',
  SET_IS_CUSTOMER_PHONE_VALID = 'SET_IS_CUSTOMER_PHONE_VALID',
  SET_IS_CUSTOMER_NAME_VALID = 'SET_IS_CUSTOMER_NAME_VALID',
  SET_IS_CUSTOMER_ADDRESS_VALID = 'SET_IS_CUSTOMER_ADDRESS_VALID',
  SET_IS_SELECTED_SHAREMEAL_VALID = 'SET_IS_SELECTED_SHAREMEAL_VALID',
  SET_IS_SELECTED_PREORDER_TIME_VALID = 'SET_IS_SELECTED_PREORDER_TIME_VALID',
}

export { CustomizeWelcomeActionType };
