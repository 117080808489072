import React from 'react';

const CurrentOrder = React.lazy(() => import('./currentOrder'));
const CustomWelcome = React.lazy(() => import('./customWelcome'));
const RedirectOrder = React.lazy(() => import('./redirectOrder'));
const SubmitPayment = React.lazy(() => import('./submitPayment'));
const SubmitOrder = React.lazy(() => import('./submitOrder'));
const Ctbc = React.lazy(() => import('./ctbc'));
const Bbpos = React.lazy(() => import('./bbpos'));
const Paypal = React.lazy(() => import('./paypal'));
const Jkopay = React.lazy(() => import('./jkopay'));
const TappayDirect = React.lazy(() => import('./tappaydirect'));
const WelcomeRedirect = React.lazy(() => import('./welcomeRedirect'));

export {
  CurrentOrder,
  CustomWelcome,
  RedirectOrder,
  SubmitPayment,
  SubmitOrder,
  Ctbc,
  Bbpos,
  Paypal,
  Jkopay,
  TappayDirect,
  WelcomeRedirect,
};
