export const enum ResponseCode {
  NO_ERROR = '0',

  ERROR = '1',
  UNSUPPORTED = '2',
  REQUEST_TIMEOUT = '3',
  FORBIDDEN = '4',
  BAD_REQUEST = '5',

  API_NO_ERROR = '0000',
  ORDER_STATUS_ABNORMAL = '10001',
  ORDER_CHECKED_OUT = '10002',
  ORDER_IS_PAYING = '10003',

  PAYMENT_NO_INVOICE = '20001',
  PAYMENT_ORDER_PAID = '20002',
  PAYMENT_NO_ORDER = '20003',
  PAYMENT_PAY_FAILED = '20004',
  PAYMENT_CREATE_FAILED = '20005',

  SEAT_NOT_FOUND = '30001',
  SEAT_HAS_NO_ORDER = '30002',
  SEAT_OCCUPIED = '30003',

  ORDER_NOT_FOUND = '40001',
  STORE_NOT_FOUND = '40002',
  STORE_NOT_OPEN = '40003',
  EMPLOYEE_NOT_FOUND = '40004',
  MEMBER_INFO_NOT_FOUND = '40005',
  PARSE_SALES_REPORT_ERROR = '40006',
  HTY_STORE_NOT_OPEN = '40007',
  HTY_NOT_A_MEMBER = '40008',
  HTY_CABINET_FULL = '40009',
  PICKED_UP = '40010',

  SOCKET_TIMEOUT = '50001',
  STORE_OFF_LINE = '50003',
}
