const enum PromotionType {
  DEFAULT = 'DEFAULT',
  DISCOUNT = 'DISCOUNT',
  PLATFORM_DISCOUNT = 'PLATFORM_DISCOUNT',
  PLATFORM_MEMBER_DISCOUNT = 'PLATFORM_MEMBER_DISCOUNT',
  AD_DISCOUNT = 'AD_DISCOUNT',
  SERVICE_FEE = 'SERVICE_FEE',
  SHARE_MEAL_FEE = 'SHARE_MEAL_FEE',
  DELIVERY_FEE = 'DELIVERY_FEE',
  DIFFERENCE_TO_MINIMUM_FEE = 'DIFFERENCE_TO_MINIMUM_FEE',
}

export default PromotionType;
