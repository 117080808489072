import OrderService from '../../services/order/orderService';
import { OrderActionType } from './orderTypes';

export const redirect: (
  orderUuid: string,
  seatUuid: string | null
) => any = async (orderUuid, seatUuid) => {
  try {
    const res = await OrderService.redirect(orderUuid, seatUuid);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const stores: (orderUuid: string) => any = async (orderUuid) => {
  try {
    const res = await OrderService.stores(orderUuid);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const recentorder: (
  storeUuid: string,
  mobile: string,
  lineUserId: string
) => any = async (storeUuid, mobile, lineUserId) => {
  try {
    const res = await OrderService.recentorder(storeUuid, mobile, lineUserId);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const recentordercommon: (
  storeUuid: string,
  employeeId: string
) => any = async (storeUuid, employeeId) => {
  try {
    const res = await OrderService.recentordercommon(storeUuid, employeeId);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const menu: (
  orderUuid: string,
  storeUuid?: string | null
) => any = async (orderUuid, storeUuid) => {
  try {
    const res = await OrderService.menu(orderUuid, storeUuid);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const getMenu: (orderVo: any) => any = async (orderVo) => {
  try {
    const res = await OrderService.getMenu(orderVo);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const rule: (orderVo: any) => any = async (orderVo) => {
  try {
    const res = await OrderService.rule(orderVo);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const getredeem: (orderVo: any) => any = async (orderVo) => {
  try {
    const res = await OrderService.getredeem(orderVo);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const getotp: (orderUuid: string, storeId: any) => any = async (
  orderUuid,
  storeId
) => {
  try {
    const res = await OrderService.getotp(orderUuid, storeId);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const sendOtp: (
  storeId: any,
  mobile: string,
  country: string,
  lineUserId: any
) => any = async (storeId, mobile, country, lineUserId) => {
  try {
    const res = await OrderService.sendOtp(
      storeId,
      mobile,
      country,
      lineUserId
    );
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const lineCheck: (
  storeId: any,
  mobile: string,
  country: string,
  lineUserId: any
) => any = async (storeId, mobile, country, lineUserId) => {
  try {
    const res = await OrderService.lineCheck(
      storeId,
      mobile,
      country,
      lineUserId
    );
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const lineRelation: (data: any) => any = async (data) => {
  try {
    const res = await OrderService.lineRelation(data);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const redeempoint: (
  orderUuid: string,
  otp: string,
  orderPromotionVos: any,
  storeId: any
) => any = async (orderUuid, otp, orderPromotionVos, storeId) => {
  try {
    const res = await OrderService.redeempoint(
      orderUuid,
      otp,
      orderPromotionVos,
      storeId
    );
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const validatecoupon: (
  orderUuid: string,
  time: string,
  barcodes: any,
  storeId: any
) => any = async (orderUuid, time, barcodes, storeId) => {
  try {
    const res = await OrderService.validatecoupon(
      orderUuid,
      time,
      barcodes,
      storeId
    );
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const updatemobile: (orderUuid: string, orderVo: any) => any = async (
  orderUuid,
  orderVo
) => {
  try {
    const res = await OrderService.updatemobile(orderUuid, orderVo);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const currentorder: (seatUuid: string) => any = async (seatUuid) => {
  try {
    const res = await OrderService.currentorder(seatUuid);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const customWelcome: (customUuid: string) => any = async (
  customUuid
) => {
  try {
    const res = await OrderService.customWelcome(customUuid);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const getrecommendation: (orderUuid: string, webVo: any) => any = async (
  orderUuid,
  webVo
) => {
  try {
    const res = await OrderService.getrecommendation(orderUuid, webVo);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const submitorder: (
  orderUuid: string,
  webVo: any,
  seatUuid: string | null
) => any = async (orderUuid, webVo, seatUuid) => {
  try {
    const res = await OrderService.submitorder(orderUuid, webVo, seatUuid);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const updateIncomingTiming: (
  orderUuid: string,
  webVo: any
) => any = async (orderUuid, webVo) => {
  try {
    const res = await OrderService.updateIncomingTiming(orderUuid, webVo);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const updateparty: (orderUuid: string, orderVo: any) => any = async (
  orderUuid,
  orderVo
) => {
  try {
    const res = await OrderService.updateparty(orderUuid, orderVo);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const addcouponbarcodes: (
  orderUuid: string,
  couponBarcodes: any
) => any = async (orderUuid, couponBarcodes) => {
  try {
    const res = await OrderService.addcouponbarcodes(orderUuid, couponBarcodes);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const getorder: (orderUuid: string) => any = async (orderUuid) => {
  try {
    const res = await OrderService.getorder(orderUuid);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

export const changeLocale: (orderUuid: string, locale: string) => any = async (
  orderUuid,
  locale
) => {
  try {
    const res = await OrderService.changeLocale(orderUuid, locale);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};

// TODO : refact
export const updateVo: (res) => any = (res) => async (dispatch) => {
  dispatch({
    type: OrderActionType.MENU,
    payload: res,
  });
};

export const updateWebCdsVo: (webCdsVos) => any =
  (webCdsVos) => async (dispatch) => {
    dispatch({
      type: OrderActionType.WEBCDSVOS,
      payload: webCdsVos,
    });
  };

export const updateStoreVo: (res) => any = (res) => async (dispatch) => {
  dispatch({
    type: OrderActionType.UPDATE_STORE,
    payload: res,
  });
};

export const addon: (storeUuid: string, data: any) => any = async (
  storeUuid,
  data
) => {
  try {
    const res = await OrderService.addon(storeUuid, data);
    return await Promise.resolve(res.data);
  } catch (err) {
    console.log('axios error', err);
    throw err;
  }
};
