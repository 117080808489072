// import { RedeemActionType } from '../../actions/member/memberTypes';
import { CouponActionType } from '../../actions/coupon/couponTypes';

const initialState: any = {
  couponStatus: 'null',
  showCouponSucess: false,
};

const couponReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case CouponActionType.SET_COUPON_STATUS:
      return {
        ...state,
        couponStatus: payload,
      };
    case CouponActionType.SET_SHOW_COUPON_SUCCESS:
      return {
        ...state,
        showCouponSucess: payload,
      };

    default:
      return state;
  }
};

export default couponReducer;
