import PropTypes from 'prop-types';
import { Style } from '@/components/style';

const { backgroundColor, whiteColor, primaryColor } = new Style().getUiConfig();
function InformationLayout({ children, title, titleIcon, endTitle }) {
  return (
    <div
      className="d-flex flex-column p-3 gap-2 "
      style={{ backgroundColor, borderRadius: '10px' }}
    >
      <div
        className="d-flex justify-content-between "
        style={{ fontSize: '14px', fontWeight: 500 }}
      >
        <div className="d-flex align-items-center gap-2 ">
          <div
            className="d-flex align-items-center"
            style={{ width: '20px', height: '20px' }}
          >
            <img src={titleIcon} alt={title} />
          </div>
          <div>{title}</div>
        </div>
        <div style={{ color: primaryColor, fontWeight: 800 }}>{endTitle}</div>
      </div>
      <div
        className="d-flex p-3 justify-content-between align-items-center"
        style={{ backgroundColor: whiteColor }}
      >
        {children}
      </div>
    </div>
  );
}

InformationLayout.defaultProps = {
  endTitle: '',
};

InformationLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  titleIcon: PropTypes.string.isRequired,
  endTitle: PropTypes.string,
};

export default InformationLayout;
