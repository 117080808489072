import { combineReducers } from 'redux';
import messageReducer from '@reducers/message/messageReducer';
import demoReducer from './demo/demoReducer';
import welcomeReducer from './welcome/welcomeReducer';
import customizeWelcomeReducer from './customizeWelcomeReducer/customizeWelcomeReducer';
import orderReducer from './order/orderReducer';
import commonReducer from './common/commonReducer';
import modeReducer from './mode/modeReducer';
import lineReducer from './line/lineReducer';
import memberReducer from './member/memberReducer';
import memberInfoReducer from './member/memberInfoReducer';
import redeemReducer from './redeem/redeemReducer';
import areaCodesReducer from './areaCodeReducer/areaCodeReducer';
import uberDirectReducer from './uberDirect/uberDirectReducer';
import cartV2Reducer from './cartV2/cartV2Reducer';
import couponReducer from './couponReducer/couponReducer';
import menuSingleStoreReducer from './menuSingleStoreReducer/menuSingleStoreReducer';

export default combineReducers({
  demoReducer,
  welcomeReducer,
  menuSingleStoreReducer,
  customizeWelcomeReducer,
  orderReducer,
  modeReducer,
  commonReducer,
  lineReducer,
  messageReducer,
  memberReducer,
  memberInfoReducer,
  redeemReducer,
  areaCodesReducer,
  uberDirectReducer,
  cartV2Reducer,
  couponReducer,
});
