import { CustomizeWelcomeActionType } from '../../actions/customizeWelcome/customizeWelcomeTypes';

const initialState: any = {
  welcomeLocale: '',
  selectedServeType: '',
  selectedTakeOutServeType: '',
  selectedCountryLang: '',
  customerName: '',
  selectedAreaCode: '',
  customerPhone: '',
  customerAddress: '',
  customerComment: '',
  selectedDatePreorderTime: '',
  selectedTimePreorderTime: '',
  shareMealCondiction: [],
  isSelectedServeTypeValid: true,
  isCustomerPhoneValid: true,
  isCustomerNameValid: true,
  isCustomerAddressValid: true,
  isShareMealCondictionValid: true,
  isSelectedPreorderTimeValid: true,
};

const customizeWelcomeReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case CustomizeWelcomeActionType.SET_WELCOME_LOCALE:
      return {
        ...state,
        welcomeLocale: payload,
      };

    case CustomizeWelcomeActionType.SET_SELECTED_SERVETYPE:
      return {
        ...state,
        selectedServeType: payload,
      };

    case CustomizeWelcomeActionType.SET_SELECTED_TAKEOUT_SERVETYPE:
      return {
        ...state,
        selectedTakeOutServeType: payload,
      };

    case CustomizeWelcomeActionType.SET_WELCOME_NAME:
      return {
        ...state,
        customerName: payload,
      };

    case CustomizeWelcomeActionType.SET_WELCOME_AREA_CODE:
      return {
        ...state,
        selectedAreaCode: payload,
      };

    case CustomizeWelcomeActionType.SET_WELCOME_PHONE:
      return {
        ...state,
        customerPhone: payload,
      };

    case CustomizeWelcomeActionType.SET_WELCOME_DATE_PREORDER_TIME:
      return {
        ...state,
        selectedDatePreorderTime: payload,
      };

    case CustomizeWelcomeActionType.SET_WELCOME_TIME_PREORDER_TIME:
      return {
        ...state,
        selectedTimePreorderTime: payload,
      };

    case CustomizeWelcomeActionType.SET_WELCOME_ADDRESS:
      return {
        ...state,
        customerAddress: payload,
      };

    case CustomizeWelcomeActionType.SET_WELCOME_COMMENT:
      return {
        ...state,
        customerComment: payload,
      };

    case CustomizeWelcomeActionType.SET_WELCOME_SHARE_MEAL_CONDICTIONS:
      return {
        ...state,
        shareMealCondiction: payload,
      };
    case CustomizeWelcomeActionType.SET_IS_SELECTED_SERVETYPE_VALID:
      return {
        ...state,
        isSelectedServeTypeValid: payload,
      };
    case CustomizeWelcomeActionType.SET_IS_CUSTOMER_PHONE_VALID:
      return {
        ...state,
        isCustomerPhoneValid: payload,
      };
    case CustomizeWelcomeActionType.SET_IS_CUSTOMER_NAME_VALID:
      return {
        ...state,
        isCustomerNameValid: payload,
      };
    case CustomizeWelcomeActionType.SET_IS_CUSTOMER_ADDRESS_VALID:
      return {
        ...state,
        isCustomerAddressValid: payload,
      };
    case CustomizeWelcomeActionType.SET_IS_SELECTED_SHAREMEAL_VALID:
      return {
        ...state,
        isShareMealCondictionValid: payload,
      };
    case CustomizeWelcomeActionType.SET_IS_SELECTED_PREORDER_TIME_VALID:
      return {
        ...state,
        isSelectedPreorderTimeValid: payload,
      };

    default:
      return state;
  }
};

export default customizeWelcomeReducer;
