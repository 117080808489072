import PropTypes from 'prop-types';
import Phone from '@/assets/img/phone.svg';
import InformationLayout from '@/components/common/storeDetail/informationLayout';
import SmallButton from '@/ui/smallButton';
import { FormattedMessage, useIntl } from 'react-intl';

function PhoneInformation({ phone }) {
  const handleCallPhoneOnClick = () => {
    document.location.href = `tel:${phone}`;
  };
  const phoneTitle = useIntl().formatMessage({ id: 'storeDetail.phone' });
  return (
    phone && (
      <InformationLayout title={phoneTitle} titleIcon={Phone}>
        <div
          style={{
            maxWidth: '70%',
            wordBreak: 'break-all',
            wordWrap: 'break-word',
            fontSize: '12px',
            fontWeight: 800,
          }}
        >
          {phone}
        </div>
        <SmallButton
          onClick={handleCallPhoneOnClick}
          fontSize="12px"
          uniqeClass="fw-bolder"
        >
          <FormattedMessage id="storeDetail.call" />
        </SmallButton>
      </InformationLayout>
    )
  );
}

PhoneInformation.propTypes = {
  phone: PropTypes.string,
};

export default PhoneInformation;
