import PropTypes from 'prop-types';
import FaceBook from '@/assets/img/fb.png';
import Line from '@/assets/img/line.png';
import SocialMediaIcon from '@/components/common/storeDetail/socialMediaIcon';

function SocialMediaRow({ webStoreVo }) {
  return (
    <div className="d-flex pb-2 gap-2">
      <SocialMediaIcon img={FaceBook} alt="fb" url={webStoreVo.fb} />
      <SocialMediaIcon img={Line} alt="line" url={webStoreVo.line} />
    </div>
  );
}

SocialMediaRow.propTypes = {
  webStoreVo: PropTypes.shape({
    fb: PropTypes.string,
    line: PropTypes.string,
  }).isRequired,
};

export default SocialMediaRow;
