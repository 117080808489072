import { FormattedMessage } from 'react-intl';
import './orderDetailContent.scss';
import PromotionType from '@/constants/PromotionType';
import Filter from '@/utils/filter';
import { useEffect, useState } from 'react';
import * as OrderAction from '@/actions/order/orderAction';

type props = {
  locale: string;
  webStoreVo: any;
  orderVo?: any;
  orderGroupVo?: any;
};
function OrderDetailContent(props: props) {
  const [orderDetailGroupVo, setOrderDetailGroupVo] = useState<any>();
  const [orderDetailVo, setOrderDetailVo] = useState<any>();

  useEffect(() => {
    if (props.orderGroupVo) {
      OrderAction.rule(props.orderGroupVo)
        .then((data: any) => {
          setOrderDetailGroupVo(data.orderVo);
        })
        .catch((e) => {
          console.log('e', e);
        });
    } else if (props.orderVo) {
      OrderAction.rule(props.orderVo)
        .then((data: any) => {
          setOrderDetailVo(data.orderVo);
        })
        .catch((e) => {
          console.log('e', e);
        });
    }
  }, [props.orderVo, props.orderGroupVo]);

  const getCountFromOrderVo = (orderVo) => {
    let count = 0;
    if (orderVo) {
      orderVo.orderItems.forEach((item) => {
        if (item.categoryId) {
          count += item.quantity;
        }
      });
    }
    return count;
  };

  const getCount = () => {
    let count = 0;
    if (orderDetailGroupVo) {
      orderDetailGroupVo.orderItems.forEach((item) => {
        if (item.categoryId) {
          count += item.quantity;
        }
      });
    } else if (orderDetailVo) {
      orderDetailVo.orderItems.forEach((item) => {
        if (item.categoryId) {
          count += item.quantity;
        }
      });
    }

    return count;
  };

  const getTotal = () => {
    let total = 0;
    if (props.orderGroupVo) {
      props.orderGroupVo.orderVos.forEach((orderVo) => {
        total += orderVo.total;
      });
    } else if (props.orderVo) {
      total = props.orderVo.total;
    }
    return total;
  };

  const getSubtotal = () => {
    let subtotal = 0;
    if (props.orderGroupVo) {
      props.orderGroupVo.orderVos.forEach((orderVo) => {
        let itemUuids: any = [];
        orderVo.orderItems.forEach((item) => {
          itemUuids = [...itemUuids, ...item.orderItemUuids];
        });
        orderVo.orderItems.forEach((item) => {
          if (!itemUuids.includes(item.uuid)) {
            subtotal += item.total;
          }
        });
      });
    } else if (props.orderVo) {
      let itemUuids: any = [];
      props.orderVo.orderItems.forEach((item) => {
        itemUuids = [...itemUuids, ...item.orderItemUuids];
      });
      props.orderVo.orderItems.forEach((item) => {
        if (!itemUuids.includes(item.uuid)) {
          subtotal += item.total;
        }
      });
    }
    return subtotal;
  };

  const getDiscount = () => {
    let discount = 0;
    if (props.orderGroupVo) {
      props.orderGroupVo.orderVos.forEach((orderVo) => {
        orderVo.orderPromotions.forEach((orderPromotion) => {
          if (
            orderPromotion.couponBarcode === '' &&
            orderPromotion.discount > 0
          ) {
            discount += orderPromotion.discount;
          }
        });
      });
    } else if (props.orderVo) {
      props.orderVo.orderPromotions.forEach((orderPromotion) => {
        if (
          orderPromotion.couponBarcode === '' &&
          orderPromotion.discount > 0
        ) {
          discount += orderPromotion.discount;
        }
      });
    }
    return discount;
  };

  const getCouponDiscount = () => {
    let discount = 0;
    if (props.orderGroupVo) {
      props.orderGroupVo.orderVos.forEach((orderVo) => {
        orderVo.orderPromotions.forEach((orderPromotion) => {
          if (
            orderPromotion.couponBarcode !== '' &&
            orderPromotion.discount > 0
          ) {
            discount += orderPromotion.discount;
          }
        });
      });
    } else if (props.orderVo) {
      props.orderVo.orderPromotions.forEach((orderPromotion) => {
        if (
          orderPromotion.couponBarcode !== '' &&
          orderPromotion.discount > 0
        ) {
          discount += orderPromotion.discount;
        }
      });
    }
    return discount;
  };

  const getFee = () => {
    let result = 0;
    if (props.orderGroupVo) {
      props.orderGroupVo.orderVos.forEach((orderVo) => {
        Filter.PromotionFilter.exceptShareMealPromotions(
          orderVo.orderPromotions
        ).forEach((orderPromotion) => {
          if (orderPromotion.discount < 0) {
            result -= orderPromotion.discount;
          }
        });
      });
    } else if (props.orderVo) {
      Filter.PromotionFilter.exceptShareMealPromotions(
        props.orderVo.orderPromotions
      ).forEach((orderPromotion) => {
        if (orderPromotion.discount < 0) {
          result -= orderPromotion.discount;
        }
      });
    }
    return result;
  };

  const getShareMealFee = () => {
    let result = 0;
    if (props.orderGroupVo) {
      props.orderGroupVo.orderVos.forEach((orderVo) => {
        orderVo.orderPromotions.forEach((orderPromotion) => {
          if (
            orderPromotion.promotionType === PromotionType.SHARE_MEAL_FEE &&
            orderPromotion.discount < 0
          ) {
            result -= orderPromotion.discount;
          }
        });
      });
    } else if (props.orderVo) {
      props.orderVo.orderPromotions.forEach((orderPromotion) => {
        if (
          orderPromotion.promotionType === PromotionType.SHARE_MEAL_FEE &&
          orderPromotion.discount < 0
        ) {
          result -= orderPromotion.discount;
        }
      });
    }
    return result;
  };

  const attrString = (orderItem, orderVo) => {
    let result = '';
    orderItem.rawAttributes.forEach((attr, j) => {
      result += attr.title + ' : ' + attr.itemTitle + ' / ';
    });
    orderItem.orderItemUuids.map((orderItemUuid, k) => {
      orderVo.orderItems.map((orderItem2, l) => {
        if (orderItem2.uuid === orderItemUuid) {
          if (orderItem2.rawAttributes.length > 0) {
            result += orderItem2.title + ' / ';
          } else {
            result += orderItem2.title + ' : ';
          }
          orderItem2.rawAttributes.forEach((attr, m) => {
            result += attr.title + ' : ' + attr.itemTitle + ' / ';
          });
        }
      });
    });
    return result.substring(0, result.length - 2);
  };

  const getOrderItemTotal = (orderItem, orderVo) => {
    let total = orderItem.total;
    // orderItem.orderItemUuids.forEach((uuid) => {
    //     orderVo.orderItems.some((item) => {
    //         if (uuid === item.uuid) {
    //             total += item.total;
    //             return true;
    //         }
    //         return false;
    //     });
    // });
    return total;
  };

  return (
    <div className="ms-3 me-3">
      <table className="table">
        <tbody>
          {props.orderVo
            ? props.orderVo.orderItems.map((orderItem, i) => {
                if (
                  !props.orderVo.orderItems.some((o) => {
                    if (o.orderItemUuids.includes(orderItem.uuid)) {
                      return true;
                    }
                    return false;
                  })
                ) {
                  return orderItem.quantity > 0 ? (
                    <tr key={i}>
                      <td>
                        <div
                          className="text-center"
                          style={{
                            background: '#A1A0A0',
                            color: '#fff',
                            width: '25px',
                            fontSize: '16px',
                          }}
                        >
                          {orderItem.quantity}
                        </div>
                      </td>
                      <td colSpan={2} style={{ width: '80%' }}>
                        <div>{orderItem.title}</div>
                        <div style={{ color: '#A1A0A0', fontSize: '14px' }}>
                          {attrString(orderItem, props.orderVo)}
                        </div>
                        {orderItem.comment && orderItem.comment.length > 0 ? (
                          <div style={{ color: '#A1A0A0', fontSize: '14px' }}>
                            <FormattedMessage
                              id="cart.comment"
                              values={{ comment: orderItem.comment }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </td>
                      <td
                        style={{
                          width: 'unset !important',
                          minWidth: '64px',
                          whiteSpace: 'nowrap',
                        }}
                        className="text-end"
                      >
                        {new Intl.NumberFormat(props.locale, {
                          style: 'currency',
                          currency: props.webStoreVo.currency,
                          minimumFractionDigits: parseInt(
                            props.webStoreVo.roundingScale
                          ),
                          maximumFractionDigits: parseInt(
                            props.webStoreVo.roundingScale
                          ),
                        }).format(getOrderItemTotal(orderItem, props.orderVo))}
                      </td>
                    </tr>
                  ) : (
                    <tr />
                  );
                }
              })
            : ''}
          {props.orderVo
            ? Filter.PromotionFilter.exceptShareMealPromotions(
                props.orderVo.orderPromotions
              ).map((orderPromotion, i) => (
                <tr key={i}>
                  <td>
                    <div
                      className="text-center invisible"
                      style={{
                        background: '#A1A0A0',
                        color: '#fff',
                        width: '25px',
                        fontSize: '16px',
                      }}
                    >
                      {orderPromotion.quantity}
                    </div>
                  </td>
                  <td colSpan={2} style={{ width: '80%' }}>
                    <div>
                      {orderPromotion.title}
                      {orderPromotion.redeemPoint &&
                      orderPromotion.redeemPoint < 0
                        ? ' x ' + orderPromotion.redeemPoint * -1
                        : ''}
                    </div>
                  </td>
                  <td
                    style={{
                      width: 'unset !important',
                      minWidth: '64px',
                      whiteSpace: 'nowrap',
                    }}
                    className="text-end"
                  >
                    {orderPromotion.discount === 0
                      ? ''
                      : new Intl.NumberFormat(props.locale, {
                          style: 'currency',
                          currency: props.webStoreVo.currency,
                          minimumFractionDigits: parseInt(
                            props.webStoreVo.roundingScale
                          ),
                          maximumFractionDigits: parseInt(
                            props.webStoreVo.roundingScale
                          ),
                        }).format(-orderPromotion.discount)}
                  </td>
                </tr>
              ))
            : ''}
          {props.orderGroupVo
            ? props.orderGroupVo.orderVos.map((orderVo) => {
                let result: any = [];
                result.push(
                  <tr
                    key={orderVo.storeId}
                    style={{ border: '0px solid #fff' }}
                  >
                    <td colSpan={2}>
                      <div className="fw-bold" style={{ fontSize: '16px' }}>
                        {orderVo.storeDisplayName}
                      </div>
                      <div
                        style={{
                          color: 'rgba(131, 131, 131, 1)',
                          fontSize: '14px',
                        }}
                      >
                        <FormattedMessage
                          id="orderdetail.itemcount"
                          values={{ count: getCountFromOrderVo(orderVo) }}
                        />
                      </div>
                    </td>
                    <td></td>
                    <td
                      style={{ width: 'unset !important' }}
                      className="text-end"
                    >
                      {new Intl.NumberFormat(props.locale, {
                        style: 'currency',
                        currency: props.webStoreVo.currency,
                        minimumFractionDigits: parseInt(
                          props.webStoreVo.roundingScale
                        ),
                        maximumFractionDigits: parseInt(
                          props.webStoreVo.roundingScale
                        ),
                      }).format(orderVo.total)}
                    </td>
                  </tr>
                );
                orderVo.orderItems.map((orderItem, i) => {
                  if (
                    !orderVo.orderItems.some((o) => {
                      if (o.orderItemUuids.includes(orderItem.uuid)) {
                        return true;
                      }
                      return false;
                    })
                  ) {
                    result.push(
                      <tr key={orderItem.categoryId}>
                        <td>
                          <div
                            className="text-center"
                            style={{
                              background: '#A1A0A0',
                              color: '#fff',
                              width: '25px',
                              fontSize: '16px',
                            }}
                          >
                            {orderItem.quantity}
                          </div>
                        </td>
                        <td colSpan={2} style={{ width: '80%' }}>
                          <div>{orderItem.title}</div>
                          <div style={{ color: '#A1A0A0', fontSize: '14px' }}>
                            {attrString(orderItem, orderVo)}
                          </div>
                          {orderItem.comment && orderItem.comment.length > 0 ? (
                            <div style={{ color: '#A1A0A0', fontSize: '14px' }}>
                              <FormattedMessage
                                id="cart.comment"
                                values={{ comment: orderItem.comment }}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </td>
                        <td
                          style={{
                            width: 'unset !important',
                            minWidth: '64px',
                            whiteSpace: 'nowrap',
                          }}
                          className="text-end"
                        >
                          {new Intl.NumberFormat(props.locale, {
                            style: 'currency',
                            currency: props.webStoreVo.currency,
                            minimumFractionDigits: parseInt(
                              props.webStoreVo.roundingScale
                            ),
                            maximumFractionDigits: parseInt(
                              props.webStoreVo.roundingScale
                            ),
                          }).format(getOrderItemTotal(orderItem, orderVo))}
                        </td>
                      </tr>
                    );
                  }
                });
                Filter.PromotionFilter.exceptShareMealPromotions(
                  orderVo.orderPromotions
                ).map((orderPromotion, i) => {
                  result.push(
                    <tr key={i}>
                      <td>
                        <div
                          className="text-center invisible"
                          style={{
                            background: '#A1A0A0',
                            color: '#fff',
                            width: '25px',
                            fontSize: '16px',
                            display: 'invisible',
                          }}
                        >
                          {orderPromotion.quantity}
                        </div>
                      </td>
                      <td colSpan={2} style={{ width: '80%' }}>
                        <div>
                          {orderPromotion.title}
                          {orderPromotion.redeemPoint &&
                          orderPromotion.redeemPoint < 0
                            ? ' x ' + orderPromotion.redeemPoint * -1
                            : ''}
                        </div>
                      </td>
                      <td
                        style={{
                          width: 'unset !important',
                          minWidth: '64px',
                          whiteSpace: 'nowrap',
                        }}
                        className="text-end"
                      >
                        {orderPromotion.discount === 0
                          ? ''
                          : new Intl.NumberFormat(props.locale, {
                              style: 'currency',
                              currency: props.webStoreVo.currency,
                              minimumFractionDigits: parseInt(
                                props.webStoreVo.roundingScale
                              ),
                              maximumFractionDigits: parseInt(
                                props.webStoreVo.roundingScale
                              ),
                            }).format(-orderPromotion.discount)}
                      </td>
                    </tr>
                  );
                });
                return result;
              })
            : ''}
          <tr style={{ border: '0px solid transparent' }}>
            <td>
              <div
                className="text-center"
                style={{
                  border: '1px solid #000',
                  color: '#000',
                  width: '25px',
                  fontSize: '16px',
                }}
              >
                {getCount()}
              </div>
            </td>
            <td style={{ width: '25%' }}>
              <FormattedMessage id="orderdetail.count" />
            </td>
            <td className="text-end fw-bold" style={{}}>
              <FormattedMessage id="orderdetail.subtotal" />
            </td>
            <td className="text-end fw-bold">
              {new Intl.NumberFormat(props.locale, {
                style: 'currency',
                currency: props.webStoreVo.currency,
                minimumFractionDigits: parseInt(props.webStoreVo.roundingScale),
                maximumFractionDigits: parseInt(props.webStoreVo.roundingScale),
              }).format(getSubtotal())}
            </td>
          </tr>
          {getShareMealFee() > 0 ? (
            <tr style={{ border: '0px solid transparent' }}>
              <td></td>
              <td></td>
              <td className="text-end" style={{ color: '#606060' }}>
                <FormattedMessage id="orderdetail.shareMeal" />
              </td>
              <td className="text-end">
                {new Intl.NumberFormat(props.locale, {
                  style: 'currency',
                  currency: props.webStoreVo.currency,
                  minimumFractionDigits: parseInt(
                    props.webStoreVo.roundingScale
                  ),
                  maximumFractionDigits: parseInt(
                    props.webStoreVo.roundingScale
                  ),
                }).format(getShareMealFee())}
              </td>
            </tr>
          ) : (
            ''
          )}
          {getFee() > 0 ? (
            <tr style={{ border: '0px solid transparent' }}>
              <td></td>
              <td></td>
              <td className="text-end" style={{ color: '#606060' }}>
                <FormattedMessage id="orderdetail.fee" />
              </td>
              <td className="text-end">
                {new Intl.NumberFormat(props.locale, {
                  style: 'currency',
                  currency: props.webStoreVo.currency,
                  minimumFractionDigits: parseInt(
                    props.webStoreVo.roundingScale
                  ),
                  maximumFractionDigits: parseInt(
                    props.webStoreVo.roundingScale
                  ),
                }).format(getFee())}
              </td>
            </tr>
          ) : (
            ''
          )}
          {getDiscount() > 0 ? (
            <tr style={{ border: '0px solid transparent' }}>
              <td></td>
              <td></td>
              <td className="text-end" style={{ color: '#606060' }}>
                <FormattedMessage id="orderdetail.discount" />
              </td>
              <td className="text-end" style={{ color: '#64D0C3' }}>
                {new Intl.NumberFormat(props.locale, {
                  style: 'currency',
                  currency: props.webStoreVo.currency,
                  minimumFractionDigits: parseInt(
                    props.webStoreVo.roundingScale
                  ),
                  maximumFractionDigits: parseInt(
                    props.webStoreVo.roundingScale
                  ),
                }).format(getDiscount())}
              </td>
            </tr>
          ) : (
            ''
          )}
          {getCouponDiscount() > 0 ? (
            <tr style={{ border: '0px solid transparent' }}>
              <td></td>
              <td></td>
              <td className="text-end" style={{ color: '#606060' }}>
                <FormattedMessage id="orderdetail.coupon" />
              </td>
              <td className="text-end" style={{ color: '#64D0C3' }}>
                {new Intl.NumberFormat(props.locale, {
                  style: 'currency',
                  currency: props.webStoreVo.currency,
                  minimumFractionDigits: parseInt(
                    props.webStoreVo.roundingScale
                  ),
                  maximumFractionDigits: parseInt(
                    props.webStoreVo.roundingScale
                  ),
                }).format(getCouponDiscount())}
              </td>
            </tr>
          ) : (
            ''
          )}
          <tr style={{ border: '0px solid transparent' }}>
            <td colSpan={4}>
              <div
                className="d-flex justify-content-between fw-bold"
                style={{
                  border: '2px solid #64D0C3',
                  borderRadius: '10px',
                  height: '60px',
                  color: '#64D0C3',
                }}
              >
                <div className="align-self-center ms-3">
                  <FormattedMessage id="orderdetail.total" />
                </div>
                <div className="align-self-center me-3">
                  {new Intl.NumberFormat(props.locale, {
                    style: 'currency',
                    currency: props.webStoreVo.currency,
                    minimumFractionDigits: parseInt(
                      props.webStoreVo.roundingScale
                    ),
                    maximumFractionDigits: parseInt(
                      props.webStoreVo.roundingScale
                    ),
                  }).format(getTotal())}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default OrderDetailContent;
