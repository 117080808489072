import FilterType from '@/constants/FilterType';
import ServeType from '@/constants/ServeType';

// check orderSource is ALL(DEFAULT) or WEB
const checkOrderSource = (itemOrderSource): boolean => {
  if (itemOrderSource === 'WEB' || itemOrderSource === 'DEFAULT') {
    return true;
  }
  return false;
};

// check attribute ID are same as subattribute ID
const checkAttributeIDFormat = (subItemID, id, filterType): boolean => {
  if (filterType === FilterType.RECOMMENDATIONS) return true;

  if (subItemID !== 0 && subItemID && id !== 0 && id) {
    if (subItemID === id) {
      return true;
    }
    return false;
  }
  return false;
};

// check this displayOverride is legal
const checkDisplayOverrides = (
  displayOverrides,
  id,
  orderServeType,
  filterType
): boolean => {
  let showFlag = false;
  if (displayOverrides?.length !== 0) {
    for (let i = 0; i < displayOverrides.length; i += 1) {
      if (
        checkAttributeIDFormat(
          displayOverrides[i].subItemAttributeId,
          id,
          filterType
        )
      ) {
        if (checkOrderSource(displayOverrides[i].orderSource)) {
          if (
            displayOverrides[i].orderServeType === ServeType.DEFAULT ||
            displayOverrides[i].orderServeType === orderServeType
          ) {
            showFlag = true;
            break;
          }
        }
      }
    }

    return showFlag;
  }
  return true;
};

const showDisplayOverrides = (
  item,
  id,
  orderServeType,
  filterType
): boolean => {
  let showFlag = true;
  const displayOverrides = JSON.parse(JSON.stringify(item.displayOverrides));
  showFlag = checkDisplayOverrides(
    displayOverrides,
    id,
    orderServeType,
    filterType
  );
  if (!showFlag) return showFlag;
  const subDisplayOverrides = JSON.parse(
    JSON.stringify(item.subDisplayOverrides)
  );
  showFlag = checkDisplayOverrides(
    subDisplayOverrides,
    id,
    orderServeType,
    filterType
  );
  return showFlag;
};

// filter item attribute with filterDisplayOverrides
const Attribute = (items, id, orderServeType): any[] => {
  const filterItems: any = [];
  for (let i = 0; i < items.length; i += 1) {
    if (
      showDisplayOverrides(items[i], id, orderServeType, FilterType.ATTRIBUTES)
    ) {
      filterItems.push(items[i]);
    }
  }
  return filterItems;
};

const Recommendations = (items, id, orderServeType): any[] => {
  const filterItems: any = [];
  for (let i = 0; i < items.length; i += 1) {
    if (
      showDisplayOverrides(
        items[i],
        id,
        orderServeType,
        FilterType.RECOMMENDATIONS
      )
    ) {
      filterItems.push(items[i]);
    }
  }
  return filterItems;
};

const DisplayOverridesFilter = { Recommendations, Attribute };

export default DisplayOverridesFilter;
