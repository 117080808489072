export const enum UberDirectActionType {
  SET_UBER_DIRECT_API_ERROR = 'SET_UBER_DIRECT_API_ERROR',
  SET_UBER_DIRECT_API = 'SET_UBER_DIRECT_API',
  SET_DELIVERY_PHONE_ERROR = 'SET_DELIVERY_PHONE_ERROR',
  SET_DELIVERY_NAME_ERROR = 'SET_DELIVERY_NAME_ERROR',
  SET_SELECTED_AREA = 'SET_SELECTED_AREA',
  SET_SELECTED_CITY = 'SET_SELECTED_CITY',
  SET_SELECTED_STREET = 'SET_SELECTED_STREET',
  SET_STRUCTURED_ADDRESS = 'SET_STRUCTURED_ADDRESS',
  SET_DELIVERABLE_ACTION = 'SET_DELIVERABLE_ACTION',
  SET_WA10_MEMBER = 'SET_WA10_MEMBER',
  SET_DELIVERY_TYPE = 'SET_DELIVERY_TYPE',
}
