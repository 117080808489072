import { MessageTypes } from '@actions/message/messageTypes';

const initialState: any = {
  projectName: null,
  serveType: 'DEFAULT',
  customizedServeType: null,
  errorConfirm: () => {},
};

const messageReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case MessageTypes.SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: payload,
      };
    case MessageTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: payload,
      };
    case MessageTypes.SET_ERROR_CONFIRM:
      return {
        ...state,
        errorConfirm: payload,
      };

    default:
      return state;
  }
};

export default messageReducer;
