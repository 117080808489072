import PropTypes from 'prop-types';
import { Style } from '@/components/style';

const { backgroundColor, gray2Color, alertColor } = new Style().getUiConfig();
function ReminderLayout({ children, setBackGroundColor }) {
  return (
    <div
      className="mt-3 ms-3 me-3 pt-3 pb-3"
      style={{
        background:
          setBackGroundColor === alertColor
            ? 'rgba(255,138,128,0.2)'
            : setBackGroundColor,
        borderRadius: '10px',
        boxShadow: `0 0 0 0.5px ${
          setBackGroundColor === alertColor ? alertColor : gray2Color
        }`,
      }}
    >
      {children}
    </div>
  );
}

ReminderLayout.defaultProps = {
  setBackGroundColor: backgroundColor,
};

ReminderLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  setBackGroundColor: PropTypes.string,
};

export default ReminderLayout;
