import hk from './flag/cn_HK.png';
import us from './flag/en_US.png';
import tw from './flag/zh_TW.png';
import jp from './flag/Japan.png';
import ko from './flag/korea.png';
import vi from './flag/vietnam.png';
import at from './flag/Austria.png';
import rs from './flag/Serbia.png';
import de from './flag/german.png';
import cn from './flag/china.png';

import applepay from './img/applepay.png';
import bbpos from './img/bbpos.png';
import backButton from './img/btn_back.png';
import goBackButton from './img/btn_goBack.png';
import closeButton from './img/btn_close.png';
import goCloseButton from './img/btn_goClose.png';
import comment from './img/comment.png';
import copyUrlSuccess from './img/copyurl_success.svg';
import coupon from './img/coupon.png';
import earth from './img/earth.svg';
import edit from './img/edit.png';
import fb from './img/fb.png';
import googlepay from './img/googlepay.png';
import jkopay from './img/jkopay.png';
import line from './img/line.png';
import linepay from './img/linepay.png';
import maIcon from './img/ma_icon.png';
import member from './img/member.png';
import memberPoint from './img/member_point.png';
import menu from './img/menu.svg';
import minusLight from './img/minus_light.png';
import minusSecondary from './img/minus_secondary.png';
import minusTransparent from './img/minus_transparent.png';
import pay from './img/pay.png';
import payArrow from './img/pay_arrow.png';
import atTheDoorGreen from './img/at_the_door_green.png';
import atTheDoorGray from './img/at_the_door_gray.png';
import putTheDoorGreen from './img/put_the_door_green.png';
import putTheDoorGray from './img/put_the_door_gray.png';
import circleCheck from './img/circle_check.png';
import OrderArrive from './img/order_arrive.png';
import OrderDelivering from './img/order_delivering.png';
import OrderProcessing from './img/order_processing.png';
import DetailBorder from './img/detail_border.png';
import orderServetype from './img/order_serveType.png';
import diningPerson from './img/dining_person.png';
import timeClock from './img/time_clock.png';
import address from './img/address.png';

import paysuccess from './img/payment_success.png';
import paypal from './img/paypal.png';
import phone from './img/phone.png';
import plusLight from './img/plus_light.png';
import plusSecondary from './img/plus_secondary.png';
import plusTransparent from './img/plus_transparent.png';
import qrInput from './img/qr_input.png';
import qrScan from './img/qr_scan.png';
import qrSelect from './img/qr_select.png';
import silverware from './img/silverware.png';
import store from './img/store.png';
import taiwanpay from './img/taiwanpay.svg';
import trashcan from './img/trashcan.png';
import vegan from './img/vegan.png';
import visa from './img/visa.png';
import promotionTag from './img/promotion_tag.png';

export const img = {
  flag: {
    hk,
    tw,
    us,
    vi,
    ko,
    jp,
    rs,
    at,
    de,
    cn,
  },
  icon: {
    orderServetype,
    diningPerson,
    timeClock,
    address,
    minusSecondary,
    plusSecondary,
    maIcon,
    fb,
    line,
    phone,
    backButton,
    goBackButton,
    closeButton,
    goCloseButton,
    minusLight,
    plusLight,
    payArrow,
    qrSelect,
    qrInput,
    qrScan,
    silverware,
    edit,
    minusTransparent,
    plusTransparent,
    trashcan,
    store,
    pay,
    member,
    memberPoint,
    coupon,
    vegan,
    comment,
    copyUrlSuccess,
    menu,
    earth,
    APPLEPAY: applepay,
    GOOGLEPAY: googlepay,
    LINEPAY: linepay,
    JKOPAY: jkopay,
    PAYPAL: paypal,
    BBPOS: bbpos,
    CTBC: visa,
    TAPPAY_DIRECT: visa,
    TAIWANPAY: taiwanpay,
    paysuccess,
    atTheDoorGreen,
    atTheDoorGray,
    putTheDoorGreen,
    putTheDoorGray,
    circleCheck,
    OrderArrive,
    OrderDelivering,
    OrderProcessing,
    DetailBorder,
    promotionTag,
  },
};
