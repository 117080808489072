import { Style } from '@/components/style';
import PropTypes from 'prop-types';

const { primaryColor, whiteColor } = new Style().getUiConfig();

function OutlineButton({ children, onClick }) {
  return (
    <div className="d-flex justify-content-center">
      <div
        className="ms-1 me-1 d-flex justify-content-center"
        role="presentation"
        style={{
          height: '50px',
          width: '90%',
          color: primaryColor,
          background: whiteColor,
          borderColor: primaryColor,
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '10px',
          boxShadow: '2px 2px 4px 2px rgb(0 0 0 / 10%)',
          fontSize: '18px',
        }}
        onClick={onClick}
      >
        <div className="fw-bold align-self-center">{children}</div>
      </div>
    </div>
  );
}

OutlineButton.defaultProps = {
  onClick: () => {},
};

OutlineButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
};

export default OutlineButton;
