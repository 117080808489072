import { AreaCodeActionType } from '../../actions/areaCode/areaCodeTypes';

const initialState: any = {
  selectAreaCode: null,
  areaCodes: null,
};
// TODO : save webVo state only ?
const areaCodesReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case AreaCodeActionType.SET_SELECT_CODE:
      return {
        ...state,
        selectAreaCode: payload,
      };
    case AreaCodeActionType.SET_AREA_CODES:
      return {
        ...state,
        areaCodes: payload,
      };

    default:
      return state;
  }
};

export default areaCodesReducer;
