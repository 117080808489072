import PropTypes from 'prop-types';
import Location from '@/assets/img/location.svg';
import InformationLayout from '@/components/common/storeDetail/informationLayout';
import SmallButton from '@/ui/smallButton';
import { FormattedMessage, useIntl } from 'react-intl';

function AddressInformaiton({ address }) {
  const handleOpneMapOnClick = () => {
    document.location.href = `https://maps.google.com/?q=${address}`;
  };
  const addressTitle = useIntl().formatMessage({ id: 'storeDetail.address' });
  return (
    address && (
      <InformationLayout title={addressTitle} titleIcon={Location}>
        <div
          style={{
            maxWidth: '70%',
            wordBreak: 'break-all',
            wordWrap: 'break-word',
            fontSize: '12px',
          }}
        >
          {address}
        </div>
        <SmallButton
          onClick={handleOpneMapOnClick}
          fontSize="12px"
          uniqeClass="fw-bolder"
        >
          <FormattedMessage id="storeDetail.map" />
        </SmallButton>
      </InformationLayout>
    )
  );
}

AddressInformaiton.propTypes = {
  address: PropTypes.string,
};

export default AddressInformaiton;
