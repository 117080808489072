// import { RedeemActionType } from '../../actions/member/memberTypes';
import { RedeemActionType } from '../../actions/redeem/redeemTypes';

const initialState: any = {
  redeemItems: null,
  currentSelectPoints: 0,
  isRedeemModalOpen: false,
  errorMsg: '',
  redeemStatus: 'REDEEM',
  otpCode: null,
  redeemBodyState: 'PHONE_INPUT',
};

const redeemReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload, currentPoints } = action;
  switch (type) {
    case RedeemActionType.SET_MEMBER_REDEEMITEMS:
      return {
        ...state,
        redeemItems: payload,
        currentSelectPoints: currentPoints,
      };

    case RedeemActionType.SET_CURRENT_SELECTED_POINTS:
      return {
        ...state,
        currentSelectPoints: payload,
      };

    case RedeemActionType.SET_REDEEM_MODAL_STATUS:
      return {
        ...state,
        isRedeemModalOpen: payload,
      };
    case RedeemActionType.SET_REDEEM_ERROR:
      return {
        ...state,
        errorMsg: payload,
      };

    case RedeemActionType.SET_REDEEM_STATUS:
      return {
        ...state,
        redeemStatus: payload,
      };
    case RedeemActionType.SET_OTP_CODE:
      return {
        ...state,
        otpCode: payload,
      };
    case RedeemActionType.SET_REDEEM_BODY_STATE:
      return {
        ...state,
        redeemBodyState: payload,
      };

    default:
      return state;
  }
};

export default redeemReducer;
