import { OrderActionType } from '../../actions/order/orderTypes';

const initialState: any = {};

const orderReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case OrderActionType.REDIRECT_ORDER:
      return {
        ...state,
        webStoreVoResponse: {
          code: payload.code,
          data: payload.data,
        },
      };
    case OrderActionType.UPDATE_STORE:
      return {
        ...state,
        webStoreVoResponse: {
          code: payload.code,
          data: payload.data,
        },
      };
    case OrderActionType.STORES:
      return {
        ...state,
        webStoreVoResponse: {
          code: payload.code,
          data: payload.data,
        },
      };
    case OrderActionType.MENU:
      return {
        ...state,
        webStoreVoResponse: {
          code: payload.code,
          data: payload.data,
        },
      };
    case OrderActionType.RULE:
      return {
        ...state,
        ruleOrderVo: payload,
      };
    case OrderActionType.REDEEM:
      return {
        ...state,
        pointRedeemVos: payload,
      };
    case OrderActionType.WEBCDSVOS:
      const webCdsVos = payload.map((webCdsVo: any) => {
        let row = {
          storeName: webCdsVo.storeDisplayname,
          orderNo: webCdsVo.orderVo.prefix + webCdsVo.orderVo.customId,
          queue: webCdsVo.customerInLine,
          status: webCdsVo.orderVo.status,
        };
        return row;
      });
      return {
        ...state,
        webCdsVos: webCdsVos,
      };
    default:
      return state;
  }
};

export default orderReducer;
