import { setErrorMessage } from '@actions/message/messageAction';
import { ResponseCode } from '@services/responseCode';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IntlShape } from 'react-intl/src/types';
import store from '@/store';
import { isEmpty, uniqueId } from 'lodash';
import { CommonActionType } from '@/actions/common/commonTypes';
import ErrorMsgUtils from '@/utils/errorMsgUtils';

const axiosService: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE}`,
  headers: {
    'Content-type': 'application/json',
  },
});

let intlShape;
let interceptors;
const requestQueue: string[] = [];

const startRequest = () => {
  store.dispatch({ type: CommonActionType.LOADING });
  requestQueue.push(uniqueId());
};

const completeRequest = () => {
  requestQueue.pop();
  if (isEmpty(requestQueue))
    store.dispatch({ type: CommonActionType.COMPLETE });
};

axiosService.interceptors.request.use((config) => {
  startRequest();
  return config;
});

const initInterceptors = (onFulfilled: any = null, onRejected: any = null) => {
  interceptors = axiosService.interceptors.response.use(
    (res: any) => {
      axiosService.interceptors.response.eject(interceptors);
      if (onFulfilled instanceof Function) {
        return onFulfilled(res);
      }

      if (
        !res?.data ||
        !(
          res.data?.code === ResponseCode.NO_ERROR ||
          res.data?.code === ResponseCode.API_NO_ERROR
        )
      ) {
        if (res?.data?.code) {
          const errorData: any = ErrorMsgUtils.translateErrorCode(
            String(res.data.code),
            intlShape
          );

          const key = `error.${res.data.code}`;
          const errorMessage = intlShape?.formatMessage({
            id: key,
          });

          if (errorMessage !== key) {
            setErrorMessage(errorData.msg, {
              title: errorData.title,
            });

            return Promise.reject(res);
          }
        }
      }

      return res;
    },
    (error: any) => {
      axiosService.interceptors.response.eject(interceptors);
      if (onRejected instanceof Function) {
        return onRejected(error);
      }
      if (onRejected instanceof String) {
        return Promise.reject(onRejected);
      }

      if (error?.data?.code) {
        const errorData: any = ErrorMsgUtils.translateErrorCode(
          String(error.data.code),
          intlShape
        );
        setErrorMessage(errorData.msg, {
          title: errorData.title,
        });

        const key = `error.${error.data.code}`;
        const errorMessage = intlShape?.formatMessage({
          id: key,
        });

        if (errorMessage !== key) {
          setErrorMessage(errorData.msg, {
            title: errorData.title,
          });
        }
      } else {
        const errorMessage = intlShape?.formatMessage({
          id: 'error.network',
        });
        setErrorMessage(errorMessage);
      }

      return Promise.reject(error);
    }
  );
};

export function initAxiosService(intl: IntlShape) {
  intlShape = intl;
}

const get = async <D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
  onFulfilled?: any,
  onRejected?: any
): Promise<AxiosResponse> => {
  initInterceptors(onFulfilled, onRejected);
  return axiosService
    .get(url, {
      ...config,
      params: data,
    })
    .finally(() => {
      completeRequest();
    });
};

const post = async <D = unknown>(
  url: string,
  data?: D,
  params?: D,
  config?: AxiosRequestConfig<D>,
  onFulfilled?: any,
  onRejected?: any
): Promise<AxiosResponse> => {
  initInterceptors(onFulfilled, onRejected);
  return axiosService.post(url, data, { ...config, params }).finally(() => {
    completeRequest();
  });
};

const put = async <D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
  onFulfilled?: any,
  onRejected?: any
): Promise<AxiosResponse> => {
  initInterceptors(onFulfilled, onRejected);
  return axiosService.put(url, data, config).finally(() => {
    completeRequest();
  });
};

const deleteFunction = async <D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
  onFulfilled?: any,
  onRejected?: any
): Promise<AxiosResponse> => {
  initInterceptors(onFulfilled, onRejected);
  return axiosService
    .delete(url, {
      ...config,
      params: data,
    })
    .finally(() => {
      completeRequest();
    });
};

export default {
  get,
  post,
  put,
  delete: deleteFunction,
};
