import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Style } from '@/components/style';
import { ReactComponent as Close } from '@/assets/img/close.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import BusinessHours from '@/components/common/storeDetail/businessHours';
import PhoneInformation from '@/components/common/storeDetail/phoneInformation';
import AddressInformaiton from '@/components/common/storeDetail/addressInformaiton';
import SocialMediaRow from '@/components/common/storeDetail/socialMediaRow';
import StoreUtils from '@/utils/storeInfoUtils';
import '@/components/common/storeDetail/storeDetailModal.scss';

const { secondaryColor, gray3Color } = new Style().getUiConfig();

function StoreDetailModal({ show, handleClose, webStoreVo }) {
  const intl = useIntl();
  const { phone, address, displayName } = webStoreVo;
  const isStoreNameLengthOver = () => {
    let isOver = false;

    const commonStoreName = intl.formatMessage({
      id: 'common.store.name',
    });
    const storeNameLength = webStoreVo?.name ? webStoreVo.name.length : 0;
    const stringLength = commonStoreName.length + storeNameLength;
    if (stringLength >= 15) isOver = true;
    return isOver;
  };
  return (
    <Modal
      className="w-100 d-flex justify-content-center"
      dialogClassName="modal-store-info"
      show={show}
      onHide={handleClose}
      centered
      scrollable
    >
      <Modal.Title className="d-flex flex-column gap-1 ps-3 pe-3 pt-3 pb-1 justify-content-between">
        <div className="d-flex justify-content-between align-items-center">
          <div className="h5 mb-0">{displayName}</div>
          <Close onClick={handleClose} />
        </div>
        <div>
          {/* 營業人名稱 統一編號 左右排 版型 */}
          {!isStoreNameLengthOver() && (
            <div className="d-flex" style={{ color: gray3Color }}>
              {webStoreVo?.name && (
                <div className="me-1" style={{ fontSize: '12px' }}>
                  <FormattedMessage id="common.store.name" />
                  {webStoreVo.name}
                </div>
              )}
              {StoreUtils.isStoreIdentifierValid(webStoreVo) && (
                <div style={{ fontSize: '12px' }}>
                  <FormattedMessage id="common.store.identifier" />
                  {webStoreVo.identifier}
                </div>
              )}
            </div>
          )}
          {/* 營業人名稱 統一編號 上下排 版型 */}
          {isStoreNameLengthOver() && (
            <div style={{ color: gray3Color }}>
              {webStoreVo?.name && (
                <div className="me-1" style={{ fontSize: '12px' }}>
                  <FormattedMessage id="common.store.name" />
                  {webStoreVo.name}
                </div>
              )}
              {StoreUtils.isStoreIdentifierValid(webStoreVo) && (
                <div style={{ fontSize: '12px' }}>
                  <FormattedMessage id="common.store.identifier" />
                  {webStoreVo.identifier}
                </div>
              )}
            </div>
          )}
        </div>

        <SocialMediaRow webStoreVo={webStoreVo} />
      </Modal.Title>
      <Modal.Body className="pt-0 d-flex flex-column gap-2">
        <BusinessHours webStoreVo={webStoreVo} />
        <PhoneInformation phone={phone} />
        <AddressInformaiton address={address} />
      </Modal.Body>
    </Modal>
  );
}

StoreDetailModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  webStoreVo: PropTypes.shape({
    address: PropTypes.string,
    displayName: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

export default StoreDetailModal;
