import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import QRCode from 'qrcode.react';
import { img } from '../../../assets/';
import { RootStateOrAny, useSelector } from 'react-redux';

type props = {
  locale: string;
  qrcodeValue: string;
  title: string;
  customId: string;
  desc: string;
  closeQrModal?: any;
  isTest?: boolean;
  total: any;
  itemCount: any;
  childOrderUuid: string;
};
type params = { orderUuid: string };

function Qrcode(props: props) {
  let qrString = '';
  const projectName = useSelector<RootStateOrAny, any>(
    (state) => state.modeReducer.projectName
  );
  const kgTechQrString =
    props.childOrderUuid + ';' + props.total + ';' + props.itemCount;

  if (projectName === 'PHISON') {
    qrString = kgTechQrString;
  } else {
    qrString = props.qrcodeValue;
  }
  return (
    <div
      className="modal fade"
      id="qrcodeModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
      aria-labelledby="qrcodeModalLabel"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="sticky-top">
            <div className="position-absolute" style={{ right: 15, top: 15 }}>
              <img
                style={{ width: '40px' }}
                src={img.icon.closeButton}
                alt=""
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={props.closeQrModal}
              ></img>
            </div>
          </div>
          <div className="modal-header">
            <h5 className="modal-title fw-bold" style={{ fontSize: '20px' }}>
              {props.title}
            </h5>
          </div>
          <div
            className="modal-body"
            style={{ padding: 'unset' }}
            id="qrcodeModalBody"
          >
            <div className="mt-3 text-center">
              <QRCode
                style={{
                  borderRadius: '10px',
                  width: '80%',
                  height: '80%',
                }}
                value={qrString}
                size={300}
                renderAs="canvas"
                bgColor="#FFFFFF"
                includeMargin
              />
            </div>
            <div className="mt-3 ms-3 me-3">
              <div
                className="d-flex justify-content-center"
                style={{ fontSize: '20px' }}
              >
                <span className="fw-bold align-self-center">
                  <FormattedMessage id={'detail.customid'} />
                </span>
                &nbsp;
                <span
                  className="align-self-center fw-bold"
                  style={{ color: '#5292D9' }}
                >
                  {props.customId}
                </span>
              </div>
            </div>
            <div className="mt-3 ms-3 me-3 pb-3">
              <div
                className="d-flex justify-content-center"
                style={{ fontSize: '14px', color: '#757575' }}
              >
                <span className="fw-bold align-self-center">{props.desc}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Qrcode;
