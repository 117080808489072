import { MemberInfoTypes } from '@actions/memberInfo/memberInfoTypes';

const initialState: any = {
  enabled: false,
  phone: '',
  areaCode: {},
  country: '',
  isMember: false,
  isLogin: false,
  memberToken: null,
  captchaToken: null,
};

const memberReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case MemberInfoTypes.SET_ENABLED:
      return {
        ...state,
        enabled: payload,
      };
    case MemberInfoTypes.SET_IS_LOGIN:
      return {
        ...state,
        isLogin: payload,
      };
    case MemberInfoTypes.SET_MEMBER_TOKEN:
      return {
        ...state,
        memberToken: payload,
      };
    case MemberInfoTypes.SET_CAPTCHA_TOKEN:
      return {
        ...state,
        captchaToken: payload,
      };
    case MemberInfoTypes.SET_CURRENT_PHONE:
      return {
        ...state,
        phone: payload.phone,
        areaCode: payload.areaCode,
        country: payload.areaCode?.country,
      };
    case MemberInfoTypes.SET_IS_MEMBER:
      return {
        ...state,
        isMember: payload,
      };
    case MemberInfoTypes.INIT_MEMBER_INFO:
      return {
        ...state,
        isLogin: payload.isLogin,
        memberToken: payload.token,
        isMember: payload.isMember,
      };
    case MemberInfoTypes.REFRESH_MEMBER_INFO:
      return {
        ...state,
        phone: '',
        areaCode: {},
        isMember: false,
        isLogin: false,
        memberToken: null,
        captchaToken: null,
      };
    default:
      return state;
  }
};

export default memberReducer;
