import { MenuSingleStoreActionType } from '../../actions/menuSingleStore/menuSingleStoreTypes';

const initialState: any = {
  MenuVo: [],
  StoreVo: {},
  WebMenuItemVos: [],
  MenuItem: null,
  MenuSecondAttributeItem: null,
  menuScrolling: false,
  MenuSelectedItemQuantity: 1,
  MenuSelectedAttributeModal: null,
  MenuSelectedSecondAttributeModal: null,
  ApplyRuleResponse: null,
};

const menuSingleStoreReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case MenuSingleStoreActionType.SET_MENU:
      return {
        ...state,
        MenuVo: payload,
      };
    case MenuSingleStoreActionType.SET_STORE:
      return {
        ...state,
        StoreVo: payload,
      };
    case MenuSingleStoreActionType.SET_WEB_MENU_ITEMVOS:
      return {
        ...state,
        WebMenuItemVos: payload,
      };
    case MenuSingleStoreActionType.SET_MENU_ITEM:
      return {
        ...state,
        MenuItem: payload,
      };
    case MenuSingleStoreActionType.SET_MENU_SECOND_ATTRIBUTE_ITEM:
      return {
        ...state,
        MenuSecondAttributeItem: payload,
      };
    case MenuSingleStoreActionType.SET_MENU_SCROLLING:
      return {
        ...state,
        menuScrolling: payload,
      };
    case MenuSingleStoreActionType.SET_MENU_SELECTED_ITEM_QUANTITY:
      return {
        ...state,
        MenuSelectedItemQuantity: payload,
      };
    case MenuSingleStoreActionType.SET_MENU_SELECTED_ATTRIBUTE_MODAL:
      return {
        ...state,
        MenuSelectedAttributeModal: payload,
      };
    case MenuSingleStoreActionType.SET_MENU_SELECTED_SECOND_ATTRIBUTE_MODAL:
      return {
        ...state,
        MenuSelectedSecondAttributeModal: payload,
      };
    case MenuSingleStoreActionType.SET_MENU_APPLY_RULE_RESPONSE:
      return {
        ...state,
        ApplyRuleResponse: payload,
      };
    default:
      return state;
  }
};

export default menuSingleStoreReducer;
