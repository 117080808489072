const enum UberDirectStatusType {
  PROCESSING = 'PROCESSING',
  DELIVERING = 'DELIVERING',
  ARRIVE = 'ARRIVE',
  pending = 'pending',
  pickup = 'pickup',
  pickup_complete = 'pickup_complete',
  dropoff = 'dropoff',
  delivered = 'delivered',
  canceled = 'canceled',
  returned = 'returned',
}

export default UberDirectStatusType;
