import { LiffActionType } from '@actions/liffLongin/liffType';

const initialState: any = {
  projectName: null,
  serveType: 'DEFAULT',
  customizedServeType: null,
};
const lineReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload = {} } = action;
  switch (type) {
    case LiffActionType.SET_LIFF:
      if (!payload) return state;
      return {
        ...state,
        lineProfile: {
          userId: payload,
        },
      };
    default:
      return state;
  }
};

export default lineReducer;
