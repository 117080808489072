import PropTypes from 'prop-types';
import { Style } from '@/components/style';
import { useEffect, useState } from 'react';
// naming from ui style guide : primary button ;

const { secondaryColor, gray2Color, gray3Color, whiteColor } =
  new Style().getUiConfig();

const enableStyle = {
  height: '50px',
  background: secondaryColor,
  borderRadius: '10px',
  color: '#fff',
  width: '100%',
};

const disableStyle = {
  height: '50px',
  background: gray2Color,
  borderRadius: '10px',
  color: gray3Color,
  borderColor: whiteColor,
  width: '100%',
};

let timeoutID;

function PrimaryButton({ onClick, children, disabled, type, id }) {
  const style = disabled ? disableStyle : enableStyle;
  const typeWidth = type === 'dafault' ? '96%' : '100%';
  const [clicked, setClicked] = useState(false); // 防 連點兩下
  const clickEvent = () => {
    if (clicked) {
      return;
    }
    setClicked(true);
    onClick();
    timeoutID = setTimeout(() => {
      setClicked(false);
    }, 1000) as unknown as number;
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutID);
    };
  }, []);
  return (
    <div className="align-self-center d-flex" style={{ width: typeWidth }}>
      <button
        id={id}
        type="button"
        disabled={clicked || disabled ? true : false}
        className="btn align-self-center justify-content-center d-flex fw-bold"
        style={style}
        onClick={clickEvent}
      >
        <div className="d-flex w-100 align-self-center justify-content-center">
          {children}
        </div>
      </button>
    </div>
  );
}

PrimaryButton.defaultProps = {
  disabled: false,
  type: 'dafault',
  id: '',
};

PrimaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string,
};

export default PrimaryButton;
