import 'intl-tel-input/build/css/intlTelInput.css';
import 'moment/locale/it.js';
import { img } from '../../../assets';

type props = {
  storeDisplay: any;
};

function Privacy(props: props) {
  return (
    <div
      className="modal fade"
      id="privacyModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
      aria-labelledby="privacyModalLabel"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div
            className="modal-body"
            style={{ padding: 'unset' }}
            id="privacyModalBody"
          >
            <div className="sticky-top">
              <div className="position-absolute" style={{ right: 5, top: 5 }}>
                <img
                  style={{ width: '40px' }}
                  src={img.icon.closeButton}
                  alt=""
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></img>
              </div>
            </div>
            <div className="mt-5">
              <p>
                <span>{props.storeDisplay}</span>
                （以下簡稱本公司）顧客智慧營銷系統暨 APP
                應用服務（以下簡稱本服務）係由<span>{props.storeDisplay}</span>
                提供。您在使用本服務之前，請先詳細閱讀以下條款，當您點選「同意」並開始使用本服務後，即視為您已有效且不可撤銷地同意本條款，並同意遵守本服務條款之約定。
              </p>
              <p>
                依照個人資料保護法第 8
                條規定，本服務之隱私權保護政策（以下簡稱「本政策」）將幫助您了解，我們如何蒐集、處理、利用及保護個人資料，請詳閱以下內容：
              </p>

              <h1>一、個人資料的蒐集目的</h1>

              <p>
                當您透過本服務註冊會員、登錄、參加本服務所提供之工具、網路活動、問券調查或贈獎活動，或進行滿意度調查、獲取意見回饋、回覆您的提問或資訊查詢、提供及改善本服務、參與使用者經驗調查及分析等客戶服務時，基於優化服務體驗與市場行銷的特定目的，本公司將蒐集、處理及利用您的個人資料。
              </p>
              <small>
                上述特定目的，法定編號包含：〇四〇 行銷、〇六七
                信用卡、現金卡、轉帳卡或電子票證業務、○八一
                個人資料之合法交易業務、〇九〇 消費者、客戶管理與服務、〇九一
                消費者保護、〇九八 商業與技術資訊、一三五 資（通）訊服務、一三六
                資（通）訊與資料庫管理、一五二 廣告或商業行為管理、一五七
                調查、統計與研究分析、一八一
                其他經營合於營業登記項目或組織章程所定之業務、一八二
                其他諮詢與顧問服務
              </small>

              <h1>二、個人資料之取得及類別</h1>

              <p>
                當您註冊成為本服務會員時，本服務會要求您輸入手機號碼、姓名、生日、性別、電子信箱等基本資訊。
              </p>
              <p>當您在使用本服務時，本公司可能會蒐集到以下資料：</p>
              <ol>
                <li>
                  得以辨識您個人之資料，例如：姓名、住址、行動電話、電子簽章、憑證序號、提供網路身分認證或申辦查詢服務之記錄及其他任何可辨識資料本人者。
                </li>
                <li>
                  其他使用者因使用本服務所產生之技術數據，例如：IP
                  地址、登錄時間、位置資料、財務交易資訊以及其他得以辨識個人休閒活動及興趣、生活格調等之資料。
                </li>
              </ol>
              <p>
                <small>
                  上述資料之類別，法定編號包含：C〇〇一 辨識個人者、C〇〇二
                  辨識財務者、C〇〇三 政府資料中之辨識者、C〇一一
                  個人描述、C〇二一 家庭情形、C〇三五 休閒生活及興趣、C〇三六
                  生活格調、C〇五一 學校紀錄、C〇八一
                  收入、所得、資產與投資、C〇九三 財務交易、C一〇二 約定或契約
                </small>
              </p>

              <h1>三、個人資料之利用期間、地區、對象及方式</h1>

              <p>期間：會員要求停止使用個人資料或本服務經終止之日為止。</p>
              <p>
                地區：會員之個人資料將用於本服務所及之地區，包含日後於台灣以外依本公司之商業判斷而有必要使用該等個人資料之地區。
              </p>
              <p>利用對象及方式：</p>
              <ol>
                <li>
                  會員之個人資料將用於本服務之會員與客戶管理的檢索查詢功能，並將作為金流服務、物流服務、行銷宣傳以及為推展本服務之必要範圍內而為的身分辨識。會員個人關於本服務之使用記錄，亦將作為修改、變更、擴張本服務內容與項目的開發基礎。
                </li>
                <li>
                  會員之個人資料將作為各種推播資訊、個人化服務以及本公司市場調查之基礎，包含但不限於透過電子郵件、實體郵件、電話與簡訊等方式提供與本服務或與本服務有關之合作廠商的資訊、分析與統整消費族群特性、調查消費頻率與回流資訊等等應用。
                </li>
                <li>
                  其他於特定目的與使用類別之範圍內，基於推廣本服務之業務需求，而由本公司以電腦化或其他相類之數據方式處理、利用之行為。
                </li>
                <li>
                  <p>
                    當您註冊成為會員時，您亦同意本公司得將會員之個人資料，揭露、分享或提供給與本公司具備契約上合作關係之第三方及其關係企業，進行如下之處理與利用：
                  </p>
                  <ol>
                    <li>
                      作為第三方各種推播資訊之基礎，包含但不限於透過電子郵件、實體郵件、電話與簡訊等方式提供第三方或其關係企業之行銷資訊。
                    </li>
                    <li>
                      作為第三方或其關係企業優化服務內容之市場調查依據，並得與其內部之管理系統結合、匯入、建檔或以一定之方式備份、留存。
                    </li>
                    <li>
                      其他經本公司與該第三方或其關係企業透過契約方式具體明列之其他處理或利用方式。
                    </li>
                  </ol>
                </li>
              </ol>
              <p>
                上述情況，本公司應於透過一定之約定或政策機制，確保該第三方或其關係企業應以不低於本政策所要求之個人資料安全或維護標準，保護會員之隱私。
              </p>

              <h1>四、個人資料的自主權</h1>

              <p>
                依照個人資料保護法第三條規定，您得就其所提供之個人資料行使下列權利：
              </p>
              <ol>
                <li>查詢或請求閱覽。</li>
                <li>請求製給複製本。</li>
                <li>請求補充或更正。</li>
                <li>請求停止蒐集、處理或利用。</li>
                <li>請求刪除。</li>
              </ol>
              <p>
                您如欲行使上述權利，可與本公司之客服人員進行聯繫申請，然於本公司尚未查證您為本人前，您不得申請查詢、複製、請求閱覽或申請變動不屬於本人會員之個人資料及帳號內容。
              </p>
              <p>
                您可隨時依據第一項之權利，要求停止蒐集、處理、利用或刪除屬於您的個人資料，然資料一旦經停止蒐集、處理、利用或刪除者，本公司有權向您終止本服務。
              </p>
              <p>
                於妨害國家重大利益、妨害公務機關執行法定職務、妨害蒐集機關或第三人重大利益時，本公司有權拒絕您主動提出變更、刪除或異動個人資料之請求。
              </p>

              <h1>五、個人資料的維護</h1>
              <p>
                為保護您的隱私，本公司將提供必要之技術及措施保護個人資料。至於會員的個人資料、註冊帳號及密碼等，請您妥善保管，避免外洩。除依政府或法令要求，以及本公司因本政策之揭示，而得將資料分享或提供予第三方使用外，本公司將對個人資料負擔保密義務。
              </p>
              <p>
                針對得與本公司合作，而依本政策取得分享、使用會員個人資料權限之第三方，本公司亦將比照前項之要求，使第三方對會員的個人資料負擔一定之保密與維護義務，然因可歸責於第三方自身之行為，造成會員權益受損者，本公司除應協助會員向第三方主張法律上之權利外，概不負擔任何賠償責任。
              </p>

              <h1>六、其他</h1>
              <p>
                本服務可能包含其他網站或網頁的連結，或使用第三人提供之服務，若您使用本服務之同時，也使用其他附加功能、外掛程式或第三方提供之應用程式（以下簡稱「應用程式」），該等網站、網頁連結或應用程式可能獲取某些您的個人資料。對於此等不屬本公司之網站、連結或應用程式，您應了解其蒐集個人資料之行為，概與本公司無關。
              </p>
              <p>
                您得自由選擇是否提供個人資料予本服務，惟一旦您拒絕提供完成註冊程序所需之必要資料，或所提供之資料不完整，將可能導致本服務無法執行。
              </p>
              <p>
                您同意在註冊成為本服務之會員時，所留存的資料與事實相符，如您所提供之資料有任何偽冒不實，您同意應自負一切民、刑事法律責任。若事後發現您的個人資料遭他人非法使用或有任何異常時，應即時通知本公司。
              </p>
              <p>
                您同意於使用本服務時，所提供及使用之資料皆為合法，並無侵害第三人權利、違反第三方協議或涉及任何違法行為。如因使用本服務而致第三人損害，除本公司故意或重大過失外，本公司並不負擔相關賠償責任。
              </p>
              <p>
                若本服務日後由本公司外之第三人經營承接，或本公司被第三人收購或合併，將依據本政策對個人資料之維護要求，移交您的個人資料，倘若因此適用不同隱私權政策者，本公司將事先向您寄送通知。
              </p>
              <p>
                本公司保留隨時修訂本政策之權利，如有更新則以現有版本為主。如個人資料的處理上有重大變更時，本服務將於產品官網上張貼告示。本政策或變更之告示，皆為本服務會員條款的一部分，並適用使用者條款的相關規定與要求。
              </p>

              <footer>最後更新日期：2020 年 6 月 18 日</footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
