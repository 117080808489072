import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Style } from '@/components/style';

const { primaryColor, alertColor } = new Style().getUiConfig();
function CancelTimeNotice({ time }) {
  return (
    <div className="ms-3 me-3 text-center" style={{ fontSize: '12px' }}>
      <div>
        <span>
          <FormattedMessage id="detail.pay.autocancel1" />
        </span>
        <span className="fw-bolder" style={{ color: alertColor }}>{time}</span>
        <span>
          <FormattedMessage id="detail.pay.autocancel2" />
        </span>
      </div>
    </div>
  );
}

CancelTimeNotice.propTypes = {
  time: PropTypes.string.isRequired,
};

export default CancelTimeNotice;
