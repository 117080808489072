import React from 'react';
import { Page1, Page2, Page3 } from './demo';
import {
  Bbpos,
  Ctbc,
  CurrentOrder,
  CustomWelcome,
  Jkopay,
  Paypal,
  RedirectOrder,
  SubmitOrder,
  SubmitPayment,
  TappayDirect,
  WelcomeRedirect,
} from './redirect';

const Welcome = React.lazy(() => import('./welcome'));
const PickUpWelcome = React.lazy(() => import('./pickUpWelcome'));
const SearchOrder = React.lazy(() => import('./searchOrder'));
const Stores = React.lazy(() => import('./stores'));
const Menu = React.lazy(() => import('./menu'));
const Payment = React.lazy(() => import('./payment'));
const Kiosk = React.lazy(() => import('./kiosk'));
const Detail = React.lazy(() => import('./detail'));
const LineLiffReminderLayout = React.lazy(
  () => import('./menu/lineLiffReminder/lineLiffRemindLayout')
);
const LiffWelcome = React.lazy(() => import('./liffWelcome'));

export {
  Page1,
  Page2,
  Page3,
  Welcome,
  RedirectOrder,
  Stores,
  Menu,
  CurrentOrder,
  CustomWelcome,
  Payment,
  Kiosk,
  SubmitPayment,
  SubmitOrder,
  Ctbc,
  Bbpos,
  Paypal,
  Jkopay,
  TappayDirect,
  WelcomeRedirect,
  Detail,
  PickUpWelcome,
  SearchOrder,
  LineLiffReminderLayout,
  LiffWelcome,
};
