// import { MemberActionType } from '../../actions/member/memberTypes';
import { CartV2ActionType } from '../../actions/cartV2/cartV2Types';

const initialState: any = {
  orderListInViewStatus: true,
  showCartErrorAlert: false,
  CartErrorAlertMsg: '',
  isCartSubmitDisabled: false,
  showOrderItemModalAlert: false,
  OrderItemModalAlertMsg: '',
  firstAttributeWebItem: null,
  secondAttributeWebItem: null,
  currrentSelectAttributeUuid: [],
  currrentSelectAttribute: null,
  currrentSelectSecondAttribute: null,
  payments: [],
  selectedPayment: '',
  selectedPaymentAlert: false,
  afterApplyRuleVo: null,
  autoShowSecondAttributeModal: false,
  customerName: '',
  customerPhone: '',
  customerAddress: '',
  customerInfoError: false,
};

const cartV2Reducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case CartV2ActionType.SET_CART_V2_INVIEW:
      return {
        ...state,
        orderListInViewStatus: payload,
      };
    case CartV2ActionType.SET_CART_ERROR_ALERT:
      return {
        ...state,
        showCartErrorAlert: payload,
      };
    case CartV2ActionType.SET_CART_ERROR_ALERT_MSG:
      return {
        ...state,
        CartErrorAlertMsg: payload,
      };
    case CartV2ActionType.SET_CART_SUBMIT_DISABLED:
      return {
        ...state,
        isCartSubmitDisabled: payload,
      };
    case CartV2ActionType.SET_ORDER_ITEM_MODAL_ALERT_MSG:
      return {
        ...state,
        OrderItemModalAlertMsg: payload,
      };
    case CartV2ActionType.SET_ORDER_ITEM_MODAL_ALERT:
      return {
        ...state,
        showOrderItemModalAlert: payload,
      };
    case CartV2ActionType.SET_FIRST_ATTRIBUTE_WEBITEMVO:
      return {
        ...state,
        firstAttributeWebItem: payload,
      };
    case CartV2ActionType.SET_SECOND_ATTRIBUTE_WEBITEMVO:
      return {
        ...state,
        secondAttributeWebItem: payload,
      };
    case CartV2ActionType.SET_CURRENT_SELECT_ATTRIBUTE_UUID:
      return {
        ...state,
        currrentSelectAttributeUuid: payload,
      };
    case CartV2ActionType.SET_CURRENT_SELECT_ATTRIBUTE:
      return {
        ...state,
        currrentSelectAttribute: payload,
      };
    case CartV2ActionType.SET_CURRENT_SELECT_SECOND_ATTRIBUTE:
      return {
        ...state,
        currrentSelectSecondAttribute: payload,
      };
    case CartV2ActionType.SET_CART_PAYMENTS:
      return {
        ...state,
        payments: payload,
      };
    case CartV2ActionType.SET_CART_SELECTED_PAYMENT:
      return {
        ...state,
        selectedPayment: payload,
      };
    case CartV2ActionType.SET_CART_SELECTED_PAYMENT_ALERT:
      return {
        ...state,
        selectedPaymentAlert: payload,
      };
    case CartV2ActionType.SET_CART_AFTER_APPLY_RULE:
      return {
        ...state,
        afterApplyRuleVo: payload,
      };
    case CartV2ActionType.SET_CART_AUTO_SHOW_SECOND_ATTRIBUTE:
      return {
        ...state,
        autoShowSecondAttributeModal: payload,
      };
    case CartV2ActionType.SET_CART_CUSTOMER_NAME:
      return {
        ...state,
        customerName: payload,
      };
    case CartV2ActionType.SET_CART_CUSTOMER_PHONE:
      return {
        ...state,
        customerPhone: payload,
      };
    case CartV2ActionType.SET_CART_CUSTOMER_ADDRESS:
      return {
        ...state,
        customerAddress: payload,
      };
    case CartV2ActionType.SET_CART_CUSTOMER_INFO_ERROR:
      return {
        ...state,
        customerInfoError: payload,
      };
    default:
      return state;
  }
};

export default cartV2Reducer;
