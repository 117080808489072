import PromotionType from '@/constants/PromotionType';

const getShareMealPromotions = (
  orderPromotions,
  isPostPayEnable,
  cartOrderItems
): any => {
  if (!isPostPayEnable) return orderPromotions;
  const filterOrderPromotions: any = [];
  if (cartOrderItems === 0) return filterOrderPromotions;
  for (let i = 0; i < orderPromotions.length; i += 1) {
    if (orderPromotions[i].promotionType === PromotionType.SHARE_MEAL_FEE) {
      filterOrderPromotions.push(orderPromotions[i]);
    }
  }
  return filterOrderPromotions;
};

const exceptShareMealPromotions = (orderPromotions): any => {
  const filterOrderPromotions: any = [];
  if (orderPromotions?.length > 0) {
    for (let i = 0; i < orderPromotions.length; i += 1) {
      if (orderPromotions[i].promotionType !== PromotionType.SHARE_MEAL_FEE) {
        filterOrderPromotions.push(orderPromotions[i]);
      }
    }
  }
  return filterOrderPromotions;
};

const PromotionFilter = { getShareMealPromotions, exceptShareMealPromotions };
export default PromotionFilter;
