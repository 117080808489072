import { FormattedMessage } from 'react-intl';
import { ReactElement } from 'react';
import CdsStatusRow from './cdsStatusRow';

const headerColor = '#E1E1E1';
const headerFontSize = '16px';
const cellColor = '#FFFFFF';

const tableHeader: ReactElement[] = [
  <FormattedMessage id="cds.store" />,
  <FormattedMessage id="cds.orderNo" />,
  <FormattedMessage id="cds.lineIn" />,
  <FormattedMessage id="cds.status" />,
];
const colWidth: string[] = [
  'text-left col-5',
  'text-center col-2',
  'text-center col-2',
  'text-center col-3',
];
function CdsStatusTable() {
  return (
    <table className="table table-borderless">
      <thead style={{ backgroundColor: headerColor, fontSize: headerFontSize }}>
        <tr className="d-flex">
          {tableHeader.map((header, i) => (
            <th
              className={'text-nowrap ' + colWidth[i]}
              scope={'col'}
              style={{ fontSize: headerFontSize }}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody style={{ backgroundColor: cellColor }}>
        <CdsStatusRow colWidth={colWidth} />
      </tbody>
    </table>
  );
}

export default CdsStatusTable;
