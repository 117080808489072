import { CommonActionType } from '@/actions/common/commonTypes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

function SuspenseLoading() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: CommonActionType.LOADING });
  }, []);

  return <div />;
}

export default SuspenseLoading;
