import PropTypes from 'prop-types';
import { Style } from '@/components/style';
import { FormattedMessage } from 'react-intl';
import OrderStatusTag from './orderStatusTag';

const { backgroundColor } = new Style().getUiConfig();
function OrderStatusBar({ status, customId, mode, paid }) {
  return (
    <div
      className="d-flex"
      style={{ height: '50px', background: backgroundColor }}
    >
      <div className="d-flex ms-3 me-3 gap-2 align-self-center fw-bold">
        <OrderStatusTag status={status} mode={mode} />
        <div
          className="d-flex justify-content-center"
          style={{ fontSize: '14px' }}
        >
          {paid && <div>*</div>}
          <span className="fw-bold align-self-center">
            <FormattedMessage id="detail.customid" />
          </span>
          &nbsp;
          <span
            className="align-self-center fw-bold"
            data-testid="detailCustomid"
          >
            {customId}
          </span>
        </div>
      </div>
    </div>
  );
}

OrderStatusBar.propTypes = {
  status: PropTypes.string.isRequired,
  customId: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  paid: PropTypes.bool.isRequired,
};

export default OrderStatusBar;
