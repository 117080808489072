const translateErrorCode = (code, intl) => {
  if (
    code === '10003' ||
    code === '10009' ||
    code === '100041' ||
    code === '10005' ||
    code === '10006' ||
    code === '10017' ||
    code === '10019' ||
    code === '10020' ||
    code === '20001' ||
    code === '20002' ||
    code === '20004' ||
    code === '20005' ||
    code === '30001' ||
    code === '30003' ||
    code === '40001' ||
    code === '40002' ||
    code === '40003' ||
    code === '40007' ||
    code === '40008' ||
    code === '40009' ||
    code === '40012' ||
    code === '50001' ||
    code === '60001'
  ) {
    let temp = {
      title: intl.formatMessage({ id: `error.${code}.title` }),
      msg: intl.formatMessage({
        id: `error.${code}.msg`,
      }),
    };
    return temp;
  }

  return {
    title: '',
    msg: intl.formatMessage({
      id: `error.${code}`,
    }),
  };
};

export default translateErrorCode;
