import PropTypes from 'prop-types';
import OrderStatusType from '@/constants/OrderStatusType';
import { Style } from '@/components/style';
import { useIntl } from 'react-intl';
// 如果訂單 取消 退款 逾時 待付款 顏色為 alertColor 且有不同 wording

const { alertColor, primaryColor, whiteColor } = new Style().getUiConfig();
function OrderStatusTag({ mode, status }) {
  const intl = useIntl();

  const tagColor =
    status === OrderStatusType.CANCELLED ||
    status === OrderStatusType.EXPIRED ||
    mode === 'kiosk'
      ? alertColor
      : primaryColor;

  const message = () => {
    if (
      status === OrderStatusType.CANCELLED ||
      status === OrderStatusType.EXPIRED
    )
      return intl.formatMessage({ id: 'common.order.cancel' });
    if (mode === 'kiosk')
      return intl.formatMessage({ id: 'detail.pay.pending' });
    if (mode === 'detail')
      return intl.formatMessage({ id: 'common.order.paid' });
    return '';
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        borderRadius: '20px',
        backgroundColor: tagColor,
        padding: '5px 10px',
        height: '28px',
        minWidth: '60px',
        fontSize: '10px',
        color: whiteColor,
      }}
    >
      <div>{message()}</div>
    </div>
  );
}

OrderStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
};

export default OrderStatusTag;
