import store from '@/store';
import { MessageTypes } from '@actions/message/messageTypes';
import { isEmpty } from 'lodash';

export const setAlertMessage: (alertMessage?: string) => any = (
  alertMessage
) => {
  store.dispatch({
    type: MessageTypes.SET_ALERT_MESSAGE,
    payload: alertMessage,
  });
};

export const setErrorMessage: (errorMessage?: string, config?: any) => any = (
  errorMessage,
  config = {}
) => {
  store.dispatch({
    type: MessageTypes.SET_ERROR_MESSAGE,
    payload: {
      errorMessage,
      errorMessageConfig: config,
    },
  });
};

export const setErrorConfirm: (errorConfirm?: () => void) => any = (
  errorConfirm
) => {
  store.dispatch({
    type: MessageTypes.SET_ERROR_CONFIRM,
    payload: errorConfirm,
  });
};

export const initDefault: () => any = () => {
  setAlertMessage(undefined);
  setErrorMessage(undefined);
  setErrorConfirm(() => {});
};

export const initMessage: (config: any) => any = (config) => {
  if (isEmpty(config)) return;

  setAlertMessage(config.alertMessage);
  setErrorMessage(config.errorMessage);
  setErrorConfirm(config.errorConfirm);
};

export default {
  setAlertMessage,
  setErrorMessage,
  setErrorConfirm,
  initDefault,
};
