import PropTypes from 'prop-types';
import Time from '@/assets/img/time.svg';
import InformationLayout from '@/components/common/storeDetail/informationLayout';
import BusinessHoursRow from '@/components/common/storeDetail/businessHoursRow';
import { useIntl } from 'react-intl';

// 先 hard code 因為目前沒有提供每天的營業時間到前端
// 目標是要顯示星期一到星期日的營業時間
function BusinessHours({ webStoreVo }) {
  const businessTitle = useIntl().formatMessage({
    id: 'storeDetail.business.time',
  });
  const openRclose = useIntl().formatMessage({ id: 'storeDetail.business.in' }); // 之後要判斷 營業中 休息中
  return (
    <InformationLayout
      title={businessTitle}
      titleIcon={Time}
      endTitle={openRclose}
    >
      <div
        className="d-flex flex-column gap-1 w-100"
        style={{ fontSize: '14px' }}
      >
        <BusinessHoursRow
          dayOfWeek="Today"
          businessTime={`${webStoreVo.startTime}-${webStoreVo.endTime}`}
        />
      </div>
    </InformationLayout>
  );
}

BusinessHours.propTypes = {
  webStoreVo: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired,
};

export default BusinessHours;
