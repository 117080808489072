const enum MemberActionType {
  SET_MEMBER_PHONE = 'SET_MEMBER_PHONE',
  SET_MEMBER_POINTS = 'SET_MEMBER_POINTS',
  SET_MEMBER_OTP_VERIFIED = 'SET_MEMBER_OTP_VERIFIED',
  SET_IS_LINEID_BOUNDED = 'SET_IS_LINEID_BOUNDED',
  SET_IS_DISAGREE_LINE_TO_BIND = 'SET_IS_DISAGREE_LINE_TO_BIND',
  SET_MEMBER_INFO_RES = 'SET_MEMBER_INFO_RES',
  SET_STORE_INFO_RES = 'SET_STORE_INFO_RES',
  SET_MEMBER_PAGE_STATE = 'SET_MEMBER_PAGE_STATE',
  SET_MEMBER_LANGS = 'SET_MEMBER_LANGS',
  SET_SHOW_MEMBER_DROP_DOWN = 'SET_SHOW_MEMBER_DROP_DOWN',
  SET_MEMBER_EDIT_ENTRANCE = 'SET_MEMBER_EDIT_ENTRANCE',
  SET_MEMBER_REGISTER_ENTRANCE = 'SET_MEMBER_REGISTER_ENTRANCE',
  SET_UPDATE_MEMBER_PHONE = 'SET_UPDATE_MEMBER_PHONE',
  SET_CURRENT_SELECTED_PHONE = 'SET_CURRENT_SELECTED_PHONE',
}

export { MemberActionType };
