import { ModeActionType } from '../../actions/mode/modeTypes';

const initialState: any = {
  projectName: null,
  serveType: 'DEFAULT',
  customizedServeType: null,
};
// TODO : save webVo state only ?
const modeReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case ModeActionType.SET_CURRENT_SERVETYPE:
      return {
        ...state,
        serveType: payload,
      };
    case ModeActionType.SET_PROJECT_NAME:
      return {
        ...state,
        projectName: payload,
      };
    case ModeActionType.SET_CUSTOMIZED_SERVETYPE:
      return {
        ...state,
        customizedServeType: payload,
      };

    default:
      return state;
  }
};

export default modeReducer;
