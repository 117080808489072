const enum RedeemActionType {
  SET_MEMBER_REDEEMITEMS = 'SET_MEMBER_REDEEMITEMS',
  SET_CURRENT_SELECTED_POINTS = 'SET_CURRENT_SELECTED_POINTS',
  SET_REDEEM_MODAL_STATUS = 'SET_REDEEM_MODAL_STATUS',
  SET_REDEEM_ERROR = 'SET_REDEEM_ERROR',
  SET_REDEEM_STATUS = 'SET_REDEEM_STATUS',
  SET_OTP_CODE = 'SET_OTP_CODE',
  SET_REDEEM_BODY_STATE = 'SET_REDEEM_BODY_STATE',
}

export { RedeemActionType };
