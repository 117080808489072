import { WelcomeActionType } from '../../actions/welcome/welcomeTypes';

const initialState: any = {};

const welcomeReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case WelcomeActionType.GET_WELCOME:
      return {
        ...state,
        webStoreVoResponse: {
          code: payload.code,
          data: payload.data,
          message: payload.message,
        },
      };

    default:
      return state;
  }
};

export default welcomeReducer;
