class Style {
  uiConfig = {
    primaryColor: '#58B6A8',
    secondaryColor: '#5292D9',
    whiteColor: '#FFFFFF',
    gray1Color: '#F3F3F4',
    gray2Color: '#E1E1E1',
    gray3Color: '#8D8C8C',
    blackColor: '#000000',
    backgroundColor: '#F5F8F9',
    alertColor: '#FF8A80',
    greenColor: '#58B6A8',
  };

  constructor() {
    this.uiConfig = {
      primaryColor: '#58B6A8',
      secondaryColor: '#5292D9',
      whiteColor: '#FFFFFF',
      gray1Color: '#F3F3F4',
      gray2Color: '#E1E1E1',
      gray3Color: '#8D8C8C',
      blackColor: '#000000',
      backgroundColor: '#F5F8F9',
      alertColor: '#FF8A80',
      greenColor: '#58B6A8',
    };
  }

  paymentChecked() {
    return {
      backgroundColor: this.uiConfig.whiteColor,
      borderColor: this.uiConfig.primaryColor,
      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2358B6A8'/%3e%3c/svg%3e")`,
    };
  }

  onlinePaymentNInvoiceChecked() {
    return {
      backgroundColor: this.uiConfig.whiteColor,
      borderColor: this.uiConfig.primaryColor,
      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2358B6A8'/%3e%3c/svg%3e")`,
    };
  }

  paymentCheckedAlert() {
    return {
      backgroundColor: this.uiConfig.whiteColor,
      borderColor: this.uiConfig.alertColor,
    };
  }

  onlinePaymentNInvoiceCheckedAlert() {
    return {
      backgroundColor: this.uiConfig.whiteColor,
      borderColor: this.uiConfig.alertColor,
    };
  }

  categoryBtn() {
    return {
      background: this.uiConfig.whiteColor,
      color: this.uiConfig.primaryColor,
      borderColor: this.uiConfig.primaryColor,
    };
  }

  categoryBtnSelected() {
    return {
      backgroundColor: this.uiConfig.primaryColor,
      color: this.uiConfig.whiteColor,
    };
  }

  primaryBtn() {
    return {
      color: this.uiConfig.gray3Color,
      backgroundColor: this.uiConfig.gray1Color,
      borderColor: this.uiConfig.gray1Color,
      fontSize: 'min(4vw, 16px)',
      fontWeight: 'normal',
    };
  }

  primaryBtnSelected() {
    return {
      color: this.uiConfig.whiteColor,
      backgroundColor: this.uiConfig.primaryColor,
      borderColor: this.uiConfig.primaryColor,
      fontSize: 'min(4vw, 16px)',
      fontWeight: 'bolder',
    };
  }

  ctaBtn() {
    return {
      color: this.uiConfig.whiteColor,
      backgroundColor: this.uiConfig.secondaryColor,
      borderColor: this.uiConfig.secondaryColor,
      fontSize: '18px',
      fontWeight: 'bolder',
    };
  }

  ctaBtnDisable() {
    return {
      color: '#E1E1E1',
      backgroundColor: this.uiConfig.gray1Color,
      borderColor: this.uiConfig.gray1Color,
      fontSize: '18px',
      fontWeight: 'bolder',
    };
  }

  searchOrderBtn() {
    return {
      color: this.uiConfig.secondaryColor,
      backgroundColor: this.uiConfig.whiteColor,
      borderColor: this.uiConfig.secondaryColor,
      fontSize: '12px',
      borderRadius: '20px',
      fontweight: 'bold',
    };
  }

  tappayFieldErorrBorder() {
    return {
      background: this.uiConfig.whiteColor,
      border: '1px solid #E64942',
      borderColor: this.uiConfig.alertColor,
    };
  }

  tappayFieldBorder() {
    return {
      background: this.uiConfig.whiteColor,
      border: '1px solid',
    };
  }

  getUiConfig() {
    return this.uiConfig;
  }
}

export default Style;
