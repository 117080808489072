import PropTypes from 'prop-types';
import { Style } from '@/components/style';

const { backgroundColor } = new Style().getUiConfig();
function SocialMediaIcon({ img, alt, url }) {
  const handleSocailIconOnClick = () => {
    document.location.href = url;
  };
  return (
    url && (
      <div
        role="presentation"
        className="rounded-circle  d-flex text-center justify-content-center align-items-center"
        style={{ backgroundColor, width: '30px', height: '30px' }}
        onClick={handleSocailIconOnClick}
      >
        <img src={img} alt={alt} style={{ width: '14px' }} />
      </div>
    )
  );
}

SocialMediaIcon.defaultProps = {
  url: '',
};
SocialMediaIcon.propTypes = {
  img: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export default SocialMediaIcon;
