import PropTypes from 'prop-types';
import { Style } from '@/components/style';
import { FormattedMessage, useIntl } from 'react-intl';
import UberDirectStatusType from '@/constants/UberDirectStatusType';
import { img } from '@/assets';
import { useState } from 'react';

const { backgroundColor, gray3Color } = new Style().getUiConfig();
function UberDirectStatus({ preorderTime, uberStatus }) {
  const intl = useIntl();
  const getImgWithStatus = () => {
    switch (uberStatus) {
      case UberDirectStatusType.pending:
        return img.icon.OrderProcessing;
      case UberDirectStatusType.pickup:
        return img.icon.OrderDelivering;
      case UberDirectStatusType.pickup_complete:
        return img.icon.OrderDelivering;
      case UberDirectStatusType.dropoff:
        return img.icon.OrderDelivering;
      case UberDirectStatusType.delivered:
        return img.icon.OrderArrive;
      default:
        return '';
    }
  };

  const getUberStatusTitle = (): string => {
    switch (uberStatus) {
      case UberDirectStatusType.pending:
        return intl.formatMessage({
          id: 'uberDirect.order.status.pending',
        });
      case UberDirectStatusType.pickup:
        return intl.formatMessage({
          id: 'uberDirect.order.status.delivering',
        });
      case UberDirectStatusType.pickup_complete:
        return intl.formatMessage({
          id: 'uberDirect.order.status.delivering',
        });
      case UberDirectStatusType.dropoff:
        return intl.formatMessage({
          id: 'uberDirect.order.status.delivering',
        });
      case UberDirectStatusType.delivered:
        return intl.formatMessage({
          id: 'uberDirect.order.status.delivered',
        });
      default:
        return '';
    }
  };

  const getDeliveryTime = () => {
    if (!preorderTime) return;
    const tempTime = preorderTime.split('T');
    const date = tempTime[0].replaceAll('-', '/');
    const time = tempTime[1].split('+')[0];
    const formatTime = date.concat(` ${time}`);

    return formatTime;
  };

  const showDeliveryTime = (): boolean => {
    if (uberStatus === UberDirectStatusType.pending) {
      return true;
    }

    if (uberStatus === UberDirectStatusType.pickup) {
      return true;
    }

    if (uberStatus === UberDirectStatusType.pickup_complete) {
      return true;
    }

    if (uberStatus === UberDirectStatusType.dropoff) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <div className="d-flex mb-2 justify-content-center">
        <img src={getImgWithStatus()} style={{ marginRight: '10px' }} alt="" />
        <div className="text-center" style={{ fontSize: '18px' }}>
          {getUberStatusTitle()}
        </div>
      </div>

      {showDeliveryTime() ? (
        <div>
          <div
            className="d-flex mb-3 justify-content-center"
            style={{
              fontSize: '16px',
            }}
          >
            <FormattedMessage id="uberDirect.order.delivered.time" />
            <div style={{ marginLeft: '5px', color: gray3Color }}>
              {getDeliveryTime()}
            </div>
          </div>

          <div className="mb-3">
            {' '}
            <img src={img.icon.DetailBorder} alt="" />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

UberDirectStatus.defaultProps = {
  uberStatus: UberDirectStatusType.pending,
};

UberDirectStatus.propTypes = {
  preorderTime: PropTypes.string,
  uberStatus: PropTypes.string,
};

export default UberDirectStatus;
