const enum CartV2ActionType {
  SET_CART_V2_INVIEW = 'SET_CART_V2_INVIEW',
  SET_CART_ERROR_ALERT = 'SET_CART_ERROR_ALERT',
  SET_CART_ERROR_ALERT_MSG = 'SET_CART_ERROR_ALERT_MSG',
  SET_CART_SUBMIT_DISABLED = 'SET_CART_SUBMIT_DISABLED',
  SET_ORDER_ITEM_MODAL_ALERT = 'SET_ORDER_ITEM_MODAL_ALERT',
  SET_ORDER_ITEM_MODAL_ALERT_MSG = 'SET_ORDER_ITEM_MODAL_ALERT_MSG',
  SET_FIRST_ATTRIBUTE_WEBITEMVO = 'SET_FIRST_ATTRIBUTE_WEBITEMVO',
  SET_SECOND_ATTRIBUTE_WEBITEMVO = 'SET_SECOND_ATTRIBUTE_WEBITEMVO',
  SET_CURRENT_SELECT_ATTRIBUTE = 'SET_CURRENT_SELECT_ATTRIBUTE',
  SET_CURRENT_SELECT_SECOND_ATTRIBUTE = 'SET_CURRENT_SELECT_SECOND_ATTRIBUTE',
  SET_CURRENT_SELECT_ATTRIBUTE_UUID = 'SET_CURRENT_SELECT_ATTRIBUTE_UUID',
  SET_CART_SELECTED_PAYMENT = 'SET_CART_SELECTED_PAYMENT',
  SET_CART_SELECTED_PAYMENT_ALERT = 'SET_CART_SELECTED_PAYMENT_ALERT',
  SET_CART_PAYMENTS = 'SET_CART_PAYMENTS',
  SET_CART_AFTER_APPLY_RULE = 'SET_CART_AFTER_APPLY_RULE',
  SET_CART_AUTO_SHOW_SECOND_ATTRIBUTE = 'SET_CART_AUTO_SHOW_SECOND_ATTRIBUTE',
  SET_CART_CUSTOMER_NAME = 'SET_CART_CUSTOMER_NAME',
  SET_CART_CUSTOMER_PHONE = 'SET_CART_CUSTOMER_PHONE',
  SET_CART_CUSTOMER_ADDRESS = 'SET_CART_CUSTOMER_ADDRESS',
  SET_CART_CUSTOMER_INFO_ERROR = 'SET_CART_CUSTOMER_INFO_ERROR',
}

export { CartV2ActionType };
