import PropTypes from 'prop-types';
import { Style } from '@/components/style';
// for the button which is fixed bottom of the page.

const { whiteColor } = new Style().getUiConfig();
function BottomButtonLayout({ children }) {
  return (
    <div
      className="fixed-bottom w-100 d-flex justify-content-center"
      style={{
        boxShadow: '0px -0.904px 5.424px rgba(0, 0, 0, 0.161)',
        height: '65px',
        background: whiteColor,
        zIndex: 2,
      }}
    >
      {children}
    </div>
  );
}

BottomButtonLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BottomButtonLayout;
