const enum MemberInfoTypes {
  INIT_MEMBER_INFO = 'INIT_MEMBER_INFO',
  SET_ENABLED = 'SET_ENABLED',
  SET_IS_LOGIN = 'SET_IS_LOGIN',
  SET_MEMBER_TOKEN = 'SET_MEMBER_TOKEN',
  SET_CAPTCHA_TOKEN = 'SET_CAPTCHA_TOKEN',
  SET_CURRENT_PHONE = 'SET_CURRENT_PHONE',
  SET_IS_MEMBER = 'SET_IS_MEMBER',
  REFRESH_MEMBER_INFO = 'REFRESH_MEMBER_INFO',
}

export { MemberInfoTypes };
