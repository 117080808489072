const enum OrderStatusType {
  WAITING = 'WAITING',
  QUEUEING = 'QUEUEING',
  PROCESSING = 'PROCESSING',
  CABINET = 'CABINET',
  DONE = 'DONE',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  CUBBY = 'CUBBY',
  EXPIRED = 'EXPIRED',
  WARNING = 'WARNING',
  REFUND = 'REFUND',
  REFUNDED = 'REFUNDED',
  INVALID = 'INVALID',
  CASH = 'CASH',
}
// QUEUEING : "準備中"
// CABINET : "可取餐"
// CLOSED : "已取餐"
// CANCELLED : "已取消"
// EXPIRED" : "已逾時"
// REFUND : 已退款"
export default OrderStatusType;
