import { FormattedMessage } from 'react-intl';
import { RootStateOrAny, useSelector } from 'react-redux';

type rowData = {
  storeName?: string;
  orderNo?: string;
  queue: string;
  status: string;
};

type props = {
  colWidth: string[];
};

const dataFontSize = '14px';
function CdsStatusRow(props: props) {
  const webCdsVos: rowData[] = useSelector<RootStateOrAny, any>(
    (state) => state.orderReducer.webCdsVos
  );
  console.log(webCdsVos);
  return (
    <>
      {webCdsVos.map((rowData) => (
        <tr
          className="d-flex align-items-center"
          style={{ height: '35px', fontSize: dataFontSize }}
        >
          <td className={props.colWidth[0]}>{rowData.storeName}</td>
          <td style={{ whiteSpace: 'nowrap' }} className={props.colWidth[1]}>
            {rowData.orderNo}
          </td>
          <td className={props.colWidth[2]}>{rowData.queue}</td>
          <td className={props.colWidth[3]}>
            <FormattedMessage id={`cds.${rowData.status}`} />
          </td>
        </tr>
      ))}
    </>
  );
}

export default CdsStatusRow;
