import http from '../../httpAxios';

const redirect: (orderUuid: string, seatUuid: string | null) => any = (
  orderUuid,
  seatUuid
) => {
  const url = `/online/v2/order/${orderUuid}`;
  return http.get(url, { seatUuid });
};

const stores: (orderUuid: string) => any = (orderUuid) => {
  const url = `/online/v2/order/${orderUuid}/stores`;
  return http.get(url);
};

const recentorder: (
  storeUuid: string,
  mobile: string,
  lineUserId: string
) => any = (storeUuid, mobile, lineUserId) => {
  const url = `/online/v2/store/${storeUuid}/get-recent-order`;
  return http.get(url, {
    mobile,
    lineUserId,
  });
};

const recentordercommon: (storeUuid: string, employeeId: string) => any = (
  storeUuid,
  employeeId
) => {
  const url = `/online/v2/store/${storeUuid}/get-recent-order-common?empNo=${employeeId}`;
  return http.get(url);
};

const menu: (orderUuid: string, storeUuid?: string | null) => any = (
  orderUuid,
  storeUuid
) => {
  let url = `/online/v2/order/${orderUuid}/menu`;
  if (storeUuid) {
    url += `?storeUuid=${storeUuid}`;
  }
  return http.get(url);
};

const getMenu: (orderVo: any) => any = (orderVo) => {
  const { storeId } = orderVo;
  let url = `/online/v2/store/${storeId}/menu`;
  return http.post(url, JSON.stringify(orderVo));
};

const rule: (orderVo: any) => any = (orderVo) => {
  const url = `/online/v2/order/apply-rule`;
  return http.post(url, JSON.stringify(orderVo));
};

const getredeem: (orderVo: any) => any = (orderVo) => {
  const url = `/online/v2/get-redeem`;
  return http.post(url, orderVo);
};

const getotp: (orderUuid: string, storeId: any) => any = (
  orderUuid,
  storeId
) => {
  const url = `/online/v2/order/${orderUuid}/get-otp?storeId=${storeId}`;
  return http.get(url);
};

const sendOtp: (
  storeId: any,
  mobile: string,
  country: string,
  lineUserId: any
) => any = (storeId, mobile, country, lineUserId) => {
  const url = `/online/v2/otp/send?storeId=${storeId}&mobile=${mobile}&country=${country}&lineUserId=${lineUserId}`;
  return http.get(url);
};

const lineCheck: (
  storeId: any,
  mobile: string,
  country: string,
  lineUserId: any
) => any = (storeId, mobile, country, lineUserId) => {
  const url = `/online/v2/otp/line/check?storeId=${storeId}&mobile=${mobile}&country=${country}&lineUserId=${lineUserId}`;
  return http.get(url);
};

const lineRelation: (data: any) => any = (data) => {
  const url = `/online/v2/otp/line/relation`;
  return http.post(url, data);
};

const redeempoint: (
  orderUuid: string,
  otp: string,
  orderPromotionVos: any,
  storeId: any
) => any = (orderUuid, otp, orderPromotionVos, storeId) => {
  const url = `/online/v2/order/${orderUuid}/redeem-points/${otp}?storeId=${storeId}`;
  return http.post(url, orderPromotionVos);
};

const validatecoupon: (
  orderUuid: string,
  time: string,
  barcodes: any,
  storeId?: any
) => any = (orderUuid, time, barcodes, storeId) => {
  const url = `/online/v2/order/${orderUuid}/coupon/validate?time=${time}&storeId=${storeId}`;
  return http.post(url, barcodes);
};

const updatemobile: (orderUuid: string, orderVo: any) => any = (
  orderUuid,
  orderVo
) => {
  const url = `/online/v2/order/${orderUuid}/update-mobile`;
  return http.put(url, orderVo);
};

const currentorder: (seatUuid: string) => any = (seatUuid) => {
  const url = `/online/v2/seat/${seatUuid}/current-order`;
  return http.get(url);
};

const customWelcome: (customUuid: string) => any = (customUuid) => {
  const url = `/online/v2/custom/${customUuid}`;
  return http.get(url);
};

const getrecommendation: (orderUuid: string, webVo: any) => any = (
  orderUuid,
  webVo
) => {
  const url = `/online/v2/order/${orderUuid}/recommendation`;
  return http.post(url, webVo);
};

const submitorder: (
  orderUuid: string,
  webVo: any,
  seatUuid: string | null
) => any = (orderUuid, webVo, seatUuid) => {
  const url = `/online/v2/order/${orderUuid}/submit-order`;
  return http.post(url, webVo, { seatUuid });
};

const updateIncomingTiming: (orderUuid: string, webVo: any) => any = (
  orderUuid,
  webVo
) => {
  const url = `/online/v2/order/${orderUuid}/update-incoming-timing`;
  return http.post(url, webVo);
};

const updateparty: (orderUuid: string, orderVo: any) => any = (
  orderUuid,
  orderVo
) => {
  const url = `/online/v2/order/${orderUuid}/update-party`;
  return http.post(url, orderVo);
};

const addcouponbarcodes: (orderUuid: string, couponBarcodes: any) => any = (
  orderUuid,
  couponBarcodes
) => {
  const url = `/online/v2/order/${orderUuid}/add-coupon-barcodes`;
  return http.post(url, couponBarcodes);
};

const getorder: (orderUuid: string) => any = (orderUuid) => {
  const url = `/online/v2/order/${orderUuid}/get-order`;
  return http.get(url);
};

const changeLocale: (orderUuid: string, locale: string) => any = (
  orderUuid,
  locale
) => {
  const url = `/online/v2/order/${orderUuid}/${locale}`;
  return http.get(url);
};

const cancelPickup: (orderUuid: string, onFulfilled, orReject) => any = (
  orderUuid,
  onFulfilled,
  orReject
) => {
  const url = `/online/order/${orderUuid}/cancel-pickup`;
  return http.put(url, undefined, undefined, onFulfilled, orReject);
};

const addon: (storeUuid: string, data: any) => any = (storeUuid, data) => {
  const url = `/online/v2/store/${storeUuid}/addon`;
  return http.post(url, data);
};

const OrderService = {
  redirect,
  stores,
  recentorder,
  recentordercommon,
  menu,
  getMenu,
  rule,
  getredeem,
  getotp,
  sendOtp,
  lineCheck,
  lineRelation,
  redeempoint,
  validatecoupon,
  updatemobile,
  currentorder,
  customWelcome,
  getrecommendation,
  submitorder,
  updateIncomingTiming,
  updateparty,
  addcouponbarcodes,
  getorder,
  changeLocale,
  cancelPickup,
  addon,
};

export default OrderService;
