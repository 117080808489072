import { Ii18n } from './type';

const error: Ii18n = {
  'error.title': 'Lỗi',
  'error.confirm': 'ĐƯỢC RỒI',
  'error.noParty': 'Vui lòng chọn số lượng người',
  'error.invalidMobile': 'Vui lòng nhập số điện thoại di động chính xác',
  'error.invalidName': 'Vui lòng nhập tên chính xác',
  'error.invalidDateTime': 'Vui lòng chọn ngày và giờ',
  'error.invalidNameAddressFields': 'Vui lòng nhập tên và địa chỉ',
  'error.noRedeemItems': 'Không có mặt hàng đổi thưởng nào có sẵn',
  'error.network': 'Lỗi mạng',
  'error.1': 'Hệ thống ngoại lệ',
  'error.10001': 'Đặt hàng bất thường',
  'error.10002': 'Đơn hàng đã được thanh toán',
  'error.10003': 'Thanh toán đơn hàng, không thể thêm điểm, cảm ơn bạn',
  'error.10009': 'Đơn hàng đã được chia nhỏ và không thể thêm các mặt hàng.',
  'error.10004': 'Hết hàng ',
  'error.100041':
    'Thông tin bữa ăn có gì bất thường, vui lòng thông báo cho nhân viên bán hàng để được hỗ trợ, xin cảm ơn! [yêu cầu cập nhật menu]',
  'error.10005': 'Thuộc tính mặt hàng bị lỗi, vui lòng liên hệ với nhân viên',
  'error.10006': 'Đặt hàng không thành công, vui lòng liên hệ với nhân viên',
  'error.10017': 'Các bảng đã được hợp nhất, vui lòng quét lại Mã QR của bảng',
  'error.20001': 'Hóa đơn hết, vui lòng liên hệ nhân viên',
  'error.20002': 'Đặt hàng là thanh toán, vui lòng liên hệ nhân viên',
  'error.20003': 'Không tìm thấy đơn hàng',
  'error.20004': 'Thanh toán không thành công, vui lòng thử lại',
  'error.20005':
    'Tạo thanh toán không thành công. Nếu thẻ của bạn bị trừ tiền, vui lòng yêu cầu nhân viên hoàn lại tiền',
  'error.30001':
    'Lỗi thông tin chỗ ngồi, vui lòng liên hệ với nhân viên. Cảm ơn bạn',
  'error.30002': 'Vui lòng liên hệ nhân viên để mở bàn',
  'error.30003': 'Bảng lặp lại, vui lòng chọn bảng khác. Cảm ơn bạn',
  'error.40001': 'Đơn hàng bị lỗi, vui lòng liên hệ nhân viên. Cảm ơn bạn',
  'error.40002':
    'Thông tin cửa hàng bị lỗi, vui lòng liên hệ với nhân viên. Cảm ơn bạn',
  'error.40003':
    'Cửa hàng không mở. Vui lòng liên hệ với nhân viên. \nGiờ làm việc trực tuyến là \n {startTime}-{endTime}',
  'error.40004': 'Không tìm thấy nhân viên',
  'error.40005': 'Không tìm thấy thành viên',
  'error.40006': 'LỖI BÁO CÁO BÁN HÀNG PARSE',
  'error.40007':
    'Giờ làm việc trực tuyến là \n {startTime}-{endTime} \nVui lòng đặt hàng trong giờ làm việc, cảm ơn bạn!',
  'error.40008':
    'Không có điện thoại di động của thành viên như vậy, \n Vui lòng nhập lại điện thoại di động của thành viên',
  'error.40009':
    'Hiện tại tủ giữ đồ ăn thông minh đã hết hàng, \n Vui lòng đợi đặt hàng, xin cảm ơn!',
  'error.40011': 'dịch vụ Không sẵn có',
  'error.40012': 'Không đủ điểm, vui lòng xóa vật phẩm trao đổi',
  'error.40013': 'dịch vụ Không sẵn có',
  'error.50001': 'Hệ thống đang bận, vui lòng thử lại. Cảm ơn bạn',
  'error.50003': 'dịch vụ Không sẵn có',
  'error.60001': 'Nhiều nhất 3 OTP mỗi ngày',
  'error.60002': 'Vui lòng nhập đúng mã OTP',
  'error.noPromotion': 'Vui lòng chọn một chương trình khuyến mãi',
  'error.noRedeem': 'Vui lòng chọn một giảm giá',
  'error.noOtp': 'Vui lòng nhập OTP',
  'error.camera.permission': 'Vui lòng cho phép máy ảnh cho phép và thử lại',
  'error.coupon.not.exist': 'Coupon không tồn tại',
  'error.coupon.used': 'Coupon Đã sử dụng',
  'error.coupon.time': 'Không thể sử dụng cái này bây giờ Coupon',
  'error.coupon.store': 'Không có sẵn tại cửa hàng này Coupon',
  'error.coupon.server': 'Lỗi. Vui lòng thử lại',
  'error.qr.not.detected': 'Không thể phát hiện mã QR',
  'error.qr.empty': 'Vui lòng nhập số sê-ri phiếu thưởng',
  'error.select.payment': 'Vui lòng chọn thanh toán',
  'error.select.onlinepayment': 'Vui lòng chọn thanh toán trực tuyến',
  'error.select.invoice': 'Vui lòng chọn loại hóa đơn',
  'error.select.npo': 'Vui lòng chọn NPO',
  'error.fill.npo': 'Vui lòng điền mã NPO',
  'error.identifier.406': 'Số nhận dạng sai, vui lòng thử lại',
  'error.identifier.403': 'Số nhận dạng sai, vui lòng thử lại',
  'error.identifier.400': 'Định danh sai',
  'error.identifier': 'Định danh sai',
  'error.invoice.gov': 'Lỗi máy chủ, vui lòng chọn tùy chọn khác',
  'error.mobilecarrier': 'Vui lòng điền vào nhà cung cấp dịch vụ di động',
  'error.mobilecarrier.notvalid': 'Nhà cung cấp dịch vụ di động sai',
  'error.npo.notvalid': 'NPO sai',
  'error.payment.retry': 'Quay lại thanh toán',
  'error.cardnumber': 'Số thẻ không hợp lệ',
  'error.cardexpdate': 'Thẻ đã hết hạn',
  'error.cardcsc': 'Mã CSC trước',
  'error.pay': '{pay} Lỗi thanh toán, Mã [{status}], Lý do [{msg}]',
  'error.preorderTime':
    'Thời gian được chỉ định không có sẵn, vui lòng chọn lại',
  'error.payment': 'Thanh toán thất bại',
  'error.pickup.unavailable': 'Nhận hàng hiện không khả dụng',
  'error.preorderTime.unavailable': 'Đơn đặt hàng trước hiện không có sẵn',
  'error.order.full': 'Đặt hàng đầy đủ',
  'error.time.order.full':
    'Giờ đã chọn của bạn không khả dụng, vui lòng chọn thời gian mới',
  'error.order.b Before.mergeOrder':
    'Các bảng đã được hợp nhất, vui lòng quét lại Mã QR của bảng',
  'error.order.after.mergeOrder':
    'Đơn hàng đã bị thay đổi thành đơn hàng không hợp lệ, vui lòng quét lại mã QR của bảng',
  'error.10019': 'Đơn hàng bị trùng lặp, vui lòng quét mã QR để đặt hàng lại.',
  'error.10020':
    'Không thể tiếp tục đơn hàng, vui lòng quét lại mã để đặt hàng',
  'error.10003.title': 'Không thể thêm mục',
  'error.10003.msg': 'Đơn hàng đang được kiểm tra',
  'error.10009.title': 'Không thể thêm mục',
  'error.10009.msg': 'Đơn hàng đã được chia',
  'error.100041.title': 'Thông tin bữa ăn bất thường',
  'error.100041.msg': 'Hãy yêu cầu nhân viên dịch vụ cập nhật menu',
  'error.10005.title': 'Ngoại lệ thuộc tính mục',
  'error.10005.msg': 'Vui lòng liên hệ với nhân viên dịch vụ',
  'error.10006.title': 'Thiết lập đơn hàng không thành công',
  'error.10006.msg': 'Vui lòng liên hệ với nhân viên dịch vụ',
  'error.10017.title': 'Các bảng đã được hợp nhất',
  'error.10017.msg': 'Vui lòng quét lại mã QR của bảng',
  'error.10019.title': 'Đơn hàng trùng lặp',
  'error.10019.msg': 'Vui lòng quét lại mã để đặt hàng',
  'error.10020.title': 'Không thể tiếp tục đặt hàng',
  'error.10020.msg': 'Vui lòng quét lại mã để đặt hàng',
  'error.20001.title': 'Không có số hóa đơn',
  'error.20001.msg': 'Vui lòng liên hệ với nhân viên dịch vụ',
  'error.20002.title': 'Đơn hàng đã được kiểm tra',
  'error.20002.msg': 'Vui lòng liên hệ với nhân viên dịch vụ',
  'error.20004.title': 'Thanh toán không thành công',
  'error.20004.msg': 'Vui lòng thử lại',
  'error.20005.title': 'Việc thiết lập đơn hàng không thành công',
  'error.20005.msg':
    'Nếu thẻ của bạn bị ghi nợ, vui lòng đến cửa hàng để được hoàn tiền',
  'error.30001.title': 'Lỗi thông tin chỗ ngồi',
  'error.30001.msg': 'Vui lòng liên hệ với nhân viên dịch vụ',
  'error.30003.title': 'Số bảng trùng lặp',
  'error.30003.msg': 'Vui lòng chọn chỗ ngồi khác',
  'error.40001.title': 'Sai số thứ tự',
  'error.40001.msg': 'Hãy yêu cầu nhân viên phục vụ mở bàn',
  'error.40002.title': 'Lỗi thông báo lưu trữ',
  'error.40002.msg': 'Vui lòng liên hệ với nhân viên dịch vụ',
  'error.40003.title': 'Giờ không làm việc hiện tại',
  'error.40003.msg':
    'Vui lòng liên hệ với nhân viên dịch vụ để được hỗ trợ \nGiờ làm việc trực tuyến là {startTime}-{endTime}',
  'error.40007.title': 'Giờ không làm việc hiện tại',
  'error.40007.msg':
    'Giờ làm việc trực tuyến là\n {startTime}-{endTime} \nVui lòng đặt hàng trong giờ làm việc',
  'error.40008.title': 'Không tìm thấy điện thoại di động của thành viên này',
  'error.40008.msg': 'Vui lòng nhập lại',
  'error.40009.title': 'Tủ lấy bữa ăn thông minh đã đầy',
  'error.40009.msg': 'Vui lòng đợi một lát trước khi đặt hàng',
  'error.40012.title': 'Không đủ điểm',
  'error.40012.msg': 'Vui lòng xóa các mục đổi thưởng',
  'error.50001.title': 'Hệ thống đang bận',
  'error.50001.msg': 'Vui lòng thử lại sau',
  'error.60001.title': 'Đã đạt đến giới hạn gửi SMS hôm nay',
  'error.60001.msg': 'Giới hạn trên là 3 lần mỗi ngày',
};

const welcome: Ii18n = {
  'welcome.availableServeTypes.DINE_IN': 'dụng nội bộ',
  'welcome.availableServeTypes.TAKE_OUT': 'Mang trở lại',
  'welcome.availableServeTypes.DELIVERY': 'Giao hàng',
  'welcome.availableTakeOutServeTypes.DEFAULT':
    'Vui lòng chọn phương thức mang đi (yêu cầu)',
  'welcome.availableTakeOutServeTypes.DEFAULT.warn':
    'Vui lòng chọn phương thức mang đi',
  'welcome.availableTakeOutServeTypes.TAKE_OUT': 'Ăn càng sớm càng tốt',
  'welcome.availableTakeOutServeTypes.SCHEDULE_TAKE_OUT': 'đặt trước',
  'welcome.tel': 'Vui lòng nhập điện thoại di động',
  'welcome.selectedDate': 'Vui lòng chọn một ngày',
  'welcome.date': 'Ngày: {date}',
  'welcome.selectedTime': 'Vui lòng chọn thời gian',
  'welcome.time': 'Thời gian: {time}',
  'welcome.setParty': 'Vui lòng chọn số lượng người',
  'welcome.setPartyTitle': 'Vui lòng chọn số lượng người ăn',
  'welcome.skipParty': 'Cộng điểm trực tiếp',
  'welcome.newOrder': 'Đơn hàng mới',
  'welcome.newPickUpOrder': 'Đơn hàng mới',
  'welcome.existingOrder': 'Trật tự hiện có',
  'welcome.name': 'Tên',
  'welcome.address': 'Địa chỉ nhà (Yêu cầu)',
  'welcome.phone': 'Điện thoại',
  'welcome.name.required': 'Name(required)',
  'welcome.tel.required': 'Phone(required)',
  'welcome.comment': 'Nhận xét (Tùy chọn)',
  'welcome.email': 'Email',
  'welcome.supplier': 'Nhà cung cấp',
  'welcome.privacy': 'Sự riêng tư',
  'welcome.confirm': 'Xác nhận',
  'welcome.add': 'cộng',
  'welcome.postpay': 'Vui lòng thanh toán tại kiosk sau khi ăn xong, cảm ơn!',
  'welcome.royality':
    'Vui lòng điền vào điện thoại di động để có điểm thành viên!',
  'welcome.machine.offline': 'Ngừng phục vụ',
  'welcome.machine.offline.text':
    'Hệ thống tạm thời bị đóng. Chúng tôi xin lỗi vì bất kỳ sự bất tiện gây ra. Mọi thắc mắc vui lòng gọi cho cửa hàng.',
  'welcome.search.order': 'Theo dõi đơn hàng',
  'welcome.search.order.and.redeem': 'Yêu cầu đặt hàng / điểm',
  'welcome.timeremind':
    'The estimated time for picking up the meal will be adjusted according to the situation of the store. Please confirm again after the order is submitted, and keep the phone open to avoid communication delays, thank you.',
  'welcome.timeremind.onlineTime.title': "It's not opening time",
  'welcome.pickup.timeremind.onlineTime':
    'The opening time is from {startTime} to {endTime}.',
  'welcome.availableTakeOutServeTimes':
    'Hiện tại là ngoài giờ làm việc, thời gian mở đặt chỗ trực tuyến là {startTime} ~ {endTime}, thời gian nhận hàng là {timePeriods}, nếu có nhu cầu đặt món ăn nhiều vui lòng liên hệ cửa hàng để xác nhận sự có sẵn.',
  'welcome.pickup.timeremind.onlineTime.title': "It's not opening time",
  'welcome.pickup.timeremind.pickupTime':
    'The latest pickup time is {pickupEndTime}',
  'welcome.timeremind.delivery.notice':
    'Delivery Time Might be different Due To Traffic ',
  'welcome.viewMenu': 'xem thực đơn',
  'welcome.store.not.open':
    'Dịch vụ đặt hàng của {serveType} hiện không khả dụng trong giờ làm việc ngoài cửa hàng.',
  'welcome.business.time': 'cửa hàng giờ làm việc {startTime} ～ {endTime}',
  'welcome.button.bussiness.time': 'giờ kinh doanh : {startTime} ～ {endTime}',
  'welcome.phone.royaltyEnable.reminder':
    'Vui lòng nhập số điện thoại di động của bạn để tích lũy điểm. Đơn hàng này có thể tích lũy điểm',
  'welcome.machine.offline.msg':
    'Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với cửa hàng',
};

const searchOrder: Ii18n = {
  'searchOrder.title': 'Hồ sơ đặt hàng',
  'searchOrder.tel': 'Vui lòng nhập số điện thoại',
  'searchOrder.availableServeTypes.DINE_IN': 'dụng nội bộ',
  'searchOrder.availableServeTypes.TAKE_OUT': 'Mang trở lại',
  'searchOrder.availableServeTypes.DELIVERY': 'Giao hàng',
  'searchOrder.availableServeTypes.PICK_UP': 'Cubbites',
  'searchOrder.search': 'kiểm',
  'searchOrder.myOrder': 'Đơn hàng của tôi',
  'searchOrder.refresh': 'tái tổ chức',
  'searchOrder.emptyOrder': 'Không có hồ sơ đặt hàng trong 2 ngày',
  'searchOrder.watingPay': 'Trong khi chờ thanh toán',
  'searchOrder.watingPay.comment.PRINT_AFTER_ORDER':
    'Thanh toán tại cửa hàng hoặc thanh toán trực tuyến ngay lập tức và chọn bữa ăn của bạn',
  'searchOrder.watingPay.comment.PRINT_AFTER_PAY':
    'Cửa hàng sẽ không bắt đầu làm bữa ăn cho đến khi hoàn tất thanh toán tại cửa hàng.',
  'searchOrder.orderCode': 'số thứ tự',
  'searchOrder.preOrderTime': 'Thời gian nhận hàng ước tính',
  'searchOrder.createOrderTime': 'thời gian tạo đơn hàng',
  'searchOrder.takeOrderMethod': 'Phương pháp đón bữa ăn',
  'searchOrder.payTotal': 'Số tiền thanh toán',
  'searchOrder.itemTotal': 'Tổng số {Items} mặt hàng',
  'searchOrder.gotoPay': 'trả',
  'searchOrder.totalMeal': 'bữa ăn {bữa}',
  'searchOrder.order.cancel': 'Đã hủy',
  'searchOrder.order.cancel.comment': 'This order has been canceled',
  'searchOrder.order.complete': 'hoàn thành',
  'searchOrder.back.to.previous.page': 'go back to previous page',
};

const redeemPoint: Ii18n = {
  'redeemPoint.title': 'điểm của tôi',
  'redeemPoint.point': 'điểm',
  'redeemPoint.info.cutstomer.points': 'Các điểm hiện có sẵn',
  'redeemPoint.info.current.redeem.points': 'Sàn giao dịch này sử dụng',
  'redeemPoint.redeem.confirm': 'trao đổi',
  'redeemPoint.empty.redeem.item':
    'Hiện tại không có mặt hàng nào có thể đổi được',
  'redeemPoint.insufficient.points': 'Không đủ điểm khả dụng',
  'redeemPoint.Inventory.limit.reached':
    'Đã đạt đến giới hạn khoảng không quảng cáo',
  'redeemPoint.cost.money.not.enough': 'cost money not enough',
  'redeemPoint.out.of.stack': 'Exchange quantity exceeds stock',
  'redeemPoint.memberPoints': 'Điểm thành viên',
  'redeemPoint.searchPoints': 'Điểm truy vấn',
  'redeemPoint.earnPoints': 'Lấy hồ sơ',
  'redeemPoint.usedPoints': 'Hồ sơ sử dụng',
  'redeemPoint.thirdRedeemPlatform.msg':
    'Vui lòng sử dụng ứng dụng {platform} để kiểm tra hồ sơ điểm',
  'redeemPoint.empty.point.record': 'Chưa có bản ghi điểm nào',
  'redeemPoint.recent.ba.month.record': 'RedeemPoint.recent.ba.month.record',
  'redeemPoint.type.default.portal.change': 'Đổi điểm',
  'redeemPoint.type.earn': 'điểm tập hợp',
  'redeemPoint.type.rollback': 'Trả lại điểm',
  'redeemPoint.type.redeem': 'đổi',
  'redeemPoint.type.refund': 'Đổi điểm',
};

const confirmModal: Ii18n = {
  'confirmModal.title.insert.otp': 'nhập mã xác nhận',
  'confirmModal.otp.send': 'Mã xác minh đã được gửi đến điện thoại của bạn:',
  'confirmModal.insert.otp': 'Vui lòng nhập mã xác minh 4 chữ số',
  'confirmModal.NOT.received.otp': 'Không nhận được mã xác minh?',
  'confirmModal.resend.otp': 'Gửi lại',
  'confirmModal.title.confirm.redeem': 'Xác nhận trao đổi',
  'confirmModal.current.redeem.points': 'Điểm được sử dụng để trao đổi này:',
  'confirmModal.confirm.sure.msg': 'Bạn có xác nhận việc trao đổi không: ',
  'confirmModal.title.redeem.success': 'Trao đổi thành công',
  'confirmModal.success.msg':
    'Mặt hàng đã được đổi thành công và mặt hàng đã được thêm vào giỏ hàng của bạn.',
  'confirmModal.point': 'điểm',
  'confirmModal.cancel': 'Hủy bỏ',
  'confirmModal.next': 'Bước tiếp theo',
  'confirmModal.confirm': 'Xác nhận trao đổi',
  'confirmModal.view.cart': 'kiểm tra giỏ hàng',
  'confirmModal.continued.order': 'tiếp tục đặt hàng',
  'confirmModal.line.otp.send':
    'Thông báo LINE đã được gửi, vui lòng kiểm tra điện thoại của bạn và nhập mã xác minh:',
  'confirmModal.line.otp.bind.agree': 'đồng ý',
  'confirmModal.line.otp.bind.disagree': 'không đồng ý',
  'confirmModal.title.redeem.line.bind':
    'Xác nhận ràng buộc điện thoại di động',
  'confirmModal.success.line.bind.msg':
    'Bạn có đồng ý ràng buộc số điện thoại di động này với cửa hàng LINE@ không? Trong tương lai, việc đổi đơn hàng từ LINE@ sẽ không yêu cầu nhập mã xác minh hoặc gửi thông báo LINE.',
};

const stores: Ii18n = {
  'stores.select': 'Vui lòng chọn một cửa hàng',
  'store.phone': 'Điện thoại hỗ trợ {phone}',
  'store.email': 'Email hỗ trợ {email}',
};

const tableinfo: Ii18n = {
  'tableinfo.serveType.DINE_IN': 'dụng nội bộ',
  'tableinfo.serveType.TAKE_OUT': 'Mang trở lại',
  'tableinfo.serveType.SCHEDULE_TAKE_OUT': 'Đặt chỗ để mang về',
  'tableinfo.serveType.DELIVERY': 'Giao hàng',
  'tableinfo.serveType.PICK_UP': 'Pick up',
  'tableinfo.serveType.time.DINE_IN': 'dụng nội bộ {time}',
  'tableinfo.serveType.time.TAKE_OUT': 'Mang trở lại {time} Đón',
  'tableinfo.serveType.time.DELIVERY': 'Giao hàng {time} Giao',
  'tableinfo.serveType.time.PICK_UP': 'To Go {time} Pickup',
  'tableinfo.party': 'số người {party}',
  'tableinfo.seats': 'Ghế ngồi {seat}',
  'tableinfo.seats.title': 'Số bàn',
  'tableinfo.serveType.time': 'Dùng bữa',
  'tableinfo.address': 'địa chỉ',
  'tableinfo.takeSeat.time': 'Giờ ngồi',
  'tableinfo.seatOverTimeMins.title': 'Giới hạn thời gian bữa ăn',
  'tableinfo.seatOverTimeMins.mins': 'phút',
  'tableinfo.table.members': 'Số người dùng bữa',
  'tableinfo.table.members.shareMealDetail': '{count} {title}',
};

const attribute: Ii18n = {
  'attribute.require': 'Yêu cầu {number}',
  'attribute.atLeast': 'Ít nhất {number}',
  'attribute.atMost': 'Nhất {number}',
  'attribute.optionItem': 'Không bắt buộc {number} mặt hàng',
  'attribute.requireItem': 'Yêu cầu {number} mặt hàng',
  'attribute.back': 'Mặt sau',
  'attribute.addtoCart': 'Thêm vào giỏ hàng',
  'attribute.add': 'Thêm các mục',
  'attribute.edit': 'Chỉnh sửa',
  'attribute.unsatisfy': 'Vui lòng chọn các mục',
  'attribute.comment': 'Bình luận',
  'attribute.comment.placeholder': 'Bình luận (Optional)',
};

const common: Ii18n = {
  'common.remain.raw.material':
    'Không đủ nguyên liệu, bạn muốn đặt hàng vui lòng gọi cho cửa hàng',
  'common.remain': 'remaining : {remain}',
  'common.soldout': 'Bán hết',
  'common.confirm': 'Xác nhận',
  'common.back': 'Mặt sau',
  'common.continueOrder': 'Tiếp tục đặt hàng',
  'common.editItems': 'chỉnh sửa mục',
  'common.placeOrder': 'Đặt hàng {price}',
  'common.shareMeal': 'Thêm phí bữa ăn chung {price}',
  'common.place.order': 'Đặt hàng',
  'common.order.placed': 'Đa đặt hang',
  'common.order.paid': 'Đặt hàng có trả tiền',
  'common.order.cancel': 'Đơn hàng được hoàn lại hoặc bị hủy',
  'common.yes': 'Đúng',
  'common.no': 'Không',
  'common.cancel': 'Hủy bỏ',
  'common.copylink': 'Sao chép URL',
  'common.homePage': 'trang đầu',
  'common.store.name': 'Tên doanh nghiệp:',
  'common.store.identifier': 'Số đồng phục:',
  'common.shareMeal.modal.header.title': 'Hãy chọn số lượng người ăn',
  'common.shareMeal.modal.confirm': 'Xác nhận',
  'common.shareMeal.title': '※ Hướng dẫn bữa ăn chung',
  'common.shareMeal.msg':
    'Mỗi {personCount} người có thể chia sẻ một nồi, nếu không đạt mức tiêu thụ tối thiểu, sẽ bị tính phí',
};

const cart: Ii18n = {
  'cart.delivery.address': 'Delivery address',
  'cart.view': 'Xem giỏ hàng',
  'cart.address': 'address：',
  'cart.title': 'Chi tiết đơn hàng',
  'cart.count': 'Bạn có {count} vật phẩm',
  'cart.order': 'Tôi muốn đặt hàng',
  'cart.place.order': 'Đặt hàng',
  'cart.confirm.order': 'Xác nhận đơn hàng',
  'cart.discount': 'Sử dụng chiết khấu',
  'cart.editTime.DINE_IN': 'dụng nội bộ',
  'cart.editTime.TAKE_OUT': 'Thời gian nhận hàng',
  'cart.editTime.DELIVERY': 'Thời gian giao hàng',
  'cart.editTime.PICK_UP': 'Thời gian giao hàng',
  'cart.customerInfo': 'thông tin khách hàng',
  'cart.comment': 'Bình luận: {comment}',
  'cart.memberpoint': 'Nhận điểm',
  'cart.ask.memberpoint': 'Bạn có muốn tích lũy điểm không',
  'cart.memberpoint.instruction':
    'Vui lòng đặt hàng lại sau khi nhập số điện thoại của bạn.',
  'cart.memberpoint.event': 'Rewards Program.',
  'cart.memberpoint.event.PRICE_POINT':
    'Now enter your mobile phone number, and you can accumulate {point} points when you spend over {price}.',
  'cart.memberpoint.earn.point': 'Earn Points',
  'cart.memberpoint.point.redemption': 'Point Redemption',
  'cart.memberpoint.earn.point.by.phone': 'Accumulate point with phone',
  'cart.memberpoint.phone.correct':
    'Successfully entered, points will be deposited into this mobile number after checkout is completed.',
  'cart.memberpoint.consumption':
    'The total consumption of this time is {total},',
  'cart.memberpoint.can.earn.point': 'Can accumulate {rulePoint} points.',
  'cart.not.in.businees.hour': 'This store is not opened now',
  'cart.customerInfo.name': 'Tên đơn hàng (tùy chọn)',
  'cart.customerInfo.name.required': 'Tên đơn hàng (bắt buộc)',
  'cart.customerInfo.name.error':
    'Không được để trống trường tên, vui lòng nhập tên người đặt hàng',
  'cart.customerInfo.phone': 'Vui lòng nhập số điện thoại di động (tùy chọn)',
  'cart.customerInfo.phone.required':
    'Vui lòng nhập số điện thoại di động (bắt buộc)',
  'cart.customerInfo.phone.empty.error':
    'Không được để trống trường điện thoại, vui lòng nhập số điện thoại',
  'cart.customerInfo.phone.error': 'Vui lòng nhập số điện thoại di động hợp lệ',
  'cart.customerInfo.phone.royaltyEnable.reminder':
    'Vui lòng nhập số điện thoại của bạn để tích điểm, đơn hàng này có thể tích {points} điểm',
  'cart.customerInfo.phone.royaltyEnable.valid':
    'Điểm hoàn thành thanh toán sẽ tự động được gửi vào số điện thoại này',
  'cart.customerInfo.order.comment': 'Nhận xét đơn hàng (tùy chọn)',
  'cart.card.cartOrder.title': 'Nội dung đơn hàng',
  'cart.card.cartOrder.items.total.forward': 'tổng',
  'cart.card.cartOrder.items.total.backward': 'các mặt hàng',
  'cart.card.cartOrder.check.order.item.detail': 'Kiểm tra chi tiết',
  'cart.card.cartOrder.order.remove': 'Xóa',
  'cart.card.cartOrder.footer.remind':
    'Nhấp vào mục bữa ăn để chỉnh sửa nội dung bữa ăn. ',
  'cart.card.cartOrder.footer.add.items': 'Thêm bữa ăn',
  'cart.card.cartOrderItemModal.comment': 'Nhận xét',
  'cart.card.cartOrderItemModal.updated.item': 'Mặt hàng được cập nhật',
  'cart.card.takeOutDeliveryInfo.title': 'Thông tin bữa ăn',
  'cart.card.takeOutDeliveryInfo.delivery.address': 'địa chỉ giao hàng',
  'cart.card.discount.title': 'Sử dụng chiết khấu',
  'cart.card.discount.coupon.title': 'Phiếu giảm giá thú vị mới',
  'cart.card.discount.redeem.title': 'Yêu cầu/Đổi điểm',
  'cart.card.discount.item.discount.title': 'Giảm giá đã sử dụng:',
  'cart.card.discount.item.coupon.title': 'Phiếu giảm giá đã sử dụng:',
  'cart.card.discount.item.redeem.title': 'Điểm đã sử dụng:',
  'cart.card.discount.item.remove': 'Xóa',
  'cart.card.detail.title': 'Chi tiết thanh toán',
  'cart.card.detail.itemTotal.title': 'Số lượng sản phẩm',
  'cart.card.detail.coupon.title': 'Phiếu giảm giá',
  'cart.card.detail.redeem.title': 'Điểm giảm giá',
  'cart.card.detail.discount.title': 'Số tiền chiết khấu',
  'cart.card.detail.shareMealFee.title': 'Chia sẻ MealFee',
  'cart.card.detail.serviceFee.title': 'Phí dịch vụ',
  'cart.card.detail.serviceFeeMinConsumption.title':
    'Mức tiêu thụ thấp khi giao hàng',
  'cart.card.detail.total.title': 'Tổng số tiền',
  'cart.card.detail.error.serviceFeeMinConsumption':
    '＊Bạn cần chi tiêu {key} nhân dân tệ để được giao hàng miễn phí và bạn cần trả thêm phí giao hàng {value} nhân dân tệ nếu bạn không chi tiêu số tiền tối thiểu',
  'cart.card.detail.accumulated.points': 'Điểm tích lũy',
  'cart.card.cartOrderItemModal.error.lacing.short':
    'Không đủ số mục {itemTitle}: ',
  'cart.card.cartOrderItemModal.error.lacing':
    'Số lượng mặt hàng ít hơn {itemTitle} còn lại: {findWebItemLimitQuantity} đơn vị',
  'cart.card.detail.error.minPrice':
    '＊Số tiền đặt hàng tối thiểu là {điều kiện} $, vui lòng nhấp vào Thêm bữa ăn để tiếp tục đặt hàng',
  'cart.card.detail.error.shareMeal':
    '＊Việc mua thêm các mặt hàng khác không thể được sử dụng cho phí co-cup và hiện tại chưa đáp ứng số lượng nồi {minMainMealTarget} tối thiểu',
  'cart.card.detail.error.maxQuantity.byOrder':
    '＊Đơn đặt hàng được giới hạn ở $ {maxQuantity} bản sao, vui lòng chỉnh sửa lại số lượng mặt hàng đặt hàng',
  'cart.card.detail.error.maxQuantity.byItem':
    '＊Vượt quá giới hạn đơn hàng, vui lòng điều chỉnh số lượng của các mặt hàng sau: \n $ {displayNames}/limit $ {maxQuantity} chiếc',
  'cart.askShareMealModal.title': 'Bạn có muốn chia sẻ pot không? ',
  'cart.askShareMealModal.msg': 'Đã tính phí bữa ăn chung $ {shareMealFee}',
  'cart.askShareMealModal.reminder.title': '※Mô tả mức tiêu thụ',
  'cart.askShareMealModal.reminder.detail':
    'Mỗi người {mealShortage} có thể chia sẻ một nồi và {shareMealCondictionMsg} có thể bù đắp phí chia sẻ bữa ăn bằng cách mua các món khác. Hiện tại, số lượng chia sẻ bữa ăn tối thiểu không được đáp ứng. Nếu không mua thêm bữa ăn nào nữa, bạn sẽ phải trả thêm ${ shareMealFee} sẽ bị tính phí.',
  'cart.askShareMealModal.reminder.detail.getShareMealCondictionMsg':
    '{title} yêu cầu khoản phí bổ sung là $ {price} nhân dân tệ',
  'cart.askShareMealModal.palceOrder': 'Tiếp tục cộng điểm',
  'cart.askShareMealModal.shareMeal': 'Tôi muốn chia sẻ nồi',
  'cart.payment.store.reminder':
    '*Cửa hàng sẽ bắt đầu phục vụ bữa ăn sau khi hoàn tất việc thanh toán và thanh toán tại cửa hàng',
  'cart.payment.alert.msg':
    '*Sau khi chọn phương thức thanh toán, bạn cần nhấp vào nút "Xác nhận đơn hàng" hoặc "Tiến hành thanh toán" bên dưới, đơn hàng sẽ được thiết lập và gửi',
  'cart.payment.stroe.only.alert.msg':
    ' *Sau khi chọn phương thức thanh toán, bạn cần nhấp vào nút "Xác nhận đơn hàng" bên dưới để hoàn tất đơn hàng và gửi đi.',
  'cart.payment.online.only.alert.msg':
    ' *Sau khi chọn phương thức thanh toán, bạn cần nhấp vào nút "Chuyển đến thanh toán" bên dưới để hoàn tất đơn hàng và gửi đi.',
  'cart.store.name': 'Cửa hàng thanh toán: {storeName}',
};

const promotion: Ii18n = {
  'promotion.myDiscount': 'Giảm giá của tôi',
  'promotion.useDiscount': 'Sử dụng chiết khấu',
  'promotion.redeem': 'Đổi điểm',
  'promotion.available.point': 'điểm hiện tại: {point}',
  'promotion.coupon': 'Coupon',
  'promotion.search': 'kiểm',
  'promotion.menu.myDiscount': 'Giảm giá của tôi/điểm',
  'promotion.menu.useDiscount': 'Sử dụng chiết khấu/điểm',
};
const redeem: Ii18n = {
  'redeem.title': 'Vui lòng chọn giảm giá',
  'redeem.point': '{point} miễn giảm',
};
const otp: Ii18n = {
  'otp.title': 'OTP',
  'otp.placeholder': 'Vui lòng nhập mã OTP',
};
const coupon: Ii18n = {
  'coupon.title': 'Sử dụng Coupon',
  'coupon.camera': 'Quét Coupon',
  'coupon.picture': 'Lựa chọn Coupon Từ tệp',
  'coupon.serial': 'đi vào Coupon Mã số',
  'coupon.v2.modal.header.title': 'Phiếu giảm giá mới thú vị',
  'coupon.v2.modal.footer.cta': 'Xác nhận sử dụng',
  'coupon.v2.scan.title': 'Quét phiếu giảm giá',
  'coupon.v2.couponbarcode.title': 'Mã giảm giá',
  'coupon.v2.máy ảnh': 'Mã quét máy ảnh',
  'coupon.v2.sucess.msg':
    '*Các phiếu giảm giá hấp dẫn đã được đổi thành công và các khoản giảm giá mới đã được thêm vào',
  'coupon.v2.modal.body.input.placeholder': 'Nhập mã giảm giá',
  'coupon.v2.error.input.empty': 'Vui lòng nhập mã giảm giá',
};
const scancoupon: Ii18n = {
  'scancoupon.title': 'Quét Coupon',
  'scancoupon.notice': 'Vui lòng quét QRCode',
};
const recommendation: Ii18n = {
  'recommendation.title': 'giới thiệu cho bạn',
  'recommendation.nextTime': 'Lần sau, cảm ơn',
  'recommendation.confirm': 'Xác nhận',
};
const rulealert: Ii18n = {
  'rulealert.title': 'Để ý',
  'rulealert.title.quantity.limit': 'vượt quá giới hạn đặt hàng',
  'rulealert.body.edit.item': 'Vui lòng điều chỉnh số lượng của các mục sau：',
  'rulealert.party': 'Số người ăn uống: {party}',
  'rulealert.sharemeal.message1': '{party} mọi người cần nồi {pot},',
  'rulealert.sharemeal.message2':
    'Hoặc ít nhất hãy đặt một đơn hàng {pot} nồi + {item} Điểm duy nhất',
  'rulealert.sharemeal.message3': 'để trừ phí co-pot',
  'rulealert.min.consumption.message1':
    'Số lượng đơn đặt hàng không đầy {condition} tăng {fee} giá trị của',
  'rulealert.min.consumption.message2': 'Số tiền đặt hàng phải đầy {condition}',
};
const postpay: Ii18n = {
  'postpay.title': 'Trả tiền',
  'postpay.qrcode': 'QRCode',
  'postpay.orderlist': 'Danh sách đặt hàng',
  'postpay.customId': 'số hoá đơn {customId}',
  'postpay.customId.v2': 'số hoá đơn',
  'postpay.qrcode.message1':
    'Sau bữa ăn, vui lòng đến máy tính tiền và "nhập số thanh toán"',
  'postpay.qrcode.message2': 'hoặc "Quét mã QR" để thanh toán',
  'postpay.qrcode.message3': '(Vui lòng tăng độ sáng màn hình trước khi quét)',
  'postpay.view.orderList': 'Xem hồ sơ đơn hàng',
  'postpay.goto.qrcode': 'Chuyển đến thanh toán',
  'trả sau.qrcode.message':
    'Sau khi dùng bữa xong quý khách vui lòng đến quầy và hiển thị màn hình này để thanh toán, hoặc quét mã QR Code này tại máy thanh toán để thanh toán. ',
  'trả sau..ex hiệnOrder.total': 'Tổng số tiền',
  'postpay.orderlist.title': 'Hồ sơ đơn hàng',
  'postpay.ex hiệnOrder.batch.title': '{times}đơn hàng',
};
const payment: Ii18n = {
  'payment.amount': 'Số tiền phải trả',
  'payment.view': 'lượt xem',
  'payment.confirm': 'xác nhận thanh toán',
  'payment.confirm.order': 'Xác nhận đơn hàng',
  'payment.free': 'Tạo đơn hàng',
  'payment.options': 'chọn thanh toán bằng tiền mặt',
  'payment.options.header': 'Payment Options',
  'payment.instore': 'Thanh toán tại cửa hàng',
  'payment.cash': 'Trả bằng tiền mặt',
  'payment.online': 'thanh toán trực tuyến',
  'payment.online.option': 'Vui lòng chọn phương thức thanh toán',
  'payment.LINEPAY': 'LINE Pay',
  'payment.JKOPAY': 'JKOPAY',
  'payment.APPLEPAY': 'Apple Pay',
  'payment.GOOGLEPAY': 'Google Pay',
  'payment.TAIWANPAY': 'Taiwan Pay',
  'payment.TAPPAY_DIRECT': 'thẻ tín dụng',
  'payment.CTBC': 'thẻ tín dụng',
  'payment.BBPOS': 'BBPOS',
  'payment.BBPOS.title': 'Điều khoản dịch vụ',
  'payment.PAYPAL': 'Paypal',
  'payment.invoice': 'Vui lòng chọn một phương thức hóa đơn',
  'payment.detail.title': 'Chi tiết bữa ăn',
  'payment.invoice.identifier':
    'Để xuất hóa đơn công ty, vui lòng nhập số hợp nhất',
  'payment.invoice.mobilecarrier': 'Vui lòng nhập số xe',
  'payment.invoice.mobilecarrier.save': 'Đặt làm phương tiện di động mặc định',
  'payment.invoice.npo': 'Vui lòng chọn một tổ chức',
  'payment.invoice.fill.npo': 'Vui lòng nhập mã tổ chức',
  'payment.invoice.PAPER': 'hóa đơn giấy',
  'payment.invoice.BUYER_IDENTIFIER': 'Tổng hợp thống nhất',
  'payment.invoice.MOBILE_CARRIER': 'phương tiện giao thông',
  'payment.invoice.NPO_CODE': 'hóa đơn quyên góp',
  'payment.invoice.EMAIL': 'Email',
  'payment.invoice.EMAIL_IDENTIFIER': 'Tổng hợp thống nhất + Email',
  'payment.npo.8585': '財團法人台灣兒童暨家庭扶助基金會',
  'payment.npo.7000': '財團法人台灣世界展望會',
  'payment.npo.9527': '財團法人罕見疾病基金會',
  'payment.npo.928': '財團法人宜蘭縣私立佛教幸夫社會福利慈善事業基金會',
  'payment.npo.8341': '社團法人中華民國銀髮慈善協會',
  'payment.npo.5252': '社團法人中華民國身心障礙聯盟',
  'payment.npo.4312': '社團法人中華親善動物保護協會',
  'payment.npo.OTHER': 'khác',
  'payment.submit': 'Thông tin thanh toán đang được tạo{br}Vui lòng chờ...',
  'payment.submit.success': 'Hồ sơ Thanh toán {br} đã được tạo',
  'payment.cardnumber': 'Số thẻ tín dụng',
  'payment.expiration.date': 'ngày hết hạn',
  'payment.card.csc': 'ba thước cuối cùng (CSC)',
  'payment.card.change': 'đổi thẻ tín dụng',
  'payment.card.remember':
    'Hãy nhớ số thẻ này cho lần thanh toán nhanh tiếp theo',
  'payment.card.remember.tip':
    'Ghi lại thông tin thanh toán thẻ tín dụng hiện tại và lần thanh toán tiếp theo chỉ cần ủy quyền để thanh toán nhanh chóng. Và có quyền sửa đổi và xóa.',
  'payment.expiration.date.placeholder': 'MM/YY',
  'payment.card.csc.placeholder': 'CSC',
  'payment.confirmation.window.title': 'Bạn sắp gửi một đơn đặt hàng',
  'payment.confirmation.window.info':
    'Sau khi đặt hàng, cửa hàng sẽ bắt tay vào chuẩn bị suất ăn cho quý khách ngay lập tức, quý khách vui lòng xác nhận thông tin đặt hàng sau：',
  'payment.confirmation.window.info.DINE_IN':
    'Cửa hàng sẽ bắt đầu thực hiện các bữa ăn sau khi thanh toán hoàn tất khi thanh toán tại cửa hàng. Vui lòng xác nhận thông tin đặt hàng sau:',
  'payment.confirmation.window.notice.Takeout':
    '※Thời gian chuẩn bị bữa ăn ít nhất là 15-20 phút, hãy kiên nhẫn. Để biết chính xác thời gian lấy hàng, vui lòng liên hệ cửa hàng qua điện thoại để xác nhận。',
  'payment.confirmation.window.notice.DINE_IN':
    '※ Sau khi đặt hàng, vui lòng thanh toán tại quầy trong cửa hàng hoặc tự thanh toán.',
  'payment.confirmation.window.notice.DELIVERY':
    '※Vui lòng hiểu rằng thời gian giao hàng sẽ bị kéo dài do vào giờ cao điểm.',
  'payment.confirmation.window.notice':
    '※ After confirming the delivery of the order, please go to the store to pay and pick up the food. If you want to modify or cancel your order, please contact the store. Please do not abandon the order without reason. ',
  'payment.confirmation.window.payTotal': 'Số tiền thanh toán：',
  'payment.confirmation.window.payMethod': 'phương thức thanh toán：',
  'payment.confirmation.window.takeOrderMethod': 'Phương pháp đón bữa ăn：',
  'payment.confirmation.window.orderTime': 'thời gian phục vụ：',
  'payment.confirmation.window.DELIVERY.address': 'địa điểm giao hàng：',
  'payment.confirmation.window.DINE_IN': 'dụng nội bộ',
  'payment.confirmation.window.TAKE_OUT': 'Mang trở lại',
  'payment.confirmation.window.DELIVERY': 'Giao hàng',
  'payment.confirmation.window.cancel': 'Hủy bỏ',
  'payment.confirmation.window.confirmOrder': 'gửi đơn đặt hàng',
  'payment.confirmation.window.serveTypes.TAKE_OUT': 'Ăn càng sớm càng tốt',
  'payment.confirmation.window.serveTypes.SCHEDULE_TAKE_OUT':
    'dự trữ {time} dùng bữa',
  'payment.confirmation.window.serveTypes.DELIVERY': 'dự trữ {time} Dịch vụ',
  'payment.confirmation.window.serveTypes.PRINT_AFTER_PAY.TAKEOUT':
    '※ Chỉ sau khi hoàn tất việc kiểm tra và thanh toán trong cửa hàng, cửa hàng mới bắt đầu chuẩn bị bữa ăn. Nếu không tiện chờ đợi tại chỗ, bạn nên chọn "Thanh toán trực tuyến" và nhận bữa ăn tại cửa hàng vào thời gian dự kiến ​​hoàn thành bữa ăn.',
  'payment.confirmation.window.serveTypes.PRINT_AFTER_PAY.DINE_IN':
    '※ Chỉ sau khi hoàn tất việc kiểm tra và thanh toán trong cửa hàng, cửa hàng mới bắt đầu chuẩn bị bữa ăn.',
  'payment.coupon.remind':
    'Please place an order again, or ask the service staff for assistance',
  'payment.submit.remind':
    'Sau khi chọn phương thức thanh toán, bạn cần nhấp vào nút 「{ctaString}」 bên dưới để hoàn thành đơn đặt hàng và gửi đi.',
  'payment.select.other.method': 'Vui lòng chọn phương thức thanh toán khác',
};

const detail: Ii18n = {
  'detail.order.status': 'Tình trạng đặt hàng',
  'detail.customid': 'số thứ tự',
  'detail.preorderTime': 'Hẹn gặp',
  'detail.printafterorder.preorder.desc':
    'Cửa hàng sẽ chuẩn bị bữa ăn cho quý khách vào giờ đón đã hẹn, quý khách vui lòng đến cửa hàng để thanh toán và lấy bữa',
  'detail.printafterpay.preorder.desc':
    'Vui lòng đến cửa hàng để thanh toán, đơn hàng sẽ bắt đầu được thực hiện sau khi thanh toán',
  'detail.pay.pending': 'Trong khi chờ thanh toán',
  'detail.pay.store': 'pay in store',
  'detail.pay.autocancel1':
    'Vui lòng thanh toán tại cửa hàng hoặc thanh toán trực tuyến trước',
  'detail.pay.autocancel2': '',
  'detail.qrcode': 'Nhấp vào đây để hiển thị QRCODE và quét để thanh toán',
  'detail.qrcode.pickup':
    'Press this button to show QRCODE, scan and take your meal.',
  'detail.qrcode.title': 'quét',
  'detail.qrcode.desc': 'QR CODE, vui lòng hướng vào máy quét để thanh toán',
  'detail.qrcode.pickup.desc':
    'Vui lòng hướng MÃ QR vào máy quét để chọn bữa ăn',
  'detail.cancel.order':
    '* Nếu bạn muốn sửa đổi nội dung đơn hàng hoặc hủy đơn hàng, vui lòng liên hệ với cửa hàng qua điện thoại',
  'detail.pay.other': 'Chọn một phương thức thanh toán khác',
  'detail.pay.online': 'thanh toán trực tuyến',
  'detail.order': 'Thông tin đặt hàng',
  'detail.order.payment': 'phương thức thanh toán',
  'detail.order.store.name': 'tên cửa hàng',
  'detail.order.dateTime': 'thời gian tạo đơn hàng',
  'detail.order.servetype': 'Phương pháp đón bữa ăn',
  'detail.order.seats': 'Ghế ngồi',
  'detail.order.customerName': 'Tên',
  'detail.order.mobile': 'điện thoại',
  'detail.order.address': 'Địa chỉ nhà',
  'detail.order.comment': 'Bình luận',
  'detail.order.pickup.time': 'Pickup Time',
  'detail.order.pickup.pincode': 'Cabinet Password: : ',
  'detail.order.pickup.address': 'Pickup Address',
  'detail.order.pickup.payment': 'Pickup Type',
  'detail.order.cancel': 'Cancel',
  'detail.order.cancel.ask': 'Agree to cancel order ?',
  'detail.order.cancel.already': 'Order is cancelled.',
  'detail.detail': 'Chi tiết bữa ăn',
  'detail.back.to.search.order': 'back to search order',
  'detail.welcome': 'đặt hàng lại',
  'detail.payment.CASH': 'tiền mặt',
  'detail.payment.LINEPAY': 'LINE Pay',
  'detail.payment.ApplePay': 'Apple Pay',
  'detail.payment.GooglePay': 'Google Pay',
  'detail.payment.TAPPAY': 'Tappay',
  'detail.payment.TAPPAY_DIRECT': 'thẻ tín dụng',
  'detail.payment.BBPOS_ONLINE': 'BBPOS',
  'detail.payment.JKOPAY': 'Jkopay',
  'detail.payment.PAYPAL': 'Paypal',
  'detail.payment.CTBC_ONLINE': 'CTBC Online',
  'detail.payment.TAIWANPAY': 'Taiwan Pay',
  'detail.takeout.preordertime': 'Hẹn gặp',
  'detail.takeout.only.message':
    'Đơn hàng của bạn đã được thanh toán thành công và bữa ăn đã được chuẩn bị cho bạn',
  'detail.takeout.message':
    'Đơn hàng của bạn đã được thanh toán thành công và bữa ăn sẽ sẵn sàng vào giờ đón đã định',
  'detail.delivery.preordertime': 'Thời gian giao hàng ước tính',
  'detail.delivery.message':
    'Đơn hàng của bạn đã được thanh toán thành công và bữa ăn sẽ được chuyển đến địa chỉ được chỉ định trong thời gian sớm nhất có thể sau khi bữa ăn đã sẵn sàng',
  'detail.delivery.message.location':
    'You have finished your payment., Your order will be delivered to specified location at delivery time',
  'detail.delivery.notice':
    '* Thời gian giao hàng sẽ được kéo dài do giờ cao điểm, xin vui lòng thứ lỗi cho tôi',
  'detail.cubbie.title': 'Thông tin tủ nhận hàng',
  'detail.cubbie.qrcode':
    'Nhấp vào đây để hiển thị QRCODE và quét để chọn bữa ăn',
  'detail.order.tag.cash': 'Thanh toán tại chỗ',
  'detail.order.tag.refund': 'Hoàn tiền đơn hàng',
  'detail.order.tag.cancel': 'Hủy đơn hàng',
  'detail.want.change.order':
    '* Nếu bạn muốn sửa đổi nội dung đơn hàng hoặc hủy đơn hàng, vui lòng liên hệ với cửa hàng qua điện thoại. ',
  'detail.cancelled.order':
    '* Cửa hàng đã hủy đơn hàng, nếu có thắc mắc vui lòng liên hệ với cửa hàng qua điện thoại. ',
  'detail.order.pay.message':
    'Đơn hàng của bạn đã được thanh toán thành công và bữa ăn đã được chuẩn bị cho bạn',
  'detail.order.cancelled.reminder.title': 'Đơn hàng đã bị hủy',
  'detail.order.cancelled.reminder.msg': 'Bắt ​​đầu lại đơn hàng mới',
  'detail.order.refund.reminder.title': 'Đơn hàng đã được hoàn tiền',
  'detail.order.reminder.TAKEOUT.preorderTime.msg':
    'Đơn đặt hàng của bạn đã được thanh toán thành công và bữa ăn sẽ được nhận vào thời gian đã đặt trước',
  'detail.order.reminder.TAKEOUT.preorderTime.msg.end': 'Hoàn tất chuẩn bị',
  'detail.order.reminder.DELIVERY.preorderTime.msg':
    'Đơn đặt hàng của bạn đã được thanh toán thành công và bữa ăn sẽ được giao vào thời gian giao hàng đã định',
  'detail.order.reminder.DELIVERY.cash.preorderTime.msg':
    'Đơn hàng của bạn đã được đặt thành công và bữa ăn sẽ được giao vào thời gian giao hàng đã định',
  'detail.order.reminder.DELIVERY.preorderTime.msg.end':
    'Giao hàng đến địa chỉ đã chỉ định sớm nhất có thể trước',
  'detail.order.reminder.DELIVERY.msg':
    '* Thời gian giao hàng sẽ bị kéo dài do giờ cao điểm, xin vui lòng tha thứ cho tôi',
  'detail.sharelMeal.addon.limit.time': 'Thêm liên kết có hiệu lực cho đến',
};

const orderdetail: Ii18n = {
  'orderdetail.itemcount': '{count} mục',
  'orderdetail.count': 'mục',
  'orderdetail.subtotal': 'Tổng phụ',
  'orderdetail.discount': 'Số tiền chiết khấu',
  'orderdetail.coupon': 'Coupon miễn giảm',
  'orderdetail.fee': 'Phí bổ sung',
  'orderdetail.shareMeal': 'Phí nồi chung',
  'orderdetail.total': 'toàn bộ',
  'orderdetail.count.total': 'toàn bộ:',
};

const bulletin: Ii18n = {
  'bulletin.title': 'sự thông báo',
  'bulletin.confirm': 'ĐƯỢC RỒI',
};

const tag: Ii18n = {
  'tag.SPECIAL_PRICE': 'đề nghị đặc biệt',
};

const notice: Ii18n = {
  'notice.title': 'nhắc lại',
  'notice.content':
    'Bạn sẽ tìm đến dịch vụ của bên thứ ba, vui lòng quay lại trang này để xác nhận tình trạng đơn hàng sau khi hoàn tất thanh toán, xin cảm ơn!',
  'notice.googlepay.1': 'Vui lòng bấm vào ',
  'notice.googlepay.2': 'Sao chép URL',
  'notice.googlepay.3':
    'Và dán URL trên trình duyệt Chrome để tiếp tục thanh toán.',
  'notice.copyurl.success': 'Đã sao chép thành công URL',
};

const cds: Ii18n = {
  'cds.store': 'store',
  'cds.orderNo': 'orderNo',
  'cds.lineIn': 'lineIn',
  'cds.status': 'status',
  'cds.cabinetNo': 'cabinetNo',
  'cds.QUEUEING': 'queueing',
  'cds.CABINET': 'available',
  'cds.CLOSED': 'done',
  'cds.CANCELLED': 'cancelled',
  'cds.EXPIRED': 'expired',
  'cds.WARNING': 'error',
  'cds.check.order.status': 'meal preparation status',
};

const changeStoreModal: Ii18n = {
  'changeStoreModal.cleanCart':
    'The cart will be clear if you leave this page.',
  'changeStoreModal.leave': 'Do you want to leave this page?',
};
const phison: Ii18n = {
  'phison.employee': 'Employee',
  'phison.guest': 'Guest',
  'phison.employee.input': 'Employee Id',
  'phison.employee.extNumber': 'ext.num (optional)',
  'phison.employee.id.empty': "Employee id can't not be emptied.",
  'phison.employee.id.error': 'Wrong employee id.',
};

const storeDetail: Ii18n = {
  'storeDetail.store': 'store info',
  'storeDetail.address': 'address',
  'storeDetail.map': 'navigation',
  'storeDetail.phone': 'phone',
  'storeDetail.call': 'call',
  'storeDetail.business.time': 'business hours',
  'storeDetail.business.in': 'open',
  'storeDetail.business.out': 'closed',
};

const pickUp: Ii18n = {
  'pick.up.prepare.message': 'Your order is preparing. Please wait patiently.',
  'pick.up.warning.message':
    'Your order is abnormal. Please ask the restaurant for help.',
  'pick.up.can.pickup.message':
    'Your order is prepared well. Please take your order by cabinet on your list below or scan the QR Code to see the details.',
  'pick.up.pickedup.message': 'Picked up completed. Thanks for your order！',
  'pick.up.expired.message':
    'Your order is expired. Please check detail of your order and contact our staff if you have any question.',
  'pick.up.status.no.pay': 'Outstanding',
  'pick.up.status.prepare': 'Is being held for pick up',
  'pick.up.status.on.shelf': ' Is ready for pick up',
  'pick.up.status.at.counter': 'At counter',
  'pick.up.status.pending.pull.off.shelf': 'Wait for take off the shelves',
  'pick.up.status.picked.up': 'Picked up',
  'pick.up.status.cancelled': 'Cancelled',
  'pick.up.status.refund': 'Refunded',
  'pick.up.status.warning': 'Warning',
  'pick.up.status.expired': 'Expired',
};

const locationSelector = {
  'location.selector.select.address': 'Please select loacation (required)',
  'location.selector.location': 'Location',
};

const delivery = {
  'uberDirect.error.invalid_params':
    'The parameters of your request were invalid.',
  'uberDirect.error.unknown_location':
    'The specified location was not understood.',
  'uberDirect.error.address_undeliverable':
    'The specified location is not in a deliverable area.',
  'uberDirect.error.address_undeliverable_limited_couriers':
    'The specified location is not in a deliverable area at this time because all couriers are currently busy.',
  'uberDirect.error.pickup_window_too_small':
    'The pickup window needs to be at least 10 minutes long.',
  'uberDirect.error.dropoff_deadline_too_early':
    'The dropoff deadline needs to be at least 20 minutes after the dropoff ready time.',
  'uberDirect.error.dropoff_deadline_before_pickup_deadline':
    'The dropoff deadline needs to be after the pickup deadline.',
  'uberDirect.error.dropoff_ready_after_pickup_deadline':
    'The dropoff ready time needs to be at or before the pickup deadline',
  'uberDirect.error.pickup_ready_too_early':
    'Thanh toán không thành công do thanh toán quá hạn và bữa ăn không thể được giao trong thời gian hẹn đã chỉ định. Vui lòng đặt hàng lại hoặc hỏi cửa hàng',
  'uberDirect.error.pickup_deadline_too_early':
    'The pickup deadline time needs to be at least 20 minutes from now.',
  'uberDirect.error.pickup_ready_too_late':
    'The pickup ready time needs to be within the next 30 days.',
  'uberDirect.error.customer_not_found': 'Customer does not exist.',
  'uberDirect.error.request_timeout': 'The request timed out…',
  'uberDirect.error.customer_suspended':
    'Your account is passed due. Payment is required.',
  'uberDirect.error.customer_blocked':
    'Your account is not allowed to create deliveries.',
  'uberDirect.error.customer_limited':
    'Your account’s limits have been exceeded.',
  'uberDirect.error.unknown_error': 'An unknown error happened.',
  'uberDirect.order.status.pending': 'Order Processing',
  'uberDirect.order.status.delivering': 'Order Delivery',
  'uberDirect.order.status.delivered': 'Delivery Complete',
  'uberDirect.order.delivered.time': 'Appointment delivery time',
  'delivery.please.input.delivery.address': 'Please enter delivery address',
  'uberDirect.deliveryMethod': 'Delivery Method',
  'uberDirect.deliverableAction.deliverable_action_meet_at_door':
    'meet at the door',
  'uberDirect.deliverableAction.deliverable_action_leave_at_door':
    'put the door',
  'delivery.please.input.address': 'please enter address',
  'delivery.please.input.address.warn': 'please enter address',
  'delivery.placeholder.address.msg': 'please enter address',
  'uberDirect.error.goto.welcome': 'Go To Welcome',
};

const member = {
  'member.userTerms.privacy': 'Đã đọc và đồng ý',
  'member.common.and': 'và',
  'member.userTerms.title': 'Điều khoản người dùng',
  'member.profile.gender.DEFAULT': 'Hãy chọn',
  'member.profile.gender.MALE': 'Nam',
  'member.profile.sex.Nữ': 'Nữ',
  'thành viên.profile.remind.msg':
    'Nếu bạn thay đổi số điện thoại di động, điểm thành viên sẽ được chuyển sang số điện thoại di động mới nhập và số điện thoại di động ban đầu sẽ hủy tư cách thành viên của bạn. ',
  'member.profile.name.warn': 'Định dạng tên đầu vào không chính xác',
  'member.profile.phone.empty': 'Yêu cầu số điện thoại di động',
  'member.profile.phone.format.warn':
    'Định dạng điện thoại di động đầu vào sai',
  'member.profile.phone.registered.warn':
    'Số điện thoại di động đã được đăng ký',
  'thành viên.profile.title.name': 'tên',
  'member.profile.title.phone': 'Điện thoại di động',
  'member.profile.title.birth': 'Sinh nhật',
  'member.profile.title.gender': 'Giới tính',
  'member.header.remove.account': 'Xóa tài khoản thành viên',
  'member.header.title.MemberRegister': 'Đăng ký',
  'member.header.title.MemberLogin':
    'Vui lòng nhập số điện thoại di động của bạn',
  'member.header.title.MemberOtpValidator': 'Vui lòng nhập mã xác minh SMS',
  'member.header.title.MemberProfile': 'Hồ sơ thành viên',
  'member.MemberRegister.submit': 'Đăng ký và xác minh SMS',
  'member.MemberOtpValidator.time.prefix': 'Xin vui lòng Yu',
  'member.MemberOtpValidator.time.concat': 'Đầu vào để xác minh',
  'member.MemberOtpValidator.otp.valid.error': 'Lỗi mã xác minh đầu vào',
  'member.MemberOtpValidator.is.lock.error':
    'Đã đạt đến giới hạn gửi tin nhắn văn bản ngày hôm nay',
  'member.common.btn.confirm': 'Xác nhận',
  'member.common.btn.send.otp': 'Gửi mã xác minh',
  'member.common.btn.save': 'Lưu',
  'member.common.btn.login': 'Đăng nhập',
  'member.modal.title.save.success': 'Lưu thành công',
  'member.modal.body.save.success':
    'Thông tin thành viên của bạn đã được thay đổi và lưu thành công. ',
  'member.modal.title.remove.account': 'Xóa tài khoản thành viên',
  'member.modal.body1.remove.account':
    'Xóa tài khoản thành viên sẽ ngăn bạn đăng nhập lại. ',
  'member.modal.body2.remove.account':
    'Bạn có chắc chắn muốn xóa tài khoản không? ',
  'member.modal.title.remove.account.success':
    'Xóa tài khoản thành viên thành công',
  'member.need-setting.reminder.msg':
    'Cập nhật ngay thông tin thành viên để dễ dàng nhận được thông tin ưu đãi. ',
  'member.need-setting.reminder.btn': 'Vào cài đặt thành viên',
  'member.reminder.msg': 'Trở thành thành viên và nhận ngay thông tin giảm giá',
  'member.reminder.btn': 'Tham gia thành viên',
  'member.reminder.short.btn': 'Tham gia thành viên',
};

const vi: Ii18n = {
  ...locationSelector,
  ...storeDetail,
  ...cds,
  ...phison,
  ...changeStoreModal,
  ...confirmModal,
  ...redeemPoint,
  ...searchOrder,
  ...error,
  ...welcome,
  ...stores,
  ...tableinfo,
  ...attribute,
  ...common,
  ...cart,
  ...promotion,
  ...redeem,
  ...otp,
  ...coupon,
  ...scancoupon,
  ...recommendation,
  ...rulealert,
  ...postpay,
  ...payment,
  ...detail,
  ...orderdetail,
  ...bulletin,
  ...tag,
  ...notice,
  ...pickUp,
  ...delivery,
  ...member,
};
export default vi;
