import OutlineButton from '@/ui/OutlineButton';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';

// 用來控制qrCode modal

function QrModalButton({ children }) {
  const qrcodeModalShow = () => {
    const modal = Modal.getOrCreateInstance(
      document.getElementById('qrcodeModal')
    );
    modal.show();
  };
  return <OutlineButton onClick={qrcodeModalShow}>{children}</OutlineButton>;
}

QrModalButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default QrModalButton;
