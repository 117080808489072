import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function DetailItem({ children, title }) {
  return (
    <div
      className="d-flex justify-content-between ms-4 me-4 border-bottom"
      style={{ minHeight: '50px' }}
    >
      <div
        className="align-self-center"
        style={{ fontSize: '14px', wordBreak: 'keep-all' }}
      >
        <FormattedMessage id={title} />
      </div>
      <div
        className="align-self-center text-end fw-bold"
        style={{ fontSize: '14px', maxWidth: '200px' }}
      >
        {children}
      </div>
    </div>
  );
}

DetailItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DetailItem;
