import { img } from '@/assets';
import { FormattedMessage } from 'react-intl';
import StoreUtils from '@/utils/storeInfoUtils';

type Props = {
  webStoreVo: any;
};
function TopStoreInfo({ webStoreVo }: Props) {
  const { webUiConfig, brandImageUrl, displayName, address, fb, line, phone } =
    webStoreVo;
  return (
    <div className="d-flex mt-2 mb-2 ms-3 me-3">
      <div
        className="d-flex flex-column justify-content-center align-self-center"
        style={{
          height: 'calc(210px*0.3)',
          width: 'calc(345px*0.3)',
          borderRadius: '5px',
        }}
      >
        <img
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          className="w-auto mx-auto d-block"
          src={brandImageUrl || img.icon.maIcon}
          alt=""
        />
      </div>
      <div className="align-self-center ms-1 me-1" style={{ height: '8vh' }} />
      <div className="align-self-center">
        <div className="fw-bold">{displayName}</div>
        {webStoreVo?.name && (
          <div className="fw-light" style={{ fontSize: '12px' }}>
            <FormattedMessage id="common.store.name" />
            {webStoreVo.name}
          </div>
        )}
        {StoreUtils.isStoreIdentifierValid(webStoreVo) && (
          <div className="fw-light" style={{ fontSize: '12px' }}>
            <FormattedMessage id="common.store.identifier" />
            {webStoreVo.identifier}
          </div>
        )}

        {webUiConfig.showStoreAddress && (
          <div className="fw-light" style={{ fontSize: '12px' }}>
            {address}
          </div>
        )}
        <div className="d-flex">
          {fb ? (
            <a href={fb}>
              <img style={{ maxHeight: '2vh' }} src={img.icon.fb} alt="" />
            </a>
          ) : (
            ''
          )}
          {fb ? (
            <div
              className="border-start align-self-center ms-2 me-2"
              style={{ height: '1vh' }}
            />
          ) : (
            ''
          )}
          {line ? (
            <a href={line}>
              <img style={{ maxHeight: '2vh' }} src={img.icon.line} alt="" />
            </a>
          ) : (
            ''
          )}
          {line ? (
            <div
              className="border-start align-self-center ms-2 me-2"
              style={{ height: '1vh' }}
            />
          ) : (
            ''
          )}
          {phone ? (
            <>
              <div>
                <img style={{ height: '2vh' }} src={img.icon.phone} alt="" />
              </div>
              <div>
                <a style={{ fontSize: '12px' }} href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

export default TopStoreInfo;
