import { Ii18n } from './type';

const error: Ii18n = {
  'error.title': '提示',
  'error.confirm': '我知道了',
  'error.noParty': '請選擇人數',
  'error.invalidMobile': '請輸入有效的手機號碼',
  'error.invalidName': '請輸入有效的姓名',
  'error.invalidDateTime': '請選擇有效日期時間',
  'error.invalidNameAddressFields': '請輸入有效的姓名與住址',
  'error.noRedeemItems': '目前沒有兌換項目',
  'error.network': '網路問題',
  'error.1': '系統異常',
  'error.10001': '訂單狀態異常',
  'error.10002': '訂單已結帳',
  'error.10003': '訂單結帳中, 無法加點, 謝謝',
  'error.10009': '訂單已進行拆單，無法加點品項',
  'error.10004': '品項不足 ',
  'error.100041': '餐點資訊異常，請通知店員協助處理，謝謝！[需要更新菜單]',
  'error.10005': '品項屬性異常, 請洽服務人員',
  'error.10006': '訂單建立失敗, 請洽服務人員',
  'error.10017': '桌位已併單，請重新掃描桌位 QR Code',
  'error.20001': '無發票號碼, 請洽服務人員',
  'error.20002': '訂單已結帳, 請洽服務人員',
  'error.20003': '查無訂單',
  'error.20004': '付款失敗, 請再試一次',
  'error.20005': '訂單建立失敗, 若您的卡被扣款, 請至商店進行退款',
  'error.30001': '座位訊息錯誤, 請找服務人員處理, 謝謝',
  'error.30002': '請找服務人員開桌',
  'error.30003': '桌號重複, 請選擇其他座位, 謝謝',
  'error.40001': '單號錯誤, 請找服務人員開桌, 謝謝',
  'error.40002': '商店訊息錯誤, 請找服務人員處理, 謝謝',
  'error.40003':
    '目前非營業時間, 請洽櫃檯人員協助 \n線上營業時間為 {startTime}-{endTime}',
  'error.40004': '查無此員工資料',
  'error.40005': '查無此會員資料',
  'error.40006': 'PARSE_SALES_REPORT_ERROR',
  'error.40007':
    '線上營業時間為\n {startTime}-{endTime} \n請於營業時間內下單, 謝謝!',
  'error.40008': '查無此會員手機,\n 請重新輸入會員手機',
  'error.40009': '目前智餐取取物櫃滿櫃,\n 請稍候下單, 謝謝!',
  'error.40011': '暫停服務',
  'error.40012': '點數不足,請移除兌換品項',
  'error.40013': '暫停服務',
  'error.50001': '系統忙碌中, 請稍後再試, 謝謝',
  'error.50003': '暫停服務',
  'error.60001': '當天最多只能傳3次OTP',
  'error.60002': '請輸入正確的OTP',
  'error.noPromotion': '請選擇一項優惠',
  'error.noRedeem': '請選擇一項折扣',
  'error.noOtp': '請輸入驗證碼',
  'error.camera.permission': '請允取系統存取相機權限, 然後再試一次',
  'error.coupon.not.exist': '酷碰券不存在',
  'error.coupon.used': '酷碰券已使用過',
  'error.coupon.time': '未符合酷碰卷使用時間',
  'error.coupon.store': '這張酷碰券不能在這間店使用',
  'error.coupon.server': '系統錯誤, 請再試一次',
  'error.qr.not.detected': '無法辨認 QR Code',
  'error.qr.empty': '請輸入酷碰券序號',
  'error.select.payment': '請選擇付款方式',
  'error.select.onlinepayment': '請選擇支付方式',
  'error.select.invoice': '請選擇發票方式',
  'error.select.npo': '請選擇機構',
  'error.fill.npo': '請輸入機構代碼',
  'error.identifier.406': '統編錯誤，要有 8 個數字',
  'error.identifier.403': '統編錯誤，要有 8 個 0-9 數字組合',
  'error.identifier.400': '統編錯誤',
  'error.identifier': '統編錯誤',
  'error.invoice.gov': '財政部系統回傳錯誤, 驗證失敗, 請選擇其他發票方式',
  'error.mobilecarrier': '請輸入載具號碼',
  'error.mobilecarrier.notvalid': '載具號碼格式錯誤',
  'error.mobilecarrier.server': '載具號碼格式錯誤',
  'error.npo.notvalid': '無此機構代碼',
  'error.payment.retry': '返回付款頁面',
  'error.cardnumber': '無效或系統不支援的卡號',
  'error.cardexpdate': '卡片已過期',
  'error.cardcsc': '安全碼輸入錯誤',
  'error.pay': '{pay} 付款失敗, 代碼 [{status}], 原因 [{msg}]',
  'error.preorderTime': '指定時間無法使用，請重新選擇',
  'error.payment': '付款失敗',
  'error.pickup.unavailable': '目前無法接收現場外帶訂單',
  'error.preorderTime.unavailable': '目前無法接收預約訂單',
  'error.order.full': '訂單已滿',
  'error.time.order.full': '您的預約取餐時段訂單已額滿, 請重新選擇取餐時間.',
  'error.order.before.mergeOrder': '桌位已併單，請重新掃描桌位 QR Code',
  'error.order.after.mergeOrder': '訂單變更為無效訂單，請重新掃描桌位 QR Code',
  'error.10019': '訂單重複，請重新掃碼點餐',
  'error.10020': '訂單無法繼續點餐，請重新掃碼點餐',
  'error.10003.title': '無法加點品項',
  'error.10003.msg': '訂單結帳中',
  'error.10009.title': '無法加點品項',
  'error.10009.msg': '訂單已進行拆單',
  'error.100041.title': '餐點資訊異常',
  'error.100041.msg': '請找服務人員更新菜單',
  'error.10005.title': '品項屬性異常',
  'error.10005.msg': '請洽服務人員',
  'error.10006.title': '訂單建立失敗',
  'error.10006.msg': '請洽服務人員',
  'error.10017.title': '桌位已併單',
  'error.10017.msg': '請重新掃描桌位 QR Code',
  'error.10019.title': '訂單重複',
  'error.10019.msg': '請重新掃碼點餐',
  'error.10020.title': '無法繼續點餐',
  'error.10020.msg': '請重新掃碼點餐',
  'error.20001.title': '無發票號碼',
  'error.20001.msg': '請洽服務人員',
  'error.20002.title': '訂單已結帳',
  'error.20002.msg': '請洽服務人員',
  'error.20004.title': '付款失敗',
  'error.20004.msg': '請再試一次',
  'error.20005.title': '訂單建立失敗',
  'error.20005.msg': '若您的卡被扣款，請至商店進行退款',
  'error.30001.title': '座位訊息錯誤',
  'error.30001.msg': '請洽服務人員',
  'error.30003.title': '桌號重複',
  'error.30003.msg': '請選擇其他座位',
  'error.40001.title': '單號錯誤',
  'error.40001.msg': '請找服務人員開桌',
  'error.40002.title': '商店訊息錯誤',
  'error.40002.msg': '請洽服務人員',
  'error.40003.title': '目前非營業時間',
  'error.40003.msg': '請洽服務人員協助 \n線上營業時間為 {startTime}-{endTime}',
  'error.40007.title': '目前非營業時間',
  'error.40007.msg':
    '線上營業時間為\n {startTime}-{endTime} \n請於營業時間內下單',
  'error.40008.title': '查無此會員手機',
  'error.40008.msg': '請重新輸入',
  'error.40009.title': '智餐取取物櫃滿櫃',
  'error.40009.msg': '請稍候再下單',
  'error.40012.title': '點數不足',
  'error.40012.msg': '請移除兌換品項',
  'error.50001.title': '系統忙碌中',
  'error.50001.msg': '請稍後再試',
  'error.60001.title': '今日簡訊發送已達上限',
  'error.60001.msg': '當日上限為3次',
};

const welcome: Ii18n = {
  'welcome.availableServeTypes.DINE_IN': '內用',
  'welcome.availableServeTypes.TAKE_OUT': '外帶自取',
  'welcome.availableServeTypes.DELIVERY': '外送',
  'welcome.availableTakeOutServeTypes.DEFAULT': '請選擇訂單時間(必選)',
  'welcome.availableTakeOutServeTypes.DEFAULT.warn': '請選擇訂單時間',
  'welcome.availableTakeOutServeTypes.TAKE_OUT': '儘快取餐',
  'welcome.availableTakeOutServeTypes.SCHEDULE_TAKE_OUT': '預約訂單',
  'welcome.tel': '請輸入手機號碼',
  'welcome.selectedDate': '請選擇日期',
  'welcome.date': '日期: {date}',
  'welcome.selectedTime': '請選擇時間',
  'welcome.time': '時間: {time}',
  'welcome.setParty': '請選擇人數',
  'welcome.setPartyTitle': '請選擇用餐人數',
  'welcome.skipParty': '直接加點',
  'welcome.newOrder': '開始點餐',
  'welcome.existingOrder': '既有訂單',
  'welcome.name': '姓名',
  'welcome.address': '送餐地址(必填)',
  'welcome.phone': '電話',
  'welcome.name.required': '訂購人姓名(必填)',
  'welcome.tel.required': '請輸入手機號碼(必填)',
  'welcome.comment': '備註(選填)',
  'welcome.email': 'Email',
  'welcome.supplier': '供應商',
  'welcome.privacy': '隱私權政策',
  'welcome.confirm': '確定',
  'welcome.add': '新增',
  'welcome.postpay': '用完餐後請到自助結帳機結帳, 謝謝!',
  'welcome.royality': '若需使用點數, 請輸入手機號碼!',
  'welcome.machine.offline': '暫停服務',
  'welcome.machine.offline.text':
    '系統暫停服務中，如造成您的不便敬請見諒。若有任何問題請致電洽詢店家聯繫。',
  'welcome.search.order': '訂單查詢',
  'welcome.search.order.and.redeem': '訂單查詢 / 點數',
  'welcome.timeremind':
    '取餐預估時間會依門市狀況調整，請在送單後再次確認，並保持電話暢通，避免溝通延誤，謝謝。',
  'welcome.timeremind.onlineTime.title': '非營業時間',
  'welcome.pickup.timeremind.onlineTime':
    '線上營業時間為 {startTime} - {endTime}',
  'welcome.availableTakeOutServeTimes':
    '目前為非營業時段，線上預約開放時間為{startTime} ～ {endTime}，取餐時間為{timePeriods}；若有大量訂餐需求，可先洽門市確認供貨情況。',
  'welcome.pickup.timeremind.onlineTime.title': '非營業時間',
  'welcome.pickup.timeremind.pickupTime': '每日最晚取貨時間為 {pickupEndTime}',
  'welcome.timeremind.delivery.notice': '外送時間會因尖峰時段延長, 請見諒',
  'welcome.viewMenu': '查看菜單',
  'welcome.store.not.open': '非店家營業時間，目前不開放{serveType}的點餐服務。',
  'welcome.business.time': '店家營業時間 {startTime} ～ {endTime}',
  'welcome.button.bussiness.time': '營業時間 : {startTime} ～ {endTime}',
  'welcome.phone.royaltyEnable.reminder':
    '集點請輸入手機號碼，此訂單可累積點數',
  'welcome.machine.offline.msg': '如有任何問題請向店家聯繫',
};

const searchOrder: Ii18n = {
  'searchOrder.title': '訂單查詢',
  'searchOrder.tel': '請輸入手機號碼',
  'searchOrder.availableServeTypes.DINE_IN': '內用',
  'searchOrder.availableServeTypes.TAKE_OUT': '外帶自取',
  'searchOrder.availableServeTypes.DELIVERY': '外送',
  'searchOrder.availableServeTypes.PICK_UP': '取物櫃',
  'searchOrder.search': '查詢',
  'searchOrder.myOrder': '我的訂單',
  'searchOrder.refresh': '重新整理',
  'searchOrder.emptyOrder': '2天內無任何訂單記錄',
  'searchOrder.watingPay': '待付款',
  'searchOrder.watingPay.comment.PRINT_AFTER_ORDER':
    '至店內結帳或立即線上付款完成並取餐。',
  'searchOrder.watingPay.comment.PRINT_AFTER_PAY':
    '需先至店內結帳付款完成後，店家才會開始製作餐點。',
  'searchOrder.orderCode': '訂單編號',
  'searchOrder.preOrderTime': '預計取餐時間',
  'searchOrder.createOrderTime': '預計取餐時間',
  'searchOrder.takeOrderMethod': '取餐方式',
  'searchOrder.payTotal': '付款金額',
  'searchOrder.itemTotal': '共 {Items} 項商品',
  'searchOrder.gotoPay': '去付款',
  'searchOrder.totalMeal': '{meal}份餐點',
  'searchOrder.order.cancel': '已取消',
  'searchOrder.order.cancel.comment': '訂單已被店家取消，請重新下單或詢問店家',
  'searchOrder.order.complete': '付款完成',
  'searchOrder.back.to.previous.page': '回上一頁',
};

const redeemPoint: Ii18n = {
  'redeemPoint.title': '我的點數',
  'redeemPoint.point': '點',
  'redeemPoint.info.cutstomer.points': '目前可用點數',
  'redeemPoint.info.current.redeem.points': '本次兌換使用',
  'redeemPoint.redeem.confirm': '進行兌換',
  'redeemPoint.empty.redeem.item': '目前沒有兌換項目',
  'redeemPoint.insufficient.points': '可使用點數不足',
  'redeemPoint.Inventory.limit.reached': '已達庫存數量上限',
  'redeemPoint.cost.money.not.enough': '已超過可折價金額',
  'redeemPoint.out.of.stack': '兌換數量超過庫存',
  'redeemPoint.memberPoints': '會員點數',
  'redeemPoint.searchPoints': '查詢點數',
  'redeemPoint.earnPoints': '獲得紀錄',
  'redeemPoint.usedPoints': '使用紀錄',
  'redeemPoint.thirdRedeemPlatform.msg': '請使用 {platform} APP 查詢點數紀錄',
  'redeemPoint.empty.point.record': '尚無點數紀錄',
  'redeemPoint.recent.three.month.record': '最近3個月紀錄',
  'redeemPoint.type.default.portal.change': '補登點數',
  'redeemPoint.type.earn': '集點',
  'redeemPoint.type.rollback': '返還兌點',
  'redeemPoint.type.redeem': '兌點',
  'redeemPoint.type.refund': '退集點',
};

const confirmModal: Ii18n = {
  'confirmModal.title.insert.otp': '輸入驗證碼',
  'confirmModal.otp.send': '驗證碼已發送至您的電話:',
  'confirmModal.insert.otp': '請輸入4位數驗證碼',
  'confirmModal.NOT.received.otp': '沒收到驗證碼?',
  'confirmModal.resend.otp': '重新發送',
  'confirmModal.title.confirm.redeem': '確認兌換',
  'confirmModal.current.redeem.points': '本次兌換使用點數：',
  'confirmModal.confirm.sure.msg': '您是否確認兌換: ',
  'confirmModal.title.redeem.success': '兌換成功',
  'confirmModal.success.msg': '成功兌換商品，已將商品加入您的購物車中。',
  'confirmModal.point': '點',
  'confirmModal.cancel': '取消',
  'confirmModal.next': '下一步',
  'confirmModal.confirm': '確認兌換',
  'confirmModal.view.cart': '查看購物車',
  'confirmModal.continued.order': '繼續點餐',
  'confirmModal.line.otp.send': '已傳送LINE通知，請查看手機並輸入驗證碼:',
  'confirmModal.line.otp.bind.agree': '同意',
  'confirmModal.line.otp.bind.disagree': '不同意',
  'confirmModal.title.redeem.line.bind': '確認綁定手機',
  'confirmModal.success.line.bind.msg':
    '是否同意店家 LINE@ 綁定此手機號碼？ 未來從 LINE@ 點餐兌換將不需要輸入驗證碼 或發送 LINE 通知。',
};

const stores: Ii18n = {
  'stores.select': '請選擇商店',
  'store.phone': '客服電話 {phone}',
  'store.email': '客服信箱 {email}',
};

const tableinfo: Ii18n = {
  'tableinfo.serveType.DINE_IN': '內用',
  'tableinfo.serveType.TAKE_OUT': '外帶',
  'tableinfo.serveType.SCHEDULE_TAKE_OUT': '預約外帶',
  'tableinfo.serveType.DELIVERY': '外送',
  'tableinfo.serveType.PICK_UP': '自取',
  'tableinfo.serveType.time.DINE_IN': '內用 {time}',
  'tableinfo.serveType.time.TAKE_OUT': '外帶 {time} 取餐',
  'tableinfo.serveType.time.DELIVERY': '外送 {time} 取餐',
  'tableinfo.serveType.time.PICK_UP': '自取 {time}',
  'tableinfo.party': '人數 {party}',
  'tableinfo.seats': '桌號 {seat}',
  'tableinfo.seats.title': '桌號',
  'tableinfo.serveType.time': '取餐',
  'tableinfo.address': '地址',
  'tableinfo.takeSeat.time': '入座時間',
  'tableinfo.seatOverTimeMins.title': '用餐時間限制',
  'tableinfo.seatOverTimeMins.mins': '分鐘',
  'tableinfo.table.members': '用餐人數',
  'tableinfo.table.members.shareMealDetail': '{count}位{title}',
};

const attribute: Ii18n = {
  'attribute.require': '必選 {number}',
  'attribute.atLeast': '至少選 {number}',
  'attribute.atMost': '最多選 {number}',
  'attribute.optionItem': '可選 {number} 項',
  'attribute.requireItem': '必選 {number} 項',
  'attribute.back': '返回',
  'attribute.addtoCart': '加入購物車',
  'attribute.add': '新增選項',
  'attribute.edit': '編輯選取項目',
  'attribute.unsatisfy': '尚有選項未選取',
  'attribute.comment': '新增備註',
  'attribute.comment.placeholder': '備註(選填)',
};

const common: Ii18n = {
  'common.remain.raw.material': '原料不足，欲下單請電話連絡門巿',
  'common.remain': '剩餘 : {remain}份',
  'common.soldout': '售完',
  'common.confirm': '確定',
  'common.back': '返回',
  'common.continueOrder': '繼續點餐',
  'common.editItems': '編輯品項',
  'common.placeOrder': '我要下單 {price}',
  'common.shareMeal': '我要共鍋 {price}',
  'common.place.order': '我要下單',
  'common.order.placed': '點餐完成',
  'common.order.paid': '付款完成',
  'common.order.cancel': '訂單取消或退款',
  'common.yes': '是',
  'common.no': '否',
  'common.cancel': '取消',
  'common.copylink': '複製連結',
  'common.homePage': '首頁',
  'common.searchOrder': '訂單查詢',
  'common.myPoints': '我的點數',
  'common.store.name': '營業人名稱：',
  'common.store.identifier': '統一編號：',
  'common.shareMeal.modal.header.title': '請選擇用餐人數',
  'common.shareMeal.modal.confirm': '確認',
  'common.shareMeal.title': '※ 共鍋說明',
  'common.shareMeal.msg': '每 {personCount} 人可共食一鍋, 未滿足最低消費會酌收',
  'common.member.login': '會員登入/註冊',
  'common.member.setting': '資料設定',
};

const cart: Ii18n = {
  'cart.delivery.address': '外送地點',
  'cart.view': '檢視購物車',
  'cart.address': '地點：',
  'cart.title': '購物車',
  'cart.count': '目前選購 {count} 項餐點',
  'cart.order': '前往結帳',
  'cart.place.order': '我要下單',
  'cart.confirm.order': '確認下單',
  'cart.discount': '使用優惠',
  'cart.editTime.DINE_IN': '內用',
  'cart.editTime.TAKE_OUT': '取餐時間',
  'cart.editTime.DELIVERY': '取餐時間',
  'cart.editTime.PICK_UP': '自取',
  'detail.order.cancel.ask': '確定取消訂單?',
  'detail.order.cancel.already': '訂單已取消',
  'cart.customerInfo': '訂購人資訊',
  'cart.comment': '備註: {comment}',
  'cart.memberpoint': '累積點數',
  'cart.ask.memberpoint': '是否累積點數',
  'cart.memberpoint.instruction': '請輸入電話號碼',
  'cart.memberpoint.event': '集點活動',
  'cart.memberpoint.event.PRICE_POINT':
    '現在輸入手機號碼，消費滿{price}元即可累積點數{point}點。',
  'cart.memberpoint.earn.point': '消費集點',
  'cart.memberpoint.point.redemption': '點數兌換',
  'cart.memberpoint.earn.point.by.phone': '輸入手機號碼累積消費點數',
  'cart.memberpoint.phone.correct':
    '已成功輸入，結帳完成後點數將存入此手機號碼。',
  'cart.memberpoint.consumption': '本次消費總計{total}元',
  'cart.memberpoint.can.earn.point': '可累積{rulePoint}點',
  'cart.not.in.businees.hour': '目前非營業時間',
  'cart.customerInfo.name': '訂購人姓名(選填)',
  'cart.customerInfo.name.required': '訂購人姓名(必填)',
  'cart.customerInfo.name.error': '姓名欄位不可為空值,請輸入訂購人姓名',
  'cart.customerInfo.phone': '請輸入手機號碼(選填)',
  'cart.customerInfo.phone.required': '請輸入手機號碼(必填)',
  'cart.customerInfo.phone.empty.error': '手機欄位不可為空值,請輸入手機號碼',
  'cart.customerInfo.phone.error': '請輸入有效的手機號碼',
  'cart.customerInfo.phone.royaltyEnable.reminder':
    '集點請輸入手機號碼，此訂單可累積 {points} 點',
  'cart.customerInfo.phone.royaltyEnable.valid':
    '結帳完成點數會自動存入此手機號碼',
  'cart.customerInfo.order.comment': '訂單備註(選填)',
  'cart.card.cartOrder.title': '訂單內容',
  'cart.card.cartOrder.items.total.forward': '共',
  'cart.card.cartOrder.items.total.backward': '項商品',
  'cart.card.cartOrder.check.order.item.detail': '查看詳細內容',
  'cart.card.cartOrder.order.remove': '移除',
  'cart.card.cartOrder.footer.remind': '點擊餐點項目，即可編輯餐點內容。',
  'cart.card.cartOrder.footer.add.items': '新增餐點',
  'cart.card.cartOrderItemModal.comment': '備註',
  'cart.card.cartOrderItemModal.updated.item': '更新商品',
  'cart.card.takeOutDeliveryInfo.title': '用餐資訊',
  'cart.card.takeOutDeliveryInfo.delivery.address': '外送地址',
  'cart.card.discount.title': '使用優惠折扣',
  'cart.card.discount.coupon.title': '新增酷碰券',
  'cart.card.discount.redeem.title': '查詢/兌換點數',
  'cart.card.discount.item.discount.title': '已使用優惠：',
  'cart.card.discount.item.coupon.title': '已使用酷碰：',
  'cart.card.discount.item.redeem.title': '已使用點數：',
  'cart.card.discount.item.remove': '移除',
  'cart.card.detail.title': '訂單明細',
  'cart.card.detail.itemTotal.title': '商品金額',
  'cart.card.detail.coupon.title': '酷碰折扣',
  'cart.card.detail.redeem.title': '點數折扣',
  'cart.card.detail.discount.title': '折扣金額',
  'cart.card.detail.shareMealFee.title': '共鍋費',
  'cart.card.detail.serviceFee.title': '服務費',
  'cart.card.detail.serviceFeeMinConsumption.title': '外送低消費',
  'cart.card.detail.total.title': '總金額',
  'cart.card.detail.error.serviceFeeMinConsumption':
    '＊外送低消需滿{key}元才免費外送, 未滿則須額外加收外送費{value}元',
  'cart.card.detail.accumulated.points': '消費累積點數',
  'cart.card.cartOrderItemModal.error.lacking.short':
    '品項數量不足 {itemTitle} : ',
  'cart.card.cartOrderItemModal.error.lacking':
    '品項數量不足 {itemTitle} 剩餘： {findWebItemLimitQuantity} 份',
  'cart.card.detail.error.minPrice':
    '＊訂單金額最低消費為 $ {condition}，請點擊新增餐點繼續點餐',
  'cart.card.detail.error.shareMeal':
    '＊加購其他品項不可抵共鍋費，目前未滿足共鍋 最低鍋數 {minMainMealTarget} 鍋',
  'cart.card.detail.error.maxQuantity.byOrder':
    '＊訂單下單限制為 $ {maxQuantity} 份，請重新編輯訂單品項數量',
  'cart.card.detail.error.maxQuantity.byItem':
    '＊超過訂單上限，請調整以下品項數量： \n $ {displayNames}/限制 $ {maxQuantity} 個',
  'cart.askShareMealModal.title': '是否要共鍋？',
  'cart.askShareMealModal.msg': '酌收共鍋費$ {shareMealFee}元',
  'cart.askShareMealModal.reminder.title': ' ※ 消費說明',
  'cart.askShareMealModal.reminder.detail':
    '每{mealShortage}人可共食一鍋，{shareMealCondictionMsg} 加購其他品項可抵共鍋費，目前未滿足共鍋最低金額，若未再選購餐點會加收$ {shareMealFee}元',
  'cart.askShareMealModal.reminder.detail.getShareMealCondictionMsg':
    '{title} 需加收$ {price}元',
  'cart.askShareMealModal.palceOrder': '繼續加點',
  'cart.askShareMealModal.shareMeal': '我要共鍋',
  'cart.payment.store.reminder':
    '*需先至店內結帳付款完成後，店家才開始製作餐點',
  'cart.payment.alert.msg':
    ' *選擇完付款方式後，需點選下方「確認下單」或「前往結帳」按鈕，訂單才成立並送出',
  'cart.payment.stroe.only.alert.msg':
    ' *選擇完付款方式後，需點選下方「確認下單」按鈕，訂單才成立並送出',
  'cart.payment.online.only.alert.msg':
    ' *選擇完付款方式後，需點選下方「前往結帳」按鈕，訂單才成立並送出',
  'cart.store.name': '收款店家 : {storeName}',
};

const promotion: Ii18n = {
  'promotion.myDiscount': '我的優惠',
  'promotion.useDiscount': '使用優惠',
  'promotion.redeem': '兌換點數',
  'promotion.available.point': '目前點數 {point} 點',
  'promotion.coupon': '使用酷碰券',
  'promotion.search': '查詢',
  'promotion.menu.myDiscount': '我的優惠/點數',
  'promotion.menu.useDiscount': '使用優惠/點數',
};

const redeem: Ii18n = {
  'redeem.title': '請選擇一項折扣',
  'redeem.point': '{point} 點折抵',
};

const otp: Ii18n = {
  'otp.title': '簡訊驗證',
  'otp.placeholder': '請輸入驗證碼',
};
const coupon: Ii18n = {
  'coupon.title': '使用酷碰券',
  'coupon.camera': '相機掃描',
  'coupon.picture': '圖庫選取',
  'coupon.serial': '酷碰券序號',
  'coupon.v2.modal.header.title': '新增酷碰券',
  'coupon.v2.modal.footer.cta': '確認使用',
  'coupon.v2.scan.title': '掃描酷碰券',
  'coupon.v2.couponbarcode.title': '優惠折扣碼',
  'coupon.v2.camera': '相機掃碼',
  'coupon.v2.sucess.msg': '*酷碰券兌換成功，已新增優惠',
  'coupon.v2.modal.body.input.placeholder': '輸入優惠折扣碼',
  'coupon.v2.error.input.empty': '請輸入優惠折扣碼',
};

const scancoupon: Ii18n = {
  'scancoupon.title': '掃描酷碰券',
  'scancoupon.notice': '請掃描QRCode',
};

const recommendation: Ii18n = {
  'recommendation.title': '為你推薦...',
  'recommendation.nextTime': '下次再點, 謝謝',
  'recommendation.confirm': '確認加購',
};

const rulealert: Ii18n = {
  'rulealert.title': '提醒',
  'rulealert.title.quantity.limit': '超過訂購上限',
  'rulealert.body.edit.item': '請調整以下品項數量：',
  'rulealert.party': '用餐人數: {party}',
  'rulealert.sharemeal.message1': '{party} 人需點 {pot} 鍋,',
  'rulealert.sharemeal.message2': '或至少點 {pot} 鍋 + {item} 單點',
  'rulealert.sharemeal.message3': '來折抵共鍋費',
  'rulealert.min.consumption.message1':
    '訂單金額未滿 {condition} 要增加 {fee} 的費用',
  'rulealert.min.consumption.message2': '訂單金額須滿 {condition}',
};

const postpay: Ii18n = {
  'postpay.title': '結帳',
  'postpay.qrcode': '結帳 QRCode',
  'postpay.orderlist': '已點清單',
  'postpay.customId': '結帳單號 {customId}',
  'postpay.customId.v2': '訂單編號',
  'postpay.qrcode.message1': '用餐完畢請到結帳機 "輸入結帳單號"',
  'postpay.qrcode.message2': '或 "掃描 QRCode" 結帳',
  'postpay.qrcode.message3': '(掃描前請將螢幕亮度調亮)',
  'postpay.view.orderList': '查看點餐紀錄',
  'postpay.goto.qrcode': '去結帳',
  'postpay.qrcode.message':
    '用餐完畢請至櫃台出示此畫面進行結帳，或於結帳機掃描此QR Code進行付款。',
  'postpay..existingOrder.total': '總金額',
  'postpay.orderlist.title': '點餐紀錄',
  'postpay.existingOrder.batch.title': '第{times}次下單',
};

const payment: Ii18n = {
  'payment.amount': '應付金額',
  'payment.view': '檢視',
  'payment.confirm': '確認付款',
  'payment.confirm.order': '確認下單',
  'payment.free': '建立訂單',
  'payment.options': '選擇付款方式',
  'payment.options.header': '選擇付款',
  'payment.instore': '店內付款',
  'payment.cash': '現金付款',
  'payment.online': '線上付款',
  'payment.online.option': '請選擇支付方式',
  'payment.LINEPAY': 'LINE Pay',
  'payment.JKOPAY': '街口支付',
  'payment.APPLEPAY': 'Apple Pay',
  'payment.GOOGLEPAY': 'Google Pay',
  'payment.TAIWANPAY': 'Taiwan Pay',
  'payment.TAPPAY_DIRECT': '信用卡',
  'payment.CTBC': '信用卡',
  'payment.BBPOS': 'BBPOS',
  'payment.BBPOS.title': '服務條款',
  'payment.PAYPAL': 'Paypal',
  'payment.invoice': '請選擇發票方式',
  'payment.detail.title': '餐點明細',
  'payment.invoice.identifier': '開立公司發票請輸入統一編號',
  'payment.invoice.mobilecarrier': '請輸入載具號碼',
  'payment.invoice.mobilecarrier.save': '設定成預設手機載具',
  'payment.invoice.npo': '請選擇機構',
  'payment.invoice.fill.npo': '請輸入機構代碼',
  'payment.invoice.PAPER': '紙本發票',
  'payment.invoice.BUYER_IDENTIFIER': '統一編號',
  'payment.invoice.MOBILE_CARRIER': '手機載具',
  'payment.invoice.NPO_CODE': '捐贈發票',
  'payment.invoice.EMAIL': 'Email',
  'payment.invoice.EMAIL_IDENTIFIER': '統一編號 + Email',
  'payment.npo.8585': '財團法人台灣兒童暨家庭扶助基金會',
  'payment.npo.7000': '財團法人台灣世界展望會',
  'payment.npo.9527': '財團法人罕見疾病基金會',
  'payment.npo.928': '財團法人宜蘭縣私立佛教幸夫社會福利慈善事業基金會',
  'payment.npo.8341': '社團法人中華民國銀髮慈善協會',
  'payment.npo.5252': '社團法人中華民國身心障礙聯盟',
  'payment.npo.4312': '社團法人中華親善動物保護協會',
  'payment.npo.OTHER': '其他',
  'payment.submit': '付款資料建立中{br}請稍後...',
  'payment.submit.success': '付款資料{br}建立完成',
  'payment.cardnumber': '信用卡卡號',
  'payment.expiration.date': '到期日',
  'payment.card.csc': '安全碼 (CVV)',
  'payment.expiration.date.placeholder': '月/年',
  'payment.card.csc.placeholder': '背面末三碼',
  'payment.card.change': '換一張信用卡',
  'payment.card.remember': '記住這張卡號，方便下次快速結帳',
  'payment.card.remember.tip':
    '紀錄本次信用卡付款資訊，下次付款只需授權即可快速結帳。且保有修改、刪除之權利。',
  'payment.confirmation.window.title': '您即將送出訂單',
  'payment.confirmation.window.info':
    '送出訂單後，店家立即開始為您製作餐點，請確認以下訂單資訊：',
  'payment.confirmation.window.info.DINE_IN':
    '需先至店內結帳付款完成後，店家才開始製作餐點，請確認以下訂單資訊：',
  'payment.confirmation.window.notice.Takeout':
    '※餐點製作時間至少15-20分鐘以上，請耐心等候。如需確切取餐時間，請電話與店家聯繫確認。',
  'payment.confirmation.window.notice.DINE_IN':
    '※ 送出訂單後，請先至店內櫃檯或自助結帳機付款。',
  'payment.confirmation.window.notice.DELIVERY':
    '※外送時間會因尖峰時段延長，請見諒。',
  'payment.confirmation.window.notice':
    '※ 確認送出訂單後，請至店內付款並取餐。如欲修改或取消訂單請聯絡店家，請勿無故棄單。',
  'payment.confirmation.window.payTotal': '付款金額：',
  'payment.confirmation.window.payMethod': '付款方式：',
  'payment.confirmation.window.takeOrderMethod': '取餐方式：',
  'payment.confirmation.window.orderTime': '訂單時間：',
  'payment.confirmation.window.DELIVERY.address': '外送地點：',
  'payment.confirmation.window.DINE_IN': '內用',
  'payment.confirmation.window.TAKE_OUT': '外帶自取',
  'payment.confirmation.window.DELIVERY': '外送',
  'payment.confirmation.window.cancel': '取消',
  'payment.confirmation.window.confirmOrder': '送出訂單',
  'payment.confirmation.window.serveTypes.TAKE_OUT': '儘快取餐',
  'payment.confirmation.window.serveTypes.SCHEDULE_TAKE_OUT':
    '預約 {time} 取餐',
  'payment.confirmation.window.serveTypes.DELIVERY': '預約 {time} 送達',
  'payment.confirmation.window.serveTypes.PRINT_AFTER_PAY.TAKEOUT':
    '※ 需先至店內結帳付款完成後，店家才開始製作餐點。若不便現場等候，建議選擇「線上付款」，並於預估餐點完成時間至店內取餐。',
  'payment.confirmation.window.serveTypes.PRINT_AFTER_PAY.DINE_IN':
    '※ 需先至店內結帳付款完成後，店家才開始製作餐點。',
  'payment.coupon.remind': '請重新下單，或請服務人員協助',
  'payment.submit.remind':
    '※選擇完付款方式後，需點選下方「{ctaString}」按鈕，訂單才成立並送出。',
  'payment.select.other.method': '請選擇其他付款方式',
};

const detail: Ii18n = {
  'detail.order.status': '訂單狀態',
  'detail.customid': '訂單編號',
  'detail.preorderTime': '預約取餐時間',
  'detail.printafterorder.preorder.desc':
    '店家會於預約取餐時間準備好您的餐點, 請至店內付款後取餐',
  'detail.printafterpay.preorder.desc':
    '需先至店內結帳付款完成後，店家才會開始製作餐點。',
  'detail.pay.pending': '待付款',
  'detail.pay.store': '店內付款',
  'detail.pay.autocancel1': '請於',
  'detail.pay.autocancel2': '前, 至店內結帳或立即線上付款完成並取餐',
  'detail.qrcode': '按此顯示QRCODE, 並掃描結帳',
  'detail.qrcode.pickup': '按此顯示QRCODE, 並掃描取餐',
  'detail.qrcode.title': '掃描',
  'detail.qrcode.desc': 'QRCODE請對準掃描機進行付款',
  'detail.qrcode.pickup.desc': 'QRCODE請對準掃描機進行取餐',
  'detail.cancel.order': '* 如欲修改訂單內容或取消訂單, 請電話聯絡門市',
  'detail.pay.other': '選擇其他付款方式',
  'detail.pay.online': '線上付款',
  'detail.order': '訂購資訊',
  'detail.order.payment': '付款方式',
  'detail.order.store.name': '商店名稱',
  'detail.order.dateTime': '訂單建立時間',
  'detail.order.servetype': '取餐方式',
  'detail.order.seats': '桌號',
  'detail.order.customerName': '取餐人',
  'detail.order.mobile': '電話',
  'detail.order.address': '地址',
  'detail.order.comment': '備註',
  'detail.order.pickup.time': '取貨時間',
  'detail.order.pickup.pincode': '開櫃密碼: ',
  'detail.order.pickup.address': '取貨地點',
  'detail.order.pickup.cabinet.number': '取貨櫃號',
  'detail.order.pickup.payment': '預選取物方式',
  'detail.order.cancel': '取消訂單',
  'detail.detail': '餐點明細',
  'detail.back.to.search.order': '回訂單列表',
  'detail.welcome': '再次訂購',
  'detail.payment.CASH': '現金',
  'detail.payment.LINEPAY': 'Line Pay',
  'detail.payment.ApplePay': 'Apple Pay',
  'detail.payment.GooglePay': 'Google Pay',
  'detail.payment.TAPPAY': 'Tappay',
  'detail.payment.TAPPAY_DIRECT': '信用卡',
  'detail.payment.BBPOS_ONLINE': 'BBPOS',
  'detail.payment.JKOPAY': '街口支付',
  'detail.payment.PAYPAL': 'Paypal',
  'detail.payment.CTBC_ONLINE': '中信支付',
  'detail.payment.TAIWANPAY': 'Taiwan Pay',
  'detail.takeout.preordertime': '預約取餐時間',
  'detail.takeout.only.message': '您的訂單已付款成功, 餐點已開始為您準備',
  'detail.takeout.message': '您的訂單已付款成功, 餐點會於預約取餐時間準備完成',
  'detail.delivery.preordertime': '預計送達時間',
  'detail.delivery.message':
    '您的訂單已付款成功, 餐點準備完成後盡快送達指定地址',
  'detail.delivery.message.location':
    '您的訂單已付款成功, 餐點準備完成後會於預約時間送達指定取餐地點。',
  'detail.delivery.notice': '* 外送時間會因尖峰時段延長, 請見諒',
  'detail.cubbie.title': '取餐櫃資訊',
  'detail.cubbie.qrcode': '按此顯示QRCODE, 並掃描取餐',
  'detail.order.tag.cash': '現場付款',
  'detail.order.tag.refund': '訂單退款',
  'detail.order.tag.cancel': '訂單取消',
  'detail.want.change.order': '* 如欲修改訂單內容或取消訂單，請電話聯絡門市。',
  'detail.cancelled.order': '* 門市取消訂單，有問題請電話聯絡門市。',
  'detail.order.paid.message': '您的訂單已付款成功, 餐點已開始為您準備',
  'detail.order.cancelled.reminder.title': '訂單已取消',
  'detail.order.cancelled.reminder.msg': '重新開始新訂單',
  'detail.order.refund.reminder.title': '訂單已退款',
  'detail.order.reminder.TAKEOUT.preorderTime.msg':
    '您的訂單已付款成功，餐點會於預約取餐時間 ',
  'detail.order.reminder.TAKEOUT.preorderTime.msg.end': ' 前準備完成',
  'detail.order.reminder.DELIVERY.preorderTime.msg':
    '您的訂單已付款成功，餐點會於預約外送時間 ',
  'detail.order.reminder.DELIVERY.cash.preorderTime.msg':
    '您的訂單已下單成功，餐點會於預約外送時間 ',
  'detail.order.reminder.DELIVERY.preorderTime.msg.end': ' 前儘快送達指定地址',
  'detail.order.reminder.DELIVERY.msg': '* 外送時間會因尖峰時段延長，請見諒',
  'detail.sharelMeal.addon.limit.time': '加點連結有效時間至',
};

const orderdetail: Ii18n = {
  'orderdetail.itemcount': '{count} 項商品',
  'orderdetail.count': '項商品',
  'orderdetail.subtotal': '小計',
  'orderdetail.discount': '折扣金額',
  'orderdetail.coupon': '酷碰券折抵',
  'orderdetail.fee': '額外費用',
  'orderdetail.shareMeal': '共鍋費用',
  'orderdetail.total': '總計',
  'orderdetail.count.total': '總計金額:',
};

const bulletin: Ii18n = {
  'bulletin.title': '公告',
  'bulletin.confirm': '我知道了',
};

const tag: Ii18n = {
  'tag.SPECIAL_PRICE': '特價',
};

const cds: Ii18n = {
  'cds.store': '商店',
  'cds.orderNo': '單號',
  'cds.lineIn': '等待',
  'cds.status': '狀態',
  'cds.cabinetNo': '櫃號',
  'cds.QUEUEING': '準備中',
  'cds.CABINET': '可取餐',
  'cds.CLOSED': '已取餐',
  'cds.CANCELLED': '已取消',
  'cds.EXPIRED': '已逾時',
  'cds.WARNING': '訂單異常',
  'cds.check.order.status': '檢查餐點準備狀態',
};

const notice: Ii18n = {
  'notice.title': '提醒',
  'notice.content':
    '您將前往第三方服務，請在完成付款後，返回此頁面確認訂單狀態，謝謝！',
  'notice.googlepay.1': '請點選',
  'notice.googlepay.2': '複製連結',
  'notice.googlepay.3': '按鈕, 開啟Chrome瀏覽器貼上網址, 繼續操作付款流程.',
  'notice.copyurl.success': '已成功複製連結',
};

const changeStoreModal: Ii18n = {
  'changeStoreModal.cleanCart': '離開此頁將會清空購物車',
  'changeStoreModal.leave': '跳出此頁？',
};

const phison: Ii18n = {
  'phison.employee': '員工',
  'phison.guest': '外賓',
  'phison.employee.input': '請輸入員工編號',
  'phison.employee.extNumber': '分機號碼（選填）',
  'phison.employee.id.empty': '員工編號不能為空',
  'phison.employee.id.error': '員工編號錯誤',
};

const storeDetail: Ii18n = {
  'storeDetail.store': '店家資訊',
  'storeDetail.address': '地址',
  'storeDetail.map': '導航',
  'storeDetail.phone': '聯絡電話',
  'storeDetail.call': '通話',
  'storeDetail.business.time': '營業時間',
  'storeDetail.business.in': '營業中',
  'storeDetail.business.out': '休息中',
};

const pickUp: Ii18n = {
  'pick.up.prepare.message': '您的商品正在準備中，請耐心等候。',
  'pick.up.warning.message':
    '訂單發生異常，請洽店家櫃檯服務人員或致電聯繫店家。',
  'pick.up.can.pickup.message':
    '您的商品已準備好，請於指定時間至對應取餐櫃或櫃檯掃描QR Code進行取餐。',
  'pick.up.pickedup.message': '已取貨完成，感謝您的訂購！',
  'pick.up.expired.message':
    '您的商品已逾時未取, 請查看下方明細商店取物狀態, 若有相關問題請洽詢櫃檯服務人員.',
  'pick.up.expired.notice':
    '逾時60分鐘未取貨, 請改至櫃檯取. 常溫商品限7日內完成取貨; 熟食商品已下架無法再取貨.',
  'pick.up.expired.notpickup': '逾時未取',
  'pick.up.status.no.pay': '未付款',
  'pick.up.status.prepare': '未上架(準備中)',
  'pick.up.status.on.shelf': '已上架(可取貨)',
  'pick.up.status.at.counter': '櫃檯取',
  'pick.up.status.pending.pull.off.shelf': '待下架(已取消)',
  'pick.up.status.picked.up': '已取貨',
  'pick.up.status.cancelled': '已取消',
  'pick.up.status.refund': '已退款(已取消)',
  'pick.up.status.warning': '異常通知',
  'pick.up.status.expired': '逾期',
};

const locationSelector = {
  'location.selector.select.address': '請選擇取餐地點(必選)',
  'location.selector.location': '取餐地點',
};

const delivery = {
  'uberDirect.error.invalid_params': '地址格式錯誤',
  'uberDirect.error.unknown_location': '無法理解指定的位置',
  'uberDirect.error.address_undeliverable': '超過外送範圍',
  'uberDirect.error.address_undeliverable_limited_couriers':
    '指定地點目前無外送員',
  'uberDirect.error.pickup_window_too_small':
    'The pickup window needs to be at least 10 minutes long.',
  'uberDirect.error.dropoff_deadline_too_early':
    'The dropoff deadline needs to be at least 20 minutes after the dropoff ready time.',
  'uberDirect.error.dropoff_deadline_before_pickup_deadline':
    'The dropoff deadline needs to be after the pickup deadline.',
  'uberDirect.error.dropoff_ready_after_pickup_deadline':
    'The dropoff ready time needs to be at or before the pickup deadline',
  'uberDirect.error.pickup_ready_too_early':
    '因逾時付款導致付款失敗，餐點無法於指定預約時間內送逹，請重新下單或詢問店家',
  'uberDirect.error.pickup_deadline_too_early':
    'The pickup deadline time needs to be at least 20 minutes from now.',
  'uberDirect.error.pickup_ready_too_late':
    'The pickup ready time needs to be within the next 30 days.',
  'uberDirect.error.customer_not_found': '客戶不存在',
  'uberDirect.error.request_timeout': '請求超時...',
  'uberDirect.error.customer_suspended':
    'Your account is passed due. Payment is required.',
  'uberDirect.error.customer_blocked':
    'Your account is not allowed to create deliveries.',
  'uberDirect.error.customer_limited':
    'Your account’s limits have been exceeded.',
  'uberDirect.error.unknown_error': '未知錯誤',
  'uberDirect.order.status.pending': '訂單處理中',
  'uberDirect.order.status.delivering': '餐點外送中',
  'uberDirect.order.status.delivered': '完成外送',
  'uberDirect.order.delivered.time': '預約送達時間',
  'delivery.please.input.delivery.address': '請輸入外送地址',
  'uberDirect.deliveryMethod': '送餐方式',
  'uberDirect.deliverableAction.deliverable_action_meet_at_door': '在門口碰面',
  'uberDirect.deliverableAction.deliverable_action_leave_at_door': '放門口',
  'delivery.please.input.address': '地址欄位不可為空值,請輸入地址',
  'delivery.please.input.address.warn': '請選擇取餐地點',
  'delivery.placeholder.address.msg': '街道門牌樓層',
  'uberDirect.error.goto.welcome': '回到歡迎頁',
};

const member = {
  'member.userTerms.privacy': '已詳閱並同意',
  'member.common.and': '及',
  'member.userTerms.title': '使用者條款',
  'member.profile.gender.DEFAULT': '請選擇',
  'member.profile.gender.MALE': '男性',
  'member.profile.name.warn': '輸入姓名格式錯誤',
  'member.profile.phone.empty': '手機號碼必填',
  'member.profile.phone.format.warn': '輸入手機格式錯誤',
  'member.profile.phone.registered.warn': '手機號碼已註冊',
  'member.profile.title.name': '姓名',
  'member.profile.title.name.placeholder': '請輸入會員姓名',
  'member.profile.title.phone': '手機',
  'member.profile.title.birth': '生日',
  'member.profile.title.gender': '性別',
  'member.header.remove.account': '刪除會員帳號',
  'member.header.title.MemberRegister': '註冊',
  'member.header.title.MemberLogin': '請輸入手機號碼',
  'member.header.title.MemberOtpValidator': '請輸入簡訊驗證碼',
  'member.header.title.MemberProfile': '會員資料',
  'member.MemberRegister.submit': '註冊並驗證簡訊',
  'member.MemberOtpValidator.time.prefix': '請於',
  'member.MemberOtpValidator.time.concat': '內輸入進行驗證',
  'member.MemberOtpValidator.otp.valid.error': '輸入驗證碼錯誤',
  'member.MemberOtpValidator.is.lock.error': '今日簡訊發送已逹上限',
  'member.common.btn.confirm': '確認',
  'member.common.btn.send.otp': '發送驗證碼',
  'member.common.btn.save': '儲存',
  'member.common.btn.login': '登入',
  'member.modal.title.save.success': '儲存成功',
  'member.modal.body.save.success': '您的會員資料已完成變更並儲存成功。',
  'member.modal.title.remove.account': '刪除會員帳號',
  'member.modal.body1.remove.account':
    '刪除會員帳號將無法再登入，且點數將會歸零',
  'member.modal.body2.remove.account': '確定刪除帳號？',
  'member.modal.title.remove.account.success': '刪除會員帳號成功',
  'member.need-setting.reminder.msg': '立即更新會員資料，以方便獲得優惠資訊。',
  'member.need-setting.reminder.btn': '前往會員設定',
  'member.reminder.msg': '成為會員能即時收到優惠資訊',
  'member.reminder.btn': '前往加入會員',
  'member.reminder.short.btn': '加入會員',
  'member.menu.promotion': '會員優惠/點數',
};

const zh_CN: Ii18n = {
  ...locationSelector,
  ...storeDetail,
  ...cds,
  ...phison,
  ...changeStoreModal,
  ...confirmModal,
  ...redeemPoint,
  ...searchOrder,
  ...error,
  ...welcome,
  ...stores,
  ...tableinfo,
  ...attribute,
  ...common,
  ...cart,
  ...promotion,
  ...redeem,
  ...otp,
  ...coupon,
  ...scancoupon,
  ...recommendation,
  ...rulealert,
  ...postpay,
  ...payment,
  ...detail,
  ...orderdetail,
  ...bulletin,
  ...tag,
  ...notice,
  ...pickUp,
  ...delivery,
  ...member,
};
export default zh_CN;
