import { UberDirectActionType } from '../../actions/uberDirect/uberDirectActionType';

const initialState: any = {
  errorMsg: null,
  structuredAddress: null,
  deliverableAction: true,
  phoneError: null,
  nameError: null,
  selectedCity: null,
  selectedArea: null,
  selectedStreet: null,
  WA10Member: null,
  deliveryType: true,
};
// TODO : save webVo state only ?
const uberDirectReducer: (state: any, action: any) => any = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case UberDirectActionType.SET_UBER_DIRECT_API_ERROR:
      return {
        ...state,
        errorMsg: payload,
      };

    case UberDirectActionType.SET_DELIVERY_PHONE_ERROR:
      return {
        ...state,
        phoneError: payload,
      };

    case UberDirectActionType.SET_DELIVERY_NAME_ERROR:
      return {
        ...state,
        nameError: payload,
      };
    case UberDirectActionType.SET_SELECTED_CITY:
      return {
        ...state,
        selectedCity: payload,
      };
    case UberDirectActionType.SET_SELECTED_AREA:
      return {
        ...state,
        selectedArea: payload,
      };
    case UberDirectActionType.SET_SELECTED_STREET:
      return {
        ...state,
        selectedStreet: payload,
      };
    case UberDirectActionType.SET_STRUCTURED_ADDRESS:
      return {
        ...state,
        structuredAddress: payload,
      };

    case UberDirectActionType.SET_DELIVERABLE_ACTION:
      return {
        ...state,
        deliverableAction: payload,
      };
    case UberDirectActionType.SET_WA10_MEMBER:
      return {
        ...state,
        WA10Member: payload,
      };
    case UberDirectActionType.SET_DELIVERY_TYPE:
      return {
        ...state,
        deliveryType: payload,
      };

    default:
      return state;
  }
};

export default uberDirectReducer;
