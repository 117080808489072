import { initAxiosService } from '@/httpAxios';
import { LoadingNError } from '@components/common';
import '@popperjs/core';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.scss';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import VConsole from 'vconsole';
import App from './App';
import * as i18n from './i18n';
import { Ii18n } from './i18n/type';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

function Root() {
  const availableLocales = [
    'zh-TW',
    'en-US',
    'zh-HK',
    'zh-CN',
    'vi',
    'ja',
    'sr',
    'de-AT',
    'de-DE',
    'ko',
  ];
  const [locale, setLocale] = useState<string>(navigator.language);
  const [messages, setMessages] = useState<Ii18n>(i18n.zh_TW);

  if (process.env.REACT_APP_V_CONSOLE) {
    const vConsole = new VConsole();
  }

  const showErrorMessage = (error) => {
    console.error(error);
  };

  useEffect(() => {
    if (!availableLocales.includes(locale)) {
      setLocale('zh-TW');
    }
    if (availableLocales.includes(locale)) {
      const key = locale.replace('-', '_');
      setMessages(i18n[key]);
    }

    const cache = createIntlCache();

    const intlProvider = createIntl(
      {
        locale,
        messages,
        defaultLocale: 'zh-TW',
        onError: showErrorMessage,
      },
      cache
    );
    initAxiosService(intlProvider);
  }, [locale]);

  return messages && availableLocales.includes(locale) ? (
    <React.StrictMode>
      <Provider store={store}>
        <IntlProvider
          locale={locale}
          key={locale}
          defaultLocale="zh-TW"
          messages={messages}
          onError={showErrorMessage}
        >
          <LoadingNError />
          <BrowserRouter>
            <App locale={locale} setLocale={setLocale} />
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    </React.StrictMode>
  ) : (
    <div />
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
