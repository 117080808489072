import 'core-js';
import React, { Suspense } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { SuspenseLoading } from '@/components/common';
import * as Components from './components';

type PropsType = RouteComponentProps<any> & {
  history?: string;
  locale: string;
  setLocale: (locale: string) => void;
};

class App extends React.Component<PropsType> {
  currentPathname: string;

  currentSearch: string;

  constructor(props: any) {
    super(props);
    this.currentPathname = '';
    this.currentSearch = '';
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen((location, action) => {
      if (action === 'PUSH') {
        if (
          location.pathname !== this.currentPathname ||
          location.search !== this.currentSearch
        ) {
          this.currentPathname = location.pathname;
          this.currentSearch = location.search;
          history.push({
            pathname: location.pathname,
            search: location.search,
            state: location.state,
          });
        }
      } else {
        history.go(1);
      }
    });
  }

  render() {
    const { locale } = this.props;
    return (
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/payment/tappaydirect`}
          >
            <Components.TappayDirect locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/payment/jkopay`}
          >
            <Components.Jkopay locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/payment/paypal/:status`}
          >
            <Components.Paypal locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/payment/bbpos/:merchantReference`}
          >
            <Components.Bbpos locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/payment/ctbc/:customerInfo`}
          >
            <Components.Ctbc locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/store/:storeUuid/takeout`}
          >
            <Components.WelcomeRedirect />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/store/:storeUuid/takeout-only`}
          >
            <Components.WelcomeRedirect />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/store/:storeUuid/welcome`}
          >
            <Components.Welcome
              locale={locale}
              setLocale={this.props.setLocale}
            />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/store/:storeUuid/welcome-hty`}
          >
            <Components.PickUpWelcome
              locale={locale}
              setLocale={this.props.setLocale}
            />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/store/:storeUuid/searchOrder`}
          >
            <Components.SearchOrder locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/detail`}
          >
            <Components.Detail
              locale={locale}
              setLocale={this.props.setLocale}
            />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/payment/kiosk`}
          >
            <Components.Kiosk
              locale={locale}
              setLocale={this.props.setLocale}
            />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/payment`}
          >
            <Components.Payment
              locale={locale}
              setLocale={this.props.setLocale}
            />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/submitpayment`}
          >
            <Components.SubmitPayment
              locale={locale}
              setLocale={this.props.setLocale}
            />
          </Route>
          <Route path={`${process.env.REACT_APP_VERSION}/online/submitorder`}>
            <Components.SubmitOrder locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/menu`}
          >
            <Components.Menu locale={locale} setLocale={this.props.setLocale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid/stores`}
          >
            <Components.Stores locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/seat/:seatUuid/current-order`}
          >
            <Components.CurrentOrder locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/custom/:customUuid`}
          >
            <Components.CustomWelcome locale={locale} />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/order/:orderUuid`}
          >
            <Components.RedirectOrder />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/lineLiffReminder`}
          >
            <Components.LineLiffReminderLayout />
          </Route>
          <Route
            path={`${process.env.REACT_APP_VERSION}/online/store/:storeUuid/liffWelcome/:type`}
          >
            <Components.LiffWelcome />
          </Route>
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(App);
