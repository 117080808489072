import { DemoActionType } from '../../actions/demo/demoTypes';

const initialState: any = {};

const demoReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case DemoActionType.CREATE_DEMO:
      return [...state, payload];

    case DemoActionType.RETRIEVE_DEMO:
      return payload;

    case DemoActionType.UPDATE_DEMO:
      return state.map((state) => {
        if (state.id === payload.id) {
          return {
            ...state,
            ...payload,
          };
        } else {
          return state;
        }
      });

    case DemoActionType.DELETE_DEMO:
      return state.filter(({ id }) => id !== payload.id);

    case DemoActionType.DELETE_ALL_DEMO:
      return [];

    default:
      return state;
  }
};

export default demoReducer;
