import PropTypes from 'prop-types';

// dayOfWeek : 星期 , businessTime ： 營業時間
function BusinessHoursRow({ dayOfWeek, businessTime }) {
  return (
    <div className="d-flex pt-1 pb-1 justify-content-between">
      <div>{dayOfWeek}</div>
      <div>{businessTime}</div>
    </div>
  );
}

BusinessHoursRow.propTypes = {
  dayOfWeek: PropTypes.string.isRequired,
  businessTime: PropTypes.string.isRequired,
};

export default BusinessHoursRow;
